import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select } from "antd";
import React, { useState } from "react";
import useCountries from "../../hooks/useCountries";
import useCourseLevels from "../../hooks/useCourseLevels";
import useIntakeMonths from "../../hooks/useIntakeMonths";
import useIntakeYears from "../../hooks/useIntakeYears";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
const { Option } = Select;
const { Panel } = Collapse;

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();
  const [postData, setPostData] = useState({
    country_id: "all",
  });

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const countries = useCountries(filterDataOther);
  const filteredUniversities = useUniversityByCountry(postData.country_id);
  const courseLevels = useCourseLevels(filterDataOther);
  const intakeMonths = useIntakeMonths(filterDataOther);
  const intakeYears = useIntakeYears(filterDataOther);

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      country_id: values.country_id ? values.country_id : "",
      name: values.name ? values.name : "",
      uni_id: values.uni_id ? values.uni_id : "",
      course_level_id: values.course_level_id ? values.course_level_id : "",
      intake_month_id: values.intake_month_id ? values.intake_month_id : "",
      intake_year_id: values.intake_year_id ? values.intake_year_id : "",
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      country_id: "",
      name: "",
      uni_id: "",
      course_level_id: "",
      intake_month_id: "",
      intake_year_id: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="name" noStyle>
              <Input
                placeholder="Course Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="country_id" noStyle>
              <Select
                allowClear
                placeholder="Select Country"
                style={{ width: "100%" }}
                onChange={(value) =>
                  setPostData({ ...postData, country_id: value })
                }
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="uni_id" noStyle>
              <Select
                placeholder="Select University"
                style={{ width: "100%" }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {filteredUniversities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item name="course_level_id" noStyle>
              <Select
                allowClear
                placeholder="Select Course Level"
                style={{ width: "100%" }}
              >
                {courseLevels.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_month_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Month"
                style={{ width: "100%" }}
              >
                {intakeMonths.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.months}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_year_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Year"
                style={{ width: "100%" }}
              >
                {intakeYears.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
