import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ApplicationFilterRow } from "../components/Reports/ApplicationFilterRow";
import { CourseFilterRow } from "../components/Reports/CourseFilterRow";
import { UniversityFilterRow } from "../components/Reports/UniversityFilterRow";
import { UserFilterRow } from "../components/Reports/UserFilterRow";
import useBreadCrumb from "../hooks/useBreadCrumb";
import useCountries from "../hooks/useCountries";
import useCourseLevels from "../hooks/useCourseLevels";
import useIntakeMonths from "../hooks/useIntakeMonths";
import useIntakeYears from "../hooks/useIntakeYears";

export default function Reports() {
  const location = useLocation();
  useBreadCrumb("Reports", location.pathname, "Reports");
  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });
  const countries = useCountries(filterDataOther);
  const intakeYears = useIntakeYears(filterDataOther);
  const intakeMonths = useIntakeMonths(filterDataOther);
  const courseLevels = useCourseLevels(filterDataOther);

  return (
    <div className="dashboard">
      <div className="section_row">
        <UserFilterRow countries={countries} />
        <div className="spaces" />
        <UniversityFilterRow countries={countries} />
        <div className="spaces" />
        <CourseFilterRow
          countries={countries}
          intakeYears={intakeYears}
          intakeMonths={intakeMonths}
          courseLevels={courseLevels}
        />
        <div className="spaces" />
        <ApplicationFilterRow
          countries={countries}
          intakeYears={intakeYears}
          intakeMonths={intakeMonths}
          courseLevels={courseLevels}
        />
      </div>
    </div>
  );
}
