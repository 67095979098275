import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useEnquiryDataStore } from "../../store";
import DetailsFirst from "./Education/DetailsFirst";
import DetailsSecond from "./Education/DetailsSecond";
import DiplomaDetails from "./Education/DiplomaDetails";
import DoctorateDetails from "./Education/DoctorateDetails";
import PostGraduateDetails from "./Education/PostGraduateDetails";
import UnderGraduateDetails from "./Education/UnderGraduateDetails";

export const StepFive = ({ currentStep, setNextStep, type }) => {
  const [educationStep, setEducationStep] = useState(1);
  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);
  const [detailsOne, setDetailsOne] = useState({});
  const [detailsTwo, setDetailsTwo] = useState({});
  const [diplomaDetails, setDiplomaDetails] = useState({});
  const [graduateDetails, setGraduateDetails] = useState({});
  const [postGraduateDetails, setPostGraduateDetails] = useState({});
  const [phdDetails, setPhdDetails] = useState({});

  const submitForm = () => {
    setNextStep(currentStep + 1);
  };

  const prev = () => {
    setNextStep(currentStep - 1);
  };

  const changePanel = (step) => {
    setEducationStep(step);
  };

  useEffect(() => {
    if (enquiryData.educationData) {
      setDetailsOne(enquiryData.educationData.detailsOne || {});
      setDetailsTwo(enquiryData.educationData.detailsTwo || {});
      setDiplomaDetails(enquiryData.educationData.diplomaDetails || {});
      setGraduateDetails(enquiryData.educationData.graduateDetails || {});
      setPostGraduateDetails(
        enquiryData.educationData.postGraduateDetails || {}
      );
      setPhdDetails(enquiryData.educationData.phdDetails || {});
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEnquiryData({
      ...enquiryData,
      educationData: {
        detailsOne,
        detailsTwo,
        diplomaDetails,
        graduateDetails,
        postGraduateDetails,
        phdDetails,
      },
    });
    // eslint-disable-next-line
  }, [
    detailsOne,
    detailsTwo,
    diplomaDetails,
    graduateDetails,
    postGraduateDetails,
    phdDetails,
  ]);

  return (
    <div className="step_board">
      <p className="common_header">
        Education Details |<span>fill your education details</span>
      </p>
      <div className="edu_section">
        <div className="edu_nav">
          <div
            className={
              educationStep === 1
                ? "edu_nav_btn edu_nav_btn_active"
                : "edu_nav_btn"
            }
            onClick={() => changePanel(1)}
          >
            <p>10th Details</p>
          </div>
          <div
            className={
              educationStep === 2
                ? "edu_nav_btn edu_nav_btn_active"
                : "edu_nav_btn"
            }
            onClick={() => changePanel(2)}
          >
            <p>12th Details</p>
          </div>
          <div
            className={
              educationStep === 3
                ? "edu_nav_btn edu_nav_btn_active"
                : "edu_nav_btn"
            }
            onClick={() => changePanel(3)}
          >
            <p>Diploma Details</p>
          </div>
          <div
            className={
              educationStep === 4
                ? "edu_nav_btn edu_nav_btn_active"
                : "edu_nav_btn"
            }
            onClick={() => changePanel(4)}
          >
            <p>Under-Graduate Details</p>
          </div>
          <div
            className={
              educationStep === 5
                ? "edu_nav_btn edu_nav_btn_active"
                : "edu_nav_btn"
            }
            onClick={() => changePanel(5)}
          >
            <p>Post-Graduate Details</p>
          </div>
          <div
            className={
              educationStep === 6
                ? "edu_nav_btn edu_nav_btn_active"
                : "edu_nav_btn"
            }
            onClick={() => changePanel(6)}
          >
            <p>PHD/Doctorate Details</p>
          </div>
        </div>
        <div className="edu_body">
          {educationStep === 1 && (
            <DetailsFirst
              detailsOne={detailsOne}
              setDetailsOne={setDetailsOne}
            />
          )}
          {educationStep === 2 && (
            <DetailsSecond
              detailsTwo={detailsTwo}
              setDetailsTwo={setDetailsTwo}
            />
          )}
          {educationStep === 3 && (
            <DiplomaDetails
              diplomaDetails={diplomaDetails}
              setDiplomaDetails={setDiplomaDetails}
            />
          )}
          {educationStep === 4 && (
            <UnderGraduateDetails
              graduateDetails={graduateDetails}
              setGraduateDetails={setGraduateDetails}
            />
          )}
          {educationStep === 5 && (
            <PostGraduateDetails
              postGraduateDetails={postGraduateDetails}
              setPostGraduateDetails={setPostGraduateDetails}
            />
          )}
          {educationStep === 6 && (
            <DoctorateDetails
              phdDetails={phdDetails}
              setPhdDetails={setPhdDetails}
            />
          )}
        </div>
      </div>

      <div className="left_btn">
        <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
          Previous
        </Button>

        <Button type="primary" htmlType="submit" onClick={() => submitForm()}>
          Next
        </Button>
      </div>
    </div>
  );
};
