import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { PostData } from "../apiService/PostData";
import { apis } from "../properties";
import { usePublicStore, useRefreshTable } from "../store";

function useAllCountries(filterData) {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const { countryList, setCountryList } = usePublicStore();

  useEffect(() => {
    const getData = () => {
      Progress.show();
      PostData(apis.COUNTRY_LIST, filterData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          setCountryList(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    countryList?.length === 0 ? getData() : setState(countryList);
    // eslint-disable-next-line
  }, [filterData, refreshTable]);
  return state;
}

export default useAllCountries;
