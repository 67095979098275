export let usersStore = (set) => ({
    userType: [],
    setUserType: (userType) => {
        set((state) => ({ ...state, userType: userType }));
    },
    setClearData: () => {
        set((state) => ({
            ...state,
            userType: [],
        }));
    }
});

