import { Button, message, Modal } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import UserDataTable from "../components/UserManagement/UserDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";
import { apis, IMAGE_PATH } from "../properties";
import Progress from "react-progress-2";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useStore } from "../store";

const { confirm } = Modal;

export default function UserManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("User Management", location.pathname, "User Management");
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  const exportFile = () => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `You are about to export all the users to a CSV file.`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(apis.FILE_EXPORT, { type: "user-all" }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            message.success("File export successfully");
            window.open(`${IMAGE_PATH}/${responseJson.data}`);
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          {actionPrivileges.includes("INVITE_USER") && (
            <Button
              className="primary__btn"
              onClick={() => navigate("/user-management/invite-user")}
            >
              Invite User
            </Button>
          )}
          {actionPrivileges.includes("ADD_USER") && (
            <Button
              className="default__btn"
              onClick={() => navigate("/user-management/create-user")}
            >
              Add User
            </Button>
          )}
          {actionPrivileges.includes("USER_EXPORT") && (
            <Button className="default__btn" onClick={() => exportFile()}>
              Export
            </Button>
          )}
        </div>
        <UserDataTable />
      </div>
    </div>
  );
}
