import { Button, Form, Input, message, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useCities from "../../hooks/useCities";
import useAllCountries from "../../hooks/useAllCountries";
import useGetUserById from "../../hooks/useGetUserById";
import useStates from "../../hooks/useStates";
import { apis } from "../../properties";
import { useStore } from "../../store";
import FileUpload from "../../util/FileUpload";
import PasswordChange from "./PasswordChange";
const { Option } = Select;

export default function MyProfile() {
  const [form] = Form.useForm();
  const location = useLocation();
  useBreadCrumb("My Profile", location.pathname, "", "add");
  const [postData, setPostData] = useState({});

  const [avatar, setAvatar] = useState();
  const [upDoc, setUpDoc] = useState();

  const profileData = useStore((state) => state.profileData);
  const setProfileData = useStore((state) => state.setProfileData);

  const [filterDataOther] = useState({
    status: "all",
  });

  const countries = useAllCountries(filterDataOther);
  const states = useStates(postData.country_id ? postData.country_id : 0);
  const cities = useCities(postData.state_id ? postData.state_id : 0);
  const userData = useGetUserById(profileData.id);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        ...userData,
      });
      setPostData({
        ...postData,
        country_id: userData.country_id,
        state_id: userData.state_id,
        city_id: userData.city_id,
      });
      if (userData.length !== 0) {
        setUpDoc(JSON.parse(userData.br_doc));
        setAvatar(userData.img_url);
      }
    }
    // eslint-disable-next-line
  }, [userData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
        img_url: avatar ? avatar : null,
        id: profileData.id,
        br_doc: upDoc ? upDoc : null,
      };
      PostDataAuth(apis.PROFILE_UPDATE, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setProfileData({
            ...profileData,
            img_url: responseJson.data.img_url,
          });
          Progress.hide();
          message.success(responseJson.message);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          My Personal Data |<span>Edit your personal data</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "First name cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="Your first name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Last name cannot be empty!" },
                ]}
              >
                <Input type="text" size="large" placeholder="Your last name" />
              </Form.Item>
              <Form.Item
                label="Contact Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Contact number cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Your contact number"
                />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input
                  type="text"
                  size="large"
                  placeholder="Your Email name"
                  disabled
                />
              </Form.Item>
              <Form.Item
                label="User Type"
                name="role"
                required
                rules={[
                  {
                    required: true,
                    message: "User type cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Your Email name"
                  disabled
                />
              </Form.Item>

              <Form.Item name="address" label="Address">
                <Input type="text" size="large" placeholder="Your address" />
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item
                label="Country"
                name="country_id"
                rules={[
                  {
                    required: true,
                    message: "Country cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Select user country"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    setPostData({ ...postData, country_id: value });
                  }}
                >
                  {countries.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="State"
                name="state_id"
                rules={[
                  {
                    required: true,
                    message: "State cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select user state"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    setPostData({ ...postData, state_id: value });
                  }}
                >
                  {states.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="City"
                name="city_id"
                rules={[
                  {
                    required: true,
                    message: "city cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select user city"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    setPostData({ ...postData, city_id: value });
                  }}
                >
                  {cities.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {profileData.role === "Agent-Admin" && (
                <div className="rg_box">
                  <p className="common_header">
                    Business Type |<span>select your business type</span>
                  </p>
                  <Form.Item name="agent_type">
                    <Radio.Group>
                      <Radio value="Individual">Individual</Radio>
                      <Radio value="Company">Company</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <div className="rg_box">
                <p className="common_header">
                  Upload Your Profile Image<span></span>
                </p>
                <FileUpload
                  type="image"
                  location="profile"
                  count={1}
                  imageUpload={avatar}
                  setImageUpload={setAvatar}
                  accept="image/png, image/jpeg"
                  prefix="profile"
                />
              </div>
              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {<PasswordChange />}

      {profileData.role === "Agent-Admin" && (
        <div className="section_row">
          <p className="common_header">
            My documents |<span>edit your documents</span>
          </p>
          <div className="rg_box">
            <p className="common_header">
              Upload BR |<span>Upload your business registration</span>
            </p>
            <FileUpload
              type="file"
              location="documents/business_registration"
              count={1}
              imageUpload={upDoc}
              setImageUpload={setUpDoc}
              accept="application/pdf"
              prefix="br"
            />
          </div>
          <div className="left_btn">
            <Button
              type="primary"
              className="primary__btn"
              onClick={() => submitForm()}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
