import {
  ClearOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Breadcrumb, Dropdown, Menu, message, Tooltip } from "antd";
import React from "react";
// import Logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_PATH } from "../../properties";
// import Progress from "react-progress-2";
import { useBreadcrumbStore, useDashboardStores, usePublicStore, useStore } from "../../store";
import Notifications from "./Notifications";
import { BiLinkExternal } from "react-icons/bi";
import RefreshTable from "../../util/RefreshTable";

function Header(props) {
  const navigate = useNavigate();

  const { profileData, activeSideMenu, setSettingData, setActiveRoute } =
    useStore();
  const breadcrumb = useBreadcrumbStore((state) => state.breadcrumb);
  const {setClearDashboard} = useDashboardStores();
  const {setClearPublicData} = usePublicStore();

  const logout = () => {
    // Progress.show();
    localStorage.removeItem("campusDirect");
    localStorage.removeItem("breadCrumb");
    message.success("Logged Out Successfully");
    navigate("/login");
  };

  const homeRoot = () => {
    setActiveRoute("dashboard");
  };

  const moveProfile = () => {
    navigate("/settings/my-profile");
    setActiveRoute("settings");
  };

  const moveSetting = () => {
    navigate("/settings");
    setActiveRoute("settings");
  };

  const cashClearStore = () => {
    setClearDashboard();
    setClearPublicData();
    window.location.reload();
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => moveProfile()}>Profile</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => moveSetting()}>Settings</div>
      </Menu.Item>
      <Menu.Item onClick={logout}>
        <div>Logout</div>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="Header">
      <div className="Header_left">
        <div
          onClick={() => setSettingData(!activeSideMenu)}
          className="toggle-menu-btn"
        >
          {activeSideMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
        <div className="Header_main">
          <div className="Header_main_top">
            <div className="Header_main_top_right">
              <p>{breadcrumb.length !== 0 ? breadcrumb[0].title : ""}</p>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link
                    to="/dashboard"
                    className="link"
                    onClick={() => homeRoot()}
                  >
                    <HomeOutlined className="bb_home" />
                  </Link>
                </Breadcrumb.Item>
                {breadcrumb.map((item, index) => (
                  <Breadcrumb.Item>
                    <Link to={item.url} className="link">
                      <span className="bb_other">{item.name}</span>
                    </Link>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <div className="Header_right">
        <Tooltip placement="bottom" title="Cash clear">
          <ClearOutlined className="head_icon" onClick={() => cashClearStore()}/>
        </Tooltip>
        <RefreshTable />
        <a
          href="https://campusdirect.io"
          target="_blank"
          rel="noreferrer"
          className="ex_webLink"
        >
          <Tooltip placement="bottom" title="Web Site">
            <BiLinkExternal />
          </Tooltip>
        </a>

        <Notifications />
        <div className="user_pro_name">
          <p>
            {profileData.first_name
              ? profileData.first_name + " " + profileData.last_name
              : ""}
          </p>
          <span>{profileData.role ? profileData.role : ""}</span>
        </div>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomLeft"
          arrow
        >
          {profileData.img_url ? (
            <Avatar src={`${IMAGE_PATH}/${profileData.img_url}`} />
          ) : (
            <Avatar
              style={{ color: "#234caa", backgroundColor: "#06297a30" }}
              icon={<UserOutlined />}
            />
          )}
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;
