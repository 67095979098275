import { Button, Form, Input, message, Select } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useAllUserList from "../../hooks/useAllUserList";
import useAllUserListAgents from "../../hooks/useAllUserListAgents";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";

const { Option } = Select;

function AddWorkflows() {
  const [form] = Form.useForm();
  const location = useLocation();

  useBreadCrumb("Create Workflows Rule", location.pathname, "", "add");

  const [filterData] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    role: [2, 4],
    dataTable: false,
  });
  const allStaffList = useAllUserList(filterData);
  const allAgentList = useAllUserListAgents();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  useEffect(() => {
    form.setFieldsValue({
      rule: "AUTO_ASSIGN_STAFF_APPLICATION",
      agent_id: null,
    });
    return () => {
      form.setFieldsValue({
        rule: "AUTO_ASSIGN_STAFF_APPLICATION",
        agent_id: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
      };
      PostDataAuth(apis.CREATE_WORKFLOW, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Create Workflows Rule |<span>create new rule</span>
        </p>
        <br />
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item name="rule">
                <Input size="large" disabled />
              </Form.Item>
              <Form.Item
                name="agent_id"
                rules={[
                  {
                    required: true,
                    message: "Agent Admin cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Agent Admin"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {allAgentList?.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.first_name + " " + item.last_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="staff_id"
                required
                rules={[
                  {
                    required: true,
                    message: "Staff Member cannot be empty!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Select Staff Member"
                >
                  {allStaffList.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.first_name +
                        " " +
                        item.last_name +
                        " (" +
                        item.role +
                        ")"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="input_field"></div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddWorkflows;
