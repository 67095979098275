import { Button, Form, Input, message, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { PostDataAuth } from "../../../apiService/PostData";
import { apis } from "../../../properties";
import Progress from "react-progress-2";
import { useRefreshTable } from "../../../store";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import useApplicationStatusById from "../../../hooks/useApplicationStatusById";

export default function AddApplicationStatus({
  visible,
  setVisible,
  type,
  selectId,
}) {
  const [form] = Form.useForm();

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [isActive, setIsActive] = useState(true);
  const [state, setState] = useState({
    displayColorPicker: false,
    stat_color: "#000000",
  });
  const applicationStatusById = useApplicationStatusById(selectId);

  useEffect(() => {
    if (type === "edit" && applicationStatusById.length !== 0) {
      setIsActive(applicationStatusById.status === 1 ? true : false);
      form.setFieldsValue({
        stat_name: applicationStatusById.stat_name,
        status: applicationStatusById.status === 1 ? true : false,
      });
      setState({
        ...state,
        stat_color: applicationStatusById.stat_color,
      });
    }
    // eslint-disable-next-line
  }, [applicationStatusById, type]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      var data;
      var URL;
      if (type === "add") {
        data = {
          ...values,
          ...state,
        };
        URL = apis.CREATE_APPLICATION_STATUS;
      } else {
        data = {
          ...values,
          status: isActive ? 1 : 0,
          ...state,
          id: selectId,
        };
        URL = apis.UPDATE_APPLICATION_STATUS;
      }
      Progress.show();
      PostDataAuth(URL, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          form.resetFields();
          setRefreshTable(!refreshTable);
          setVisible(false);
          message.success(responseJson.message);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const styles = reactCSS({
    default: {
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",

        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <Modal
      title={
        type === "add"
          ? "Add New Application Status"
          : "Edit Application Status"
      }
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="stat_name"
            rules={[
              {
                required: true,
                message: "Stats name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Stats name" />
          </Form.Item>
          <div className="color_row">
            <p>Status Color</p>
            <div
              style={styles.swatch}
              onClick={() =>
                setState({
                  ...state,
                  displayColorPicker: !state.displayColorPicker,
                })
              }
            >
              <div
                className="color_tab"
                style={{ backgroundColor: state.stat_color }}
              />
            </div>
            {state.displayColorPicker ? (
              <div style={styles.popover}>
                <div
                  style={styles.cover}
                  onClick={() =>
                    setState({ ...state, displayColorPicker: false })
                  }
                />
                <SketchPicker
                  color={state.stat_color }
                  onChange={(color) =>
                    setState({ ...state, stat_color: color.hex })
                  }
                />
              </div>
            ) : null}
          </div>
          {type !== "add" && (
            <div style={{ display: "flex", gap: "5px" }}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              <p>Status</p>
            </div>
          )}
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
