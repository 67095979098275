import { Button, Form, Input, message, Modal, Select } from "antd";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { PostDataAuth } from "../../apiService/PostData";
import useApplicationStatusList from "../../hooks/useApplicationStatusList";
import { apis } from "../../properties";
import FileUpload from "../../util/FileUpload";
import { useRefreshTable } from "../../store";

const { Option } = Select;
const { TextArea } = Input;

export const AddRemarkModel = ({ visible, setVisible, applications, enquiry_id }) => {
  const [form] = Form.useForm();
  const [upDoc, setUpDoc] = useState("");

  const applicationStatusList = useApplicationStatusList();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  
  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      var postData = {
        ...values,
        upDoc : upDoc,
        enquiry_id : enquiry_id,
      };
      console.log(postData);
      PostDataAuth(apis.ADD_REMARKS, postData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success("Remark added successfully");
          setVisible(false);
          setRefreshTable(!refreshTable);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
      setVisible(false);
    });
  };

  return (
    <Modal
      title="Add Remark"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={700}
    >
      <div className="step_model_body">
        <p></p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="status"
            required
            rules={[
              {
                required: true,
                message: "Status cannot be empty!",
              },
            ]}
          >
            <Select size="large" placeholder="Select Status" allowClear>
              {applicationStatusList.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.stat_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="application_id"
            required
            rules={[
              {
                required: true,
                message: "Application cannot be empty!",
              },
            ]}
          >
            <Select size="large" placeholder="Select Application">
              {applications.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.course_name}-({item.app_unique_id}) -
                  {item.university_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="up_document">
            <div>
              <p className="common_header">Upload Document (Optional)</p>
              <FileUpload
                type="file"
                location="documents/remark_document"
                count={1}
                imageUpload={upDoc}
                setImageUpload={setUpDoc}
                accept="application/pdf"
                prefix="remark_doc"
              />
            </div>
          </Form.Item>

          <Form.Item
            name="remark"
            required
            rules={[
              {
                required: true,
                message: "Remark cannot be empty!",
              },
            ]}
          >
            <TextArea rows={5} placeholder="Add your Remark" />
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
