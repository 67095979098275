import { Alert, Button, Form, Input, message, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable } from "../../store";
import useIntakeYears from "../../hooks/useIntakeYears";
import { PostDataAuth } from "../../apiService/PostData";
import Progress from "react-progress-2";

const { Option } = Select;

function ImportModel({ visible, setVisible }) {
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterDataOther] = useState({
    status: 1,
  });
  const intakeYears = useIntakeYears(filterDataOther);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      if (selectedFile) {
        console.log(selectedFile);
        var formData = new FormData();
        formData.append("uploaded_file", selectedFile);
        formData.append("type", "course");
        formData.append("year_id", values.year_id);
        setUploading(true);
        axios
          .post(apis.FILE_IMPORT, formData, {
            headers: {
              token: "dad#lecture#Charger#lot7",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setRefreshTable(!refreshTable);
              message.success(response.data.message);
              setSelectedFile();
              setUploading(false);
              setVisible(false);
            }
          })
          .catch((error) => {
            setUploading(false);
            if (error.response.status === 422) {
              const errors = error.response.data.error.file;
              errors.forEach((error) => {
                message.error(error);
              });
            } else {
              message.error(error.response.data.message);
            }
          });
      } else {
        message.error("Please choose a file");
      }
    });
  };

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const exportFile = (dataType) => {
    Progress.show();
    PostDataAuth(apis.FILE_EXPORT, { type: dataType }).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        Progress.hide();
        message.success("File export successfully");
        window.open(`${IMAGE_PATH}/${responseJson.data}`);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
      }
    });
  };

  const alertTxt = () => {
    return (
      <ol className="alertTxt">
        <li>
          Download the sample format file.{" "}
          <a
            href={`${IMAGE_PATH}/download/sample/couses-import-sample.csv`}
            target="_blank"
            rel="noreferrer"
            className="alLink"
          >
            Download sample file
          </a>
        </li>
        <li>
          Export country data{" "}
          <span onClick={() => exportFile("country-all")} className="alLink">
            Export here
          </span>
        </li>
        <li>
          Export course level data.{" "}
          <span onClick={() => exportFile("course-level")} className="alLink">
            Export here
          </span>
        </li>
        <li>
          Export Subject data.{" "}
          <span onClick={() => exportFile("subject-all")} className="alLink">
            Export here
          </span>
        </li>
        <li>Fill the data in the format file</li>
        <li>Select the intake year</li>
        <li>Upload the file and click on import button</li>
      </ol>
    );
  };

  return (
    <Modal
      title="Import Data"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
    >
      <Spin tip="Uploading..." spinning={uploading}>
        <div className="step_model_body">
          <Alert
            message="Read the instructions carefully before importing data"
            description={alertTxt()}
            type="info"
            showIcon
          />
          <br />
          <Form
            autoComplete="off"
            form={form}
            onFinish={submitForm}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="year_id"
              required
              rules={[
                {
                  required: true,
                  message: "Year cannot be empty!",
                },
              ]}
            >
              <Select size="large" placeholder="Select Intake Year">
                {intakeYears.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="uploaded_file">
              <Input
                type="file"
                size="large"
                placeholder="Select Country"
                onChange={uploadFileChange}
                accept="csv"
              />
            </Form.Item>

            <div className="left_btn">
              <Button type="primary" className="primary__btn" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
}

export default ImportModel;
