import { Button, message, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import useBreadCrumb from "../hooks/useBreadCrumb";
import { apis, IMAGE_PATH } from "../properties";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CoursesTable from "../components/Courses/CoursesTable";
import { useStore } from "../store";
import ImportModel from "../components/Courses/ImportModel";
import ImportModelJson from "../components/Courses/ImportModelJson";

const { confirm } = Modal;

export default function CoursesManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Courses Management", location.pathname, "Courses Management");
  const actionPrivileges = useStore((state) => state.actionPrivileges);
  const [visible, setVisible] = useState(false);
  const [visibleImportJson, setVisibleImportJson] = useState(false);
  const [spinning, setSpinning] = useState(false);

  const exportFile = () => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `You are about to export all the courses to a CSV file.`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        setSpinning(true);
        PostDataAuth(apis.FILE_EXPORT, { type: "course-all" }).then(
          (result) => {
            let responseJson = result;
            if (responseJson.status === "success") {
              setSpinning(false);
              message.success("File export successfully");
              window.open(`${IMAGE_PATH}/${responseJson.data}`);
            } else if (responseJson.status === "error") {
              message.error(responseJson.message);
              setSpinning(false);
            }
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Spin spinning={spinning}>
      <div className="dashboard">
        <div className="section_row">
          <div className="top_row">
            {actionPrivileges.includes("COURSE_ADD_BTN") && (
              <Button
                className="primary__btn"
                onClick={() => navigate("/courses-management/create")}
              >
                Add Courses
              </Button>
            )}
            {actionPrivileges.includes("COURSE_ADD_EXPORT") && (
              <Button className="default__btn" onClick={() => exportFile()}>
                Export All
              </Button>
            )}
            {actionPrivileges.includes("COURSE_ADD_EXPORT") && (
              <Button className="default__btn" onClick={() => setVisible(true)}>
                Import CSV
              </Button>
            )}
            {/* {actionPrivileges.includes("COURSE_ADD_EXPORT") && (
              <Button
                className="default__btn"
                onClick={() => setVisibleImportJson(true)}
              >
                Import JSON
              </Button>
            )} */}
          </div>
          <CoursesTable />
        </div>
        {visible && (
          <ImportModel
            visible={visible}
            setVisible={() => {
              setVisible(!visible);
            }}
          />
        )}
        {visibleImportJson && (
          <ImportModelJson
            visible={visibleImportJson}
            setVisible={() => {
              setVisibleImportJson(!visibleImportJson);
            }}
          />
        )}
      </div>
    </Spin>
  );
}
