import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import DashBoardBox from "../components/DashBoardWidgets/DashBoardBox";
import useBreadCrumb from "../hooks/useBreadCrumb";
import useChart from "../hooks/useChart";
import useDashboard from "../hooks/useDashboard";
import { useStore } from "../store";
import RefIcon from "../assets/images/collaborate.png";
import { Select, message } from "antd";
// import usePieChart from "../hooks/usePieChart";
const { Option } = Select;

function Dashboard() {
  const location = useLocation();
  useBreadCrumb("Dashboard", location.pathname, "Dashboard");
  const { actionPrivileges, profileData } = useStore();
  const [SelectedYear, setSelectedYear] = useState(2023);
  const [years] = useState([2022, 2023]);

  const dashboardData = useDashboard(actionPrivileges, SelectedYear);
  const chartData = useChart(actionPrivileges);
  // const pieChartData = usePieChart(actionPrivileges);

  const copyRefUrl = () => {
    if (
      profileData?.refId &&
      profileData?.refId !== "" &&
      profileData?.refId !== undefined
    ) {
      const copyText =
        "https://portal.campusdirect.io/referral/" + profileData?.refId;
      navigator.clipboard.writeText(copyText);
      message.success("Referral link copied to clipboard");
    } else {
      message.error("Referral link not available yet please login again");
    }
  };

  const chartOptions = {
    series: [
      {
        name: "Monthly Enquiries",
        data: chartData.length !== 0 ? chartData.data : [],
      },
    ],
    options: {
      color: ["#008FFB"],
      chart: {
        background: "transparent",
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.8,
          opacityTo: 0.4,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: chartData.length !== 0 ? chartData.month : [],
      },
      legend: {
        position: "top",
        horizontalAlign: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.4
        },
      },
    },
  };

  return (
    <div className="dashboard">
      <div className="filter_row">
        <label>Filter By</label>
        <Select
          allowClear
          placeholder="Select Year"
          style={{ width: "10%" }}
          onChange={(value) => setSelectedYear(value)}
          value={SelectedYear}
        >
          {years.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>
      {actionPrivileges.includes("DASHBOARD_DATA") && (
        <div className="box_section">
          <DashBoardBox
            title="Students"
            count={dashboardData?.length !== 0 ? dashboardData?.user : 0}
            icon={"bi bi-people"}
          />
          <DashBoardBox
            title="Universities"
            count={dashboardData?.length !== 0 ? dashboardData?.university : 0}
            icon={"bi bi-ui-checks-grid"}
          />
          <DashBoardBox
            title="Courses"
            count={dashboardData?.length !== 0 ? dashboardData?.courses : 0}
            icon={"bi bi-handbag"}
          />
          <DashBoardBox
            title="Applications"
            count={dashboardData?.length !== 0 ? dashboardData?.application : 0}
            icon={"bi bi-hdd-stack"}
          />
        </div>
      )}
      {actionPrivileges.includes("ENQUIRY_CHART") && (
        <div className="section_row_chart">
          <p className="common_header">
            Enquiries Chart |<span>monthly enquiries</span>
          </p>
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type="area"
            height="300"
          />
        </div>
      )}

      <div className="referral_row_all" onClick={() => copyRefUrl()}>
        <div className="referral_row">
          <img src={RefIcon} alt="reff-icon" />
          <span>Referral URL</span>
          <p>
            You can share this link with your students to refer them to our
            enquiry form.
          </p>
        </div>
      </div>

      {/* {actionPrivileges.includes("DASHBOARD_PENDING_DATA") && (
        <div className="section_row">
          <p className="common_header">
            Pending Application |<span>pending application data here</span>
          </p>
          <PendingApplicationsTable />
        </div>
      )} */}
    </div>
  );
}

export default Dashboard;
