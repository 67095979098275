import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../../apiService/GetData";
import { apis } from "../../../properties";
import { useRefreshTable } from "../../../store";
import usePackageList from "../../../hooks/usePackageList";
import StatusTag from "../../../util/StatusTag";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

function PackageListTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const packageList = usePackageList(filterData);

  const columns = [
    {
      title: "Title",
      render: (record) => <p>{record.title}</p>,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Status",
      render: (record) => <StatusTag status={record.status} />,
      width: 150,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() =>
                navigate(`/settings/package-management/edit/${record.id}`)
              }
            />
          </Tooltip>
          &nbsp;
          <Tooltip placement="bottom" title="Delete">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteApplication(record.id)}
            />
          </Tooltip>
          &nbsp;
          <Tooltip placement="bottom" title="Download PDF">
            <Button
              className="view_button"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={() => downloadPDF(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 120,
      align: "right",
    },
  ];

  const downloadPDF = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to download PDF?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.AGENT_PACKAGE_DOWNLOAD}/${id}`).then((result) => {
          let responseJson = result;
          window.open(`${responseJson.data}`);
          message.success(responseJson.message);
          Progress.hide();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteApplication = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete? You can't undo this action`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.AGENT_PACKAGE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={packageList.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: packageList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default PackageListTable;
