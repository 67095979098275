import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { Button, Divider, Form, Input, message, Select } from "antd";
import useAgentRequestById from "../../hooks/useAgentRequestById";
import usePackageList from "../../hooks/usePackageList";
import useAgentPackageById from "../../hooks/useAgentPackageById";
import PackageDataTable from "../Settings/AgentPackages/PackageDataTable";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import Progress from "react-progress-2";

const { Option } = Select;
const ReviewAgentRequest = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const params = useParams();

  useBreadCrumb("Review Request", location.pathname, "", "add");

  const [filterData] = useState({
    page: 1,
    pageSize: "all",
  });
  const packageList = usePackageList(filterData);
  const [agentPackageId, setAgentPackageId] = useState(2);

  const packageById = useAgentPackageById(agentPackageId);
  const [currency, setCurrency] = useState("USD");
  const [directUniList, setDirectUniList] = useState([]);
  const [partnersUniList, setPartnersUniList] = useState([]);
  const [countryId, setCountryId] = useState();
  const [status, setStatus] = useState();

  const filteredUniversities = useUniversityByCountry(countryId);

  const requestById = useAgentRequestById(params.id);

  useEffect(() => {
    if (packageById) {
      form.setFieldsValue({
        title: packageById.title,
        country_id: packageById.country_id,
      });
      setCurrency(packageById.currency);
      setCountryId(packageById.country_id);
      setDirectUniList(packageById?.direct_universities ?? []);
      setPartnersUniList(packageById?.partner_universities ?? []);
    }
    // eslint-disable-next-line
  }, [packageById]);

  useEffect(() => {
    if (requestById) {
      form.setFieldsValue({
        first_name: requestById.first_name,
        last_name: requestById.last_name,
        company_name: requestById.company_name,
        email: requestById.email,
        phone: requestById.phone,
        agent_type: requestById.agent_type,
        address: requestById.address,
        country_name: requestById.country_name,
        agent_package_id: agentPackageId,
        status: requestById.status,
      });
      setStatus(requestById.status);
    }
    // eslint-disable-next-line
  }, [requestById]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      const packageTitle = packageList.find(
        (item) => item.id === agentPackageId
      ).title;

      const formData = {
        ...values,
        country_id: countryId,
        currency: currency,
        direct_universities: status === "AGREEMENT SENT" ? directUniList : [],
        partner_universities:
          status === "AGREEMENT SENT" ? partnersUniList : [],
        agent_package_title: status === "AGREEMENT SENT" ? packageTitle : "",
      };
      Progress.show();
      PostDataAuth(
        `${apis.AGENT_ONBOARDING_APP}/${params.id}/review`,
        formData
      ).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success(responseJson.message);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
        }
        Progress.hide();
        window.history.back();
      });
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Review Request |<span>Review the agent request here</span>
        </p>
        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item name="first_name" label="First Name">
            <Input size="large" placeholder="First Name" disabled />
          </Form.Item>

          <Form.Item name="last_name" label="Last Name">
            <Input size="large" placeholder="Last Name" disabled />
          </Form.Item>

          <Form.Item name="company_name" label="Company Name">
            <Input size="large" placeholder="Company Name" disabled />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input size="large" placeholder="Email" disabled />
          </Form.Item>

          <Form.Item name="phone" label="Contact Number">
            <Input size="large" placeholder="Contact Number" disabled />
          </Form.Item>

          <Form.Item name="agent_type" label="Agent Type">
            <Input size="large" placeholder="Agent Type" disabled />
          </Form.Item>

          <Form.Item name="country_name" label="Country">
            <Input size="large" placeholder="Country" disabled />
          </Form.Item>

          <Form.Item name="address" label="Address">
            <Input size="large" placeholder="Address" disabled />
          </Form.Item>

          {requestById?.br_doc_url && (
            <Form.Item name="br_doc_url" label="Business Registration Document">
              <a
                href={requestById?.br_doc_url}
                target="_blank"
                rel="noreferrer"
              >
                View Document
              </a>
            </Form.Item>
          )}

          <Form.Item name="status" label="Status">
            <Select
              size="large"
              placeholder="Select Status"
              onChange={(value) => setStatus(value)}
              value={status}
            >
              <Option value="REJECTED">REJECTED</Option>
              <Option value="AGREEMENT SENT">AGREEMENT SENT</Option>
            </Select>
          </Form.Item>

          {status === "AGREEMENT SENT" && (
            <>
              <Divider orientation="left" plain>
                Agent Package
              </Divider>
              <Select
                size="large"
                placeholder="Agent Package"
                onChange={(value) => setAgentPackageId(value)}
                value={agentPackageId}
                style={{ width: "100%" }}
              >
                {packageList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>

              <Divider orientation="left" plain>
                Direct Universities
              </Divider>
              <PackageDataTable
                filteredUniversities={filteredUniversities ?? []}
                currency={currency}
                data={directUniList}
                setData={setDirectUniList}
              />
              <Divider orientation="left" plain>
                Partner Universities
              </Divider>
              <PackageDataTable
                filteredUniversities={filteredUniversities ?? []}
                currency={currency}
                data={partnersUniList}
                setData={setPartnersUniList}
              />
            </>
          )}
          <br />
          <Form.Item name="feedback" label="Review Feedback (Optional)">
            <Input.TextArea size="large" placeholder="Review Feedback" />
          </Form.Item>

          <div className="top_row">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ReviewAgentRequest;
