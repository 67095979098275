export let dashboardStore = (set) => ({
  dashboardData: [],
  chartData: [],
  applicationsData: [],
  setDashboardData: (dashboardData) => {
    set((state) => ({ ...state, dashboardData: dashboardData }));
  },
  setChartData: (chartData) => {
    set((state) => ({ ...state, chartData: chartData }));
  },
  setApplicationsData: (applicationsData) => {
    set((state) => ({ ...state, applicationsData: applicationsData }));
  },
  setClearDashboard: () => {
    set((state) => ({
      ...state,
      dashboardData: [],
      chartData: [],
      applicationsData: [],
    }));
  }
});
