import { Button, Form, Input, message, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { PostDataAuth } from "../../../apiService/PostData";
import { apis } from "../../../properties";
import Progress from "react-progress-2";
import { useRefreshTable } from "../../../store";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import useIntakeMonthById from "../../../hooks/useIntakeMonthById";

export default function AddIntakeMonths({ visible, setVisible, type, selectId }) {
  const [form] = Form.useForm();

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [isActive, setIsActive] = useState(true);

  const intakeMonthById = useIntakeMonthById(selectId);

  useEffect(() => {
    if (type === "edit" && intakeMonthById.length !== 0 ) {
      setIsActive(intakeMonthById.status === 1 ? true : false);
      form.setFieldsValue({
        months: intakeMonthById.months,
        status: intakeMonthById.status === 1 ? true : false,
      });
    }
    // eslint-disable-next-line
  }, [intakeMonthById, type]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      var data;
      var URL;
      if (type === "add") {
        data = {
          ...values,
        };
        URL = apis.INTAKE_MONTHS_CREATE;
      } else {
        data = {
          ...values,
          status: isActive ? 1 : 0,
          id: selectId,
        };
        URL = apis.INTAKE_MONTHS_UPDATE;
      }
      Progress.show();
      PostDataAuth(URL, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          form.resetFields();
          setRefreshTable(!refreshTable);
          setVisible(false);
          message.success(responseJson.message);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  return (
    <Modal
      title={type === "add" ? "Add Intake Months" : "Edit Intake Months"}
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="months"
            rules={[
              {
                required: true,
                message: "Intake Month cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Intake Month" />
          </Form.Item>
          {type !== "add" && (
            <div style={{ display: "flex", gap: "5px" }}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              <p>Status</p>
            </div>
          )}
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
