import React from "react";
import useBreadCrumb from "../hooks/useBreadCrumb";
import BlogDataTable from "../components/Blogs/BlogsDataTable";

import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export default function Blogs() {
  const location = useLocation();
  const navigate = useNavigate();

  useBreadCrumb("Blog Management", location.pathname, "blog Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/blog-management/create")}
          >
            Add Blog
          </Button>
        </div>
        <BlogDataTable />
      </div>
    </div>
  );
}
