import { Button, DatePicker, Divider, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { uploadAdapter } from "../../helpers/uploadAdapter";
import { convertToSlug } from "../../helpers/convertToSlug";
import FileUpload from "../../util/FileUpload";

function AddBlog({ type }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [desData, setDesData] = useState();
  const [featureImage, setFeatureImage] = useState();

  useBreadCrumb("Create blog", location.pathname, "", "add");

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const data = {
        ...values,
        blog_description: desData,
        createdAt: moment(values.createdAt).format("YYYY-MM-DD"),
        image_path: featureImage
      };
      console.log(data, "blog-data");
      PostDataAuth(apis.BLOGS, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <p className="common_header">Create New Blog</p>
        </div>

        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input
              type="text"
              size="large"
              placeholder="blog title"
              onChange={(e) => {
                if (type !== "FIXED_SLUG") {
                  form.setFieldsValue({
                    slug: convertToSlug(e.target.value),
                  });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Slug"
            name="slug"
            rules={[{ required: true, message: "Slug cannot be empty!" }]}
          >
            <Input
              type="text"
              size="large"
              placeholder="Slug"
              disabled={true}
            />
          </Form.Item>

          <Form.Item name="description" label="blog Description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>

          <Form.Item
            name="image_path"
            label="Feature Image (Image Size: 300x300px)"
            required
          >
            <FileUpload
              type="image"
              location="blog"
              count={1}
              imageUpload={featureImage}
              setImageUpload={setFeatureImage}
              accept=".png, .jpg, .jpeg"
              prefix="blog"
            />
          </Form.Item>
          <Form.Item
            label="Author"
            name="author"
            rules={[
              {
                required: true,
                message: "Author cannot be empty!",
              },
            ]}
          >
            <Input
              type="text"
              size="large"
              placeholder="Author Name"
              onChange={(e) => {
                e.target.name = e.target.value;
              }}
            />
          </Form.Item>

          <Form.Item
            name="createdAt"
            label="Blog Created Date"
            rules={[
              { required: true, message: "Date & Time cannot be empty!" },
            ]}
          >
            <DatePicker
              size="large"
              format="YYYY-MM-DD"
              placeholder="Blog Created Date"
              style={{
                width: "300px",
              }}
            />
          </Form.Item>

          <Divider orientation="left" orientationMargin="0">
            SEO Details
          </Divider>
          <Form.Item
            label="Meta Title"
            name="seo_title"
            rules={[
              {
                required: true,
                message: "Meta Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Meta Title" />
          </Form.Item>

          <Form.Item
            label="Meta Description"
            name="seo_description"
            rules={[
              {
                required: true,
                message: "Meta Description cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Meta Description" />
          </Form.Item>

          <Form.Item
            label="Meta Keywords"
            name="seo_keywords"
            rules={[
              {
                required: true,
                message: "Meta Keywords cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Meta Keywords" />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddBlog;
