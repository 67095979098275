import { Button, Form, Input, message, Radio, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Logo from "../assets/images/Logo-Clr.png";
import Progress from "react-progress-2";
import { PostData } from "../apiService/PostData";
import { apis } from "../properties";
import { useNavigate, useParams } from "react-router-dom";
import { GetData } from "../apiService/GetData";
import useAllCountries from "../hooks/useAllCountries";
import useCities from "../hooks/useCities";
import useStates from "../hooks/useStates";
import FileUpload from "../util/FileUpload";

const { Option } = Select;

export default function WelcomeUser() {
  let params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [filterDataOther] = useState({
    status: "all",
  });
  const [postData, setPostData] = useState({});
  const [userData, setUserData] = useState({});
  const [upDocBR, setUpDocBR] = useState();

  const countries = useAllCountries(filterDataOther);
  const states = useStates(postData.country_id ? postData.country_id : 0);
  const cities = useCities(postData.state_id ? postData.state_id : 0);

  useEffect(() => {
    welcomeUserData();
    // eslint-disable-next-line
  }, []);

  const welcomeUserData = () => {
    Progress.show();
    GetData(`${apis.WELCOME_USER}/${params.id}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        form.setFieldsValue({
          email: responseJson.data.email,
          role: responseJson.data.role,
        });
        setUserData(responseJson.data);
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
      }
    });
  };

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    Progress.show();
    const formData = {
      ...form.getFieldsValue(),
      role_id: userData.role_id,
      br_doc: upDocBR,
      admin_agent_id: userData.agent_admin_id ? userData.agent_admin_id : 0,
      created_by: userData.created_by,
      c_role_id: userData.c_role_id,
    };
    PostData(apis.USER_REGISTER, formData).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        Progress.hide();
        message.success(responseJson.message);
        navigate("/user-register-success");
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
      }
    });
  };

  return (
    <div className="outer__container">
      <Form
        autoComplete="off"
        form={form}
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
      >
        <div className="outer_box">
          <div className="outer_side_content">
            <div className="outer_logo">
              <img src={Logo} alt="logo" />
            </div>
            <div className="content">
              <div className="login_title">
                <h1>Welcome to Campus Direct</h1>
                <p>
                  Complete your account by creating a master password and
                  reviewing user details
                </p>
              </div>
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: "First name cannot be empty!" },
                ]}
              >
                <Input type="text" placeholder="Your first name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: "Last name cannot be empty!" },
                ]}
              >
                <Input type="text" placeholder="Your last name" />
              </Form.Item>
              <Form.Item name="company_name">
                <Input type="text" placeholder="Company Name (Optional)" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Contact number cannot be empty!",
                  },
                ]}
              >
                <Input type="text" placeholder="Your contact number" />
              </Form.Item>
              <Form.Item name="email">
                <Input type="text" placeholder="Your Email name" disabled />
              </Form.Item>
              <Form.Item name="role">
                <Input type="text" placeholder="Your User Role" disabled />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Password cannot be empty!" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
          </div>
          <div className="outer_side_content">
            <Form.Item name="address">
              <Input type="text" placeholder="Your address" />
            </Form.Item>
            <Form.Item
              name="country_id"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select user country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setPostData({ ...postData, country_id: value });
                }}
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="state_id"
              rules={[
                {
                  required: true,
                  message: "State cannot be empty!",
                },
              ]}
            >
              <Select
                placeholder="Select user state"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setPostData({ ...postData, state_id: value });
                }}
              >
                {states.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="city_id"
              rules={[
                {
                  required: true,
                  message: "city cannot be empty!",
                },
              ]}
            >
              <Select
                placeholder="Select user city"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setPostData({ ...postData, city_id: value });
                }}
              >
                {cities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {userData.role === "Agent-Admin" && (
              <>
                <div className="rg_box">
                  <p className="common_header">
                    Business Type |<span>select your business type</span>
                  </p>
                  <Form.Item name="agent_type">
                    <Radio.Group>
                      <Radio value="Individual">Individual</Radio>
                      <Radio value="Company">Company</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="rg_box">
                  <p className="common_header">
                    Upload BR |<span>Upload your business registration</span>
                  </p>
                  <FileUpload
                    type="file"
                    location="documents/business_registration"
                    count={1}
                    imageUpload={upDocBR}
                    setImageUpload={setUpDocBR}
                    accept="application/pdf"
                    prefix="br"
                  />
                </div>
              </>
            )}
            <Button
              type="primary"
              size="large"
              className="primary__btn full_width"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
      <div className="footer_2">
        <p> &copy; {moment().year()} Campus Direct. All rights reserved.</p>
      </div>
    </div>
  );
}
