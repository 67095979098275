import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import Progress from "react-progress-2";

export default function PasswordChange() {
  const [form] = Form.useForm();
  const [result, setResult] = useState(null);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      PostDataAuth(apis.CHANGE_PASSWORD, form.getFieldsValue()).then(
        (result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            message.success(responseJson.message);
            setResult({ result: "success" });
            form.resetFields();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
            setResult({ result: "error" });
          }
        }
      );
    });
  };

  return (
    <div className="section_row">
      <p className="common_header">
        Change Password |<span>edit your password</span>
      </p>
      <Form
        autoComplete="off"
        form={form}
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Current Password"
          name="current_password"
          hasFeedback
          rules={[
            { required: true, message: "Please input your current password!" },
          ]}
          {...(result && {
            hasFeedback: true,
            help:
              result.result === "success"
                ? "Password changed successfully"
                : "Password Mismatch! Please try again",
            validateStatus: result.result === "success" ? "success" : "error",
          })}
        >
          <Input type="password" size="large" placeholder="Current Password" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input type="password" size="large" placeholder="New Password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          hasFeedback
          rules={[
            { required: true, message: "Please confirm your new password!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Password does not match!");
                }
              },
            }),
          ]}
        >
          <Input type="password" size="large" placeholder="Confirm Password" />
        </Form.Item>

        <div className="left_btn">
          <Button type="primary" className="primary__btn" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
