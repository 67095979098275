import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { usePublicStore, useRefreshTable } from "../store";

function useApplicationStatusList(filterData) {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const {applicationStatus, setApplicationStatus} = usePublicStore();

  useEffect(() => {
    const getData = () => {
      Progress.show();
      PostDataAuth(apis.APPLICATION_STATUS_LIST, filterData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          filterData?.isStore &&  setApplicationStatus(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    if (!filterData?.isStore) {
      getData();
    } else {
      applicationStatus?.length === 0 ? getData() : setState(applicationStatus);
    }
    // eslint-disable-next-line
  }, [filterData, refreshTable]);
  return state;
}

export default useApplicationStatusList;
