import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  Select,
} from "antd";
import React, { useState } from "react";
import { PostDataAuth } from "../../apiService/PostData";
import useAllUserList from "../../hooks/useAllUserList";
import useAllUserListAgents from "../../hooks/useAllUserListAgents";
import useApplicationStatusList from "../../hooks/useApplicationStatusList";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import { apis, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const ApplicationFilterRow = ({
  countries,
  intakeYears,
  intakeMonths,
  courseLevels,
}) => {
  const [form] = Form.useForm();
  const [validTime, setValidTime] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const [postData, setPostData] = useState({
    country_id: "all",
  });

  const [assign_filter] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    role: [2, 4],
    dataTable: false,
  });

  const applicationStatusList = useApplicationStatusList();
  const allAgentList = useAllUserListAgents();
  const filteredUniversities = useUniversityByCountry(postData.country_id);
  const allUserList = useAllUserList(assign_filter);

  const onFinish = (values) => {
    const data = {
      ...form.getFieldsValue(),
      start_date: validTime && validTime[0],
      end_date: validTime && validTime[1],
      reportFrom: "APPLICATION-REPORTS",
    };
    setBtnLoading(true);
    Progress.show();
    PostDataAuth(apis.USER_REPORTS, data).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        message.success("Report Generated successfully");
        window.open(`${IMAGE_PATH}/${responseJson.data}`);
        Progress.hide();
        setBtnLoading(false);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
        setBtnLoading(false);
      }
    });
  };

  const clearData = () => {
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Reports from Applications">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="date_range" noStyle>
              <RangePicker
                allowClear
                style={{ width: "95%" }}
                format={dateFormat}
                onChange={(date, dateString) => {
                  setValidTime(dateString);
                }}
              />
            </Form.Item>
            <Form.Item name="agent_id" noStyle>
              <Select
                placeholder="Select Agent Admin"
                style={{ width: "100%" }}
                allowClear
              >
                {allAgentList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.first_name} {item.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="app_status" noStyle>
              <Select
                placeholder="Select Application Status"
                style={{ width: "100%" }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {applicationStatusList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.stat_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item name="app_id" noStyle>
              <Input
                placeholder="Application ID"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="student_name" noStyle>
              <Input
                placeholder="Student Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="course_name" noStyle>
              <Input
                placeholder="Course Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
          </div>

          {/* Select row */}

          <div className="row">
            <Form.Item name="country_id" noStyle>
              <Select
                allowClear
                placeholder="Select Country"
                style={{ width: "100%" }}
                onChange={(value) =>
                  setPostData({ ...postData, country_id: value })
                }
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="uni_id" noStyle>
              <Select
                placeholder="Select University"
                style={{ width: "100%" }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {filteredUniversities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="assign_id" noStyle>
              <Select
                placeholder="Select Assign To"
                style={{ width: "100%" }}
                allowClear
              >
                {allUserList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.first_name + " " + item.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          {/* Select row */}
          <div className="row">
            <Form.Item name="course_level_id" noStyle>
              <Select
                allowClear
                placeholder="Select Course Level"
                style={{ width: "100%" }}
              >
                {courseLevels.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_month_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Month"
                style={{ width: "100%" }}
              >
                {intakeMonths.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.months}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_year_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Year"
                style={{ width: "100%" }}
              >
                {intakeYears.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit" btnLoading={btnLoading}>
              Generate Report
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
