export let publicStore = (set) => ({
    country: [],
    countryList: [],
    courseLevels: [],
    intakeMonths: [],
    intakeYears: [],
    applicationStatus: [],
    agentList: [],
    setCountry: (country) => {
        set((state) => ({ ...state, country: country }));
    },
    setCountryList: (countryList) => {
        set((state) => ({ ...state, countryList: countryList }));
    },
    setCourseLevels: (courseLevels) => {
        set((state) => ({ ...state, courseLevels: courseLevels }));
    },
    setIntakeMonths: (intakeMonths) => {
        set((state) => ({ ...state, intakeMonths: intakeMonths }));
    },
    setIntakeYears: (intakeYears) => {
        set((state) => ({ ...state, intakeYears: intakeYears }));
    },
    setApplicationStatus: (applicationStatus) => {
        set((state) => ({ ...state, applicationStatus: applicationStatus }));
    },
    setAgentList: (agentList) => {
        set((state) => ({ ...state, agentList: agentList }));
    },
    setClearPublicData: () => {
        set((state) => ({
            ...state,
            country: [],
            countryList: [],
            courseLevels: [],
            intakeMonths: [],
            intakeYears: [],
            applicationStatus: [],
            agentList: [],
        }));
    }
});
