import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../../apiService/GetData";
import useRequiredDocuments from "../../../hooks/useRequiredDocuments";
import { apis } from "../../../properties";
import { useRefreshTable } from "../../../store";
import AddRequireDoc from "./AddRequireDoc";

const { confirm } = Modal;

function RequirementsDocumentsTable() {

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [visible, setVisible] = useState(false);
  const [selectId, setSelectId] = useState('');

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    dataTable: true,
  });

  const requiredDocuments = useRequiredDocuments(filterData);

  const columns = [
    {
      title: "Document Name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record) => <p>{record.name}</p>,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Created Date",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
      width: 150,
      fixed: "right",
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectId(record.id);
                setVisible(true);
              }}
            />
          </Tooltip>&nbsp;
          <Tooltip placement="bottom" title="Delete">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteApplication(record.id)}
            />
          </Tooltip>{" "}
        </>
      ),
      fixed: "right",
      width: 100,
    },
  ];

  const deleteApplication = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this doc? You can't undo this action`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.REQUIRED_DOCUMENTS_DELETE}?id=${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={requiredDocuments.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: requiredDocuments.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
       {visible && (
        <AddRequireDoc
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
          type="edit"
          selectId={selectId}
        />
      )}
    </>
  );
}

export default RequirementsDocumentsTable;
