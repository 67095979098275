import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();

  const [validTime, setValidTime] = useState();

  useEffect(() => {
    form.setFieldsValue(filterData);
    // eslint-disable-next-line
  }, [filterData]);

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      name: values.name ? values.name : "",
      status: values.status ? values.status : "",
      start_date: validTime ? validTime[0] : "",
      end_date: validTime ? validTime[1] : "",
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      name: "",
      date_range: "",
      status: "",
      start_date: "",
      end_date: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="name" noStyle>
              <Input
                placeholder="Student Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="date_range" noStyle>
              <RangePicker
                allowClear
                style={{ width: "100%" }}
                format={dateFormat}
                onChange={(date, dateString) => {
                  setValidTime(dateString);
                }}
              />
            </Form.Item>
            <Form.Item name="status" noStyle>
              <Select placeholder="Status" style={{ width: "100%" }} allowClear>
                <Option value="APPLIED">APPLIED</Option>
                <Option value="DELETED">DELETED</Option>
                <Option value="REJECTED">REJECTED</Option>
                <Option value="DRAFT">DRAFT</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
