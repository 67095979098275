import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import useInvitationsList from "../../hooks/useInvitationsList";
import { GetDataAuth } from "../../apiService/GetData";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { useMediaQuery } from "react-responsive";
import { FilterRowInvite } from "./FilterRowInvite";
import { permanentDelete } from "../../helpers/permanentDelete";
import { useRefreshTable } from "../../store";

const { confirm } = Modal;

function InvitationsTable() {
  const isMobileDevice = useMediaQuery({ query: "(max-width: 600px)" });
  const { refreshTable, setRefreshTable } = useRefreshTable();

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    order: "DESC",
    sortBy: "CREATED_AT",
    email: "",
  });

  const invitations = useInvitationsList(filterData);

  const columns = [
    {
      title: "Email Address",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: "User Type",
      render: (record) => (
        <Tag className="tags" color="#096dd9">
          {record.role}
        </Tag>
      ),
      width: 180,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Seen" : "Unseen"}
        </Tag>
      ),
      width: 180,
    },
    {
      title: "Created Date",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Created By",
      sorter: (a, b) => a.created_fname.length - b.created_fname.length,
      render: (record) => (
        <p>
          {record.created_fname} {record.created_lname}
        </p>
      ),
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Copy Link">
            <Button
              className="view_button"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(
                  process.env.REACT_APP_WEB_URL +
                    "user-invitation/" +
                    record.id
                );
                message.success("Link copied to clipboard");
              }}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Resend invitation">
            <Button
              className="view_button"
              shape="circle"
              icon={<SendOutlined />}
              onClick={() => statusChange(record.id)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete permanently">
            <Button
              className="view_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() =>
                permanentDelete(
                  "user_invitations",
                  record.id,
                  refreshTable,
                  setRefreshTable
                )
              }
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 130,
    },
  ];

  const statusChange = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to resend invitation?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.RESEND_INVITATION}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success("Invitation sent successfully");
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <FilterRowInvite filterData={filterData} setFilterData={setFilterData} />
      <Table
        scroll={isMobileDevice ? { x: 600 } : ""}
        columns={columns}
        dataSource={invitations.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: invitations.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default InvitationsTable;
