import { Button, Form, Input, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Logo from "../assets/images/full-logo.png";
import Progress from "react-progress-2";
import { PostData } from "../apiService/PostData";
import { apis } from "../properties";
import { Link, useParams, useNavigate } from "react-router-dom";

export default function ResetPassword() {
  let params = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    Progress.show();
    setLoading(true);
    const data = {
      ...form.getFieldsValue(),
      code: params.id,
    };
    PostData(apis.RESET_PASSWORD, data).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        message.success(responseJson.message);
        Progress.hide();
        setLoading(false);
        navigate("/dashboard");
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
        setLoading(false);
      }
    });
  };

  return (
    <div className="outer__container">
      <div className="outer_box">
        <div className="outer_side_banner"></div>
        <div className="outer_side_content">
          <div className="outer_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="content">
            <div className="login_title">
              <h1>New Password</h1>
              <p>
                Enter your new password and confirm it to reset your password.
              </p>
            </div>
            <Form
              autoComplete="off"
              form={form}
              onFinish={submitForm}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input
                  type="password"
                  size="large"
                  placeholder="New Password"
                />
              </Form.Item>
              <Form.Item
                name="password_confirmation"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Password does not match!");
                      }
                    },
                  }),
                ]}
              >
                <Input
                  type="password"
                  size="large"
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Button
                type="primary"
                size="large"
                className="primary__btn full_width"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </Form>
            <p className="signup-link">
              Remember your password? <Link to="/login">Sign In</Link>
            </p>
          </div>
        </div>
      </div>
      <div className="footer">
        <p> &copy; {moment().year()} Campus Direct. All rights reserved.</p>
      </div>
    </div>
  );
}
