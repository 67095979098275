import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CareerDataTable from "../components/Careers/CareerDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";

export default function Careers() {
  const location = useLocation();
  const navigate = useNavigate();

  useBreadCrumb("Career Management", location.pathname, "career Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/career-management/create")}
          >
            Add Career
          </Button>
        </div>
        <CareerDataTable />
      </div>
    </div>
  );
}
