import { Button } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/full-logo.png";

export default function WelcomeSuccess() {
  const navigate = useNavigate();

  return (
    <div className="outer__container">
      <div className="outer_box">
        <div className="outer_side_banner"></div>
        <div className="outer_side_content">
          <div className="outer_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="content">
            <div className="login_title">
              <h1>Thank you</h1>
              <p>
                Your account is successfully created. Please wait for the
                approval. We will send an email once your account was active.
              </p>
            </div>
            <Button
              type="primary"
              size="large"
              className="primary__btn full_width"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
      <div className="footer">
        <p> &copy; {moment().year()} Campus Direct. All rights reserved.</p>
      </div>
    </div>
  );
}
