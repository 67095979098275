import { Button, Form, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { PostDataAuth } from "../../apiService/PostData";
import useAllUserList from "../../hooks/useAllUserList";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { useRefreshTable } from "../../store";

const { Option } = Select;

export const AssignModel = ({ visible, setVisible, application }) => {
  const [form] = Form.useForm();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    role: [2, 4],
    dataTable: false,
  });
  const allUserList = useAllUserList(filterData);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      console.log(values);
      var postData = {
        ...values,
        id: application.id,
        user_id: values.user_id,
      };
      PostDataAuth(apis.ASSIGN_EMPLOYEE, postData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success("User assigned successfully");
          setRefreshTable(!refreshTable);
          setVisible(false);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
      setVisible(false);
    });
  };

  return (
    <Modal
      title={`Application id - ${application.app_unique_id}`}
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <p></p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="assigned_to"
            required
            rules={[
              {
                required: true,
                message: "Employee cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Select Employee"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {allUserList.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.first_name +
                    " " +
                    item.last_name +
                    " (" +
                    item.role +
                    ")"}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
