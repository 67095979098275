import React from "react";

const IeltsInfo = ({ data }) => {
  return (
    <div>
      <p className="common_header">
        IELTS / PTE Details |<span>student exam details</span>
      </p>
      <div className="view_box_body">
        <div className="view_row">
          <p className="type">Type of Exam</p>
          <p className="data">{data.exam_type ? data.exam_type : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Date Of Exam</p>
          <p className="data">{data.examDate ? data.examDate : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Overall Score</p>
          <p className="data">
            {data.overall_score ? data.overall_score : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Listening Score</p>
          <p className="data">
            {data.listening_score ? data.listening_score : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Reading Score</p>
          <p className="data">
            {data.reading_score ? data.reading_score : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Writing Score</p>
          <p className="data">
            {data.writing_score ? data.writing_score : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Speaking Score</p>
          <p className="data">
            {data.speaking_score ? data.speaking_score : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default IeltsInfo;
