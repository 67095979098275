import React from "react";

const SubPhdInfo = ({ data }) => {
  return (
    <div>
      <p className="common_header">
        PHD/Doctorate Details |<span>education details</span>
      </p>
      <div className="view_box_body">
        <div className="view_row">
          <p className="type">Course Name</p>
          <p className="data">{data.course_name ? data.course_name : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Country of Institution</p>
          <p className="data">
            {data.country_institution ? data.country_institution : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Name Of Institute</p>
          <p className="data">
            {data.name_institute ? data.name_institute : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Primary Level of Language</p>
          <p className="data">
            {data.primary_language ? data.primary_language : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Attended Date</p>
          <p className="data">
            {data.attended_from ? data.attended_from : "-"} <b>to</b>{" "}
            {data.attended_to ? data.attended_to : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Certificate Number</p>
          <p className="data">
            {data.certificate_number ? data.certificate_number : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Core Subject Marks</p>
          <p className="data">
            {data.subject_marks ? data.subject_marks : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Percentage</p>
          <p className="data">{data.percentage ? data.percentage : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Institute Address</p>
          <p className="data">
            {data.school_address ? data.school_address : "-"}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Institute State</p>
          <p className="data">{data.school_state ? data.school_state : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Institute City</p>
          <p className="data">{data.school_city ? data.school_city : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Institute Postal Code</p>
          <p className="data">{data.postal_code ? data.postal_code : "-"}</p>
        </div>
      </div>
    </div>
  );
};

export default SubPhdInfo;
