import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import { DatePicker } from "antd";
import { PostDataAuth } from "../../apiService/PostData";
import { apis, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const UniversityFilterRow = ({ countries }) => {
  const [form] = Form.useForm();
  const [validTime, setValidTime] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const onFinish = (values) => {
    const data = {
      ...form.getFieldsValue(),
      start_date: validTime && validTime[0],
      end_date: validTime && validTime[1],
      reportFrom: "UNIVERSITY-REPORTS",
    };
    setBtnLoading(true);
    Progress.show();
    PostDataAuth(apis.USER_REPORTS, data).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        message.success("Report Generated successfully");
        window.open(`${IMAGE_PATH}/${responseJson.data}`);
        Progress.hide();
        setBtnLoading(false);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
        setBtnLoading(false);
      }
    });
  };

  const clearData = () => {
    form.resetFields();
    setBtnLoading(false);
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Reports from Universities">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="date_range" noStyle>
              <RangePicker
                allowClear
                style={{ width: "100%" }}
                format={dateFormat}
                onChange={(date, dateString) => {
                  setValidTime(dateString);
                }}
              />
            </Form.Item>
            <Form.Item name="name" noStyle>
              <Input placeholder="University Name" allowClear />
            </Form.Item>
            <Form.Item name="country_id" noStyle>
              <Select
                placeholder="Select Country"
                style={{ width: "100%" }}
                allowClear
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item name="havePaperBase" noStyle>
              <Select
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Have Paper Base"
              >
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            </Form.Item>
            <Form.Item name="status" noStyle>
              <Select
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Select user status"
              >
                <Option value={1}>Active</Option>
                <Option value={0}>Inactive</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button loading={btnLoading} type="primary" htmlType="submit">
              Generate Report
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
