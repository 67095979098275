import { Button, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import InvitationsTable from "./InvitationsTable";
import Progress from "react-progress-2";
import useUserTypes from "../../hooks/useUserTypes";
import { useRefreshTable } from "../../store";
import useAllUserListAgents from "../../hooks/useAllUserListAgents";
import ImportModel from "./ImportModel";

const { Option } = Select;

const InviteUsers = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  useBreadCrumb("Invite users", location.pathname, "", "add");
  const [visible, setVisible] = useState(false);

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);

  const [selectAdmin, setSelectAdmin] = useState(false);

  const userTypes = useUserTypes({ status: 1 });
  const allUserList = useAllUserListAgents();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      var data = {
        ...values,
        agent_admin_id: selectAdmin ? values.agent_admin_id : null,
      };
      PostDataAuth(apis.USER_INVITATION_SEND, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success("Invitation sent successfully");
          setRefreshTable(true);
          form.resetFields();
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };
  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Invite users |<span>send an invitation</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="user_type"
            required
            rules={[
              {
                required: true,
                message: "User type cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Select user role"
              onChange={(value) => setSelectAdmin(value === 9 ? true : false)}
            >
              {userTypes.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectAdmin && (
            <Form.Item
              name="agent_admin_id"
              required
              rules={[
                {
                  required: true,
                  message: "Agent admin name cannot be empty!",
                },
              ]}
            >
              <Select size="large" placeholder="Select an agent admin">
                {allUserList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.first_name} {item.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Email cannot be empty!" },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Email Address" />
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      <div className="section_row">
        <p className="common_header">
          All invited users |<span>Invitations history and status</span>
        </p>
        <InvitationsTable />
      </div>
      {visible && (
        <ImportModel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
        />
      )}
    </div>
  );
};

export default InviteUsers;
