export let enquiryDataStore = (set) => ({
  enquiryData: {
    applications: [],
    uploadDocuments: [],
    refusedDoc: "",
    personalData: {},
    addressData: {},
    educationData: {
      detailsOne: {},
      detailsTwo: {},
      diplomaDetails: {},
      graduateDetails: {},
      postGraduateDetails: {},
      phdDetails: {},
    },
    ieltsData: {},
  },
  setEnquiryData: (enquiryData) => {
    set((state) => ({ ...state, enquiryData: enquiryData }));
  },
});
