import { Button, Form, Input, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Logo from "../assets/images/full-logo.png";
import Progress from "react-progress-2";
import { PostData } from "./../apiService/PostData";
import { apis } from "../properties";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    Progress.show();
    setLoading(true);
    PostData(apis.FORGOT_PASSWORD, form.getFieldsValue()).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        Progress.hide();
        Swal.fire({
          title: "Send Password Reset Link",
          text: "The link to reset your password has been sent to your email. If you do not receive it within a reasonable amount of time, check your spam/junk folders.        ",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        setLoading(false);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
        setLoading(false);
      }
    });
  };

  return (
    <div className="outer__container">
      <div className="outer_box">
        <div className="outer_side_banner"></div>
        <div className="outer_side_content">
          <div className="outer_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="content">
            <div className="login_title">
              <h1>Reset Password</h1>
              <p>
                Enter your email address below and we’ll send you a link to
                reset your password
              </p>
            </div>
            <Form
              autoComplete="off"
              form={form}
              onFinish={submitForm}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Username cannot be empty!" },
                ]}
              >
                <Input type="text" size="large" placeholder="Email Address" />
              </Form.Item>
              <Button
                type="primary"
                size="large"
                className="primary__btn full_width"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </Form>
            <p className="signup-link">
              Remember your password? <Link to="/login">Sign In</Link>
            </p>
          </div>
        </div>
      </div>
      <div className="footer">
        <p> &copy; {moment().year()} Campus Direct. All rights reserved.</p>
      </div>
    </div>
  );
}
