import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Table, Button, Input, Popconfirm, Select } from "antd";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const PackageDataTable = ({
  filteredUniversities,
  currency,
  data,
  setData,
}) => {
  const [editingKey, setEditingKey] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isTextField, setIsTextField] = useState(false);

  const transformedData = data.flatMap((university, uniIndex) =>
    university.courses.map((course) => ({
      id: course.id,
      uniName: university.uniName,
      type: course.type,
      base: course.base,
      fixed: course.fixed,
      percentage: course.percentage,
    }))
  );

  const handleEdit = (record) => {
    setEditingKey(record.id);
    setEditedData(record);
  };

  const handleSave = () => {
    setData((prevData) =>
      prevData.map((item) => {
        const courseFind = item.courses.find(
          (course) => course.id === editingKey
        );
        if (courseFind) {
          item.uniName = editedData.uniName;
          courseFind.type = editedData.type;
          courseFind.base = editedData.base;
          courseFind.fixed = editedData.fixed;
          courseFind.percentage = editedData.percentage;
        }
        return item;
      })
    );
    setEditingKey(null);
    setEditedData({});
  };

  const handleCancel = () => {
    setEditingKey(null);
    setEditedData({});
  };

  const handleChange = (e, field) => {
    setEditedData({ ...editedData, [field]: e.target.value });
  };

  const addNewRow = () => {
    const id = uuidv4();
    const newRow = {
      id: id,
      uniName: "",
      type: "",
      base: "",
      fixed: "",
      percentage: "",
    };
    setData((prevData) => [
      ...prevData,
      { key: uuidv4(), uniName: "", courses: [newRow] },
    ]);
    setEditingKey(id);
    setEditedData(newRow);
  };

  const handleDelete = (record) => {
    setData((prevData) =>
      prevData.map((item) => {
        const courseFind = item.courses.find(
          (course) => course.id === record.id
        );
        if (courseFind) {
          item.courses = item.courses.filter(
            (course) => course.id !== record.id
          );
        }
        return item;
      })
    );
  };

  const columns = [
    {
      title: "University Name",
      dataIndex: "uniName",
      render: (text, record, index) => {
        const courseCount = transformedData.filter(
          (item) => item.uniName === record.uniName
        ).length;
        const isFirstOccurrence =
          transformedData.findIndex(
            (item) => item.uniName === record.uniName
          ) === index;

        const toggleInputType = () => {
          setIsTextField((prev) => !prev);
        };

        return {
          children:
            editingKey === record.id ? (
              <div className="flex">
                {isTextField ? (
                  <Input
                    onChange={(e) => handleChange(e, "uniName")}
                    placeholder="Enter University"
                    size="large"
                  />
                ) : (
                  <Select
                    size="large"
                    placeholder="Select or Enter University"
                    showSearch
                    allowClear
                    mode="combobox"
                    onSearch={(value) =>
                      handleChange({ target: { value } }, "uniName")
                    }
                    onSelect={(value) =>
                      handleChange({ target: { value } }, "uniName")
                    }
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {filteredUniversities.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
                {/* Button to toggle between Select and Input */}
                <Button
                  icon={<SwapOutlined />}
                  onClick={toggleInputType}
                  title="Toggle input type"
                  size="large"
                />
              </div>
            ) : (
              <p>{text}</p>
            ),
          props: { rowSpan: isFirstOccurrence ? courseCount : 0 },
        };
      },
    },
    {
      title: "Type of Course",
      dataIndex: "type",
      render: (text, record) =>
        editingKey === record.id ? (
          <Input
            value={editedData.type}
            onChange={(e) => handleChange(e, "type")}
          />
        ) : (
          text
        ),
    },
    {
      title: "Base/Terms",
      dataIndex: "base",
      render: (text, record) =>
        editingKey === record.id ? (
          <Input
            value={editedData.base}
            onChange={(e) => handleChange(e, "base")}
          />
        ) : (
          text
        ),
    },
    {
      title: `Fixed Commission - (${currency})`,
      dataIndex: "fixed",
      render: (text, record) =>
        editingKey === record.id ? (
          <Input
            value={editedData.fixed}
            onChange={(e) => handleChange(e, "fixed")}
          />
        ) : (
          `${text || ""}`
        ),
    },
    {
      title: "Percentage Commission",
      dataIndex: "percentage",
      render: (text, record) =>
        editingKey === record.id ? (
          <Input
            value={editedData.percentage}
            onChange={(e) => handleChange(e, "percentage")}
          />
        ) : text ? (
          `${text}%`
        ) : (
          ""
        ),
    },
    {
      title: "Action",
      render: (_, record) => {
        return editingKey === record.id ? (
          <>
            <Button
              className="view_button"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={handleSave}
            />
            &nbsp;&nbsp;
            <Button
              className="delete_button"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={handleCancel}
            />
          </>
        ) : (
          <>
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            />
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure to delete this row?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </>
        );
      },
      width: 100,
      align: "right",
    },
  ];

  return (
    <>
      <Button
        onClick={addNewRow}
        className="default__btn"
        style={{ marginBottom: 16 }}
      >
        Add New Row
      </Button>
      <Table
        columns={columns}
        dataSource={transformedData}
        bordered
        rowKey="key"
        pagination={false}
      />
    </>
  );
};

export default PackageDataTable;
