import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import { PostDataAuth } from "../../apiService/PostData";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import { apis, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";

const { Option } = Select;
const { Panel } = Collapse;

export const CourseFilterRow = ({
  countries,
  intakeYears,
  intakeMonths,
  courseLevels,
}) => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);

  const [postData, setPostData] = useState({
    country_id: "all",
  });

  const filteredUniversities = useUniversityByCountry(postData.country_id);

  const onFinish = () => {
    const data = {
      ...form.getFieldsValue(),
      reportFrom: "COURSE-REPORTS",
    };
    setBtnLoading(true);
    Progress.show();
    PostDataAuth(apis.USER_REPORTS, data).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        message.success("Report Generated successfully");
        window.open(`${IMAGE_PATH}/${responseJson.data}`);
        Progress.hide();
        setBtnLoading(false);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
        setBtnLoading(false);
      }
    });
  };

  const clearData = () => {
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Reports from Courses">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="name" noStyle>
              <Input
                placeholder="Course Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="country_id" noStyle>
              <Select
                allowClear
                placeholder="Select Country"
                style={{ width: "100%" }}
                onChange={(value) =>
                  setPostData({ ...postData, country_id: value })
                }
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="uni_id" noStyle>
              <Select
                placeholder="Select University"
                style={{ width: "100%" }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {filteredUniversities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item name="course_level_id" noStyle>
              <Select
                allowClear
                placeholder="Select Course Level"
                style={{ width: "100%" }}
              >
                {courseLevels.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_month_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Month"
                style={{ width: "100%" }}
              >
                {intakeMonths.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.months}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_year_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Year"
                style={{ width: "100%" }}
              >
                {intakeYears.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit" loading={btnLoading}>
              Generate Report
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
