import { Button } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import PackageListTable from "./PackageListTable";

const AllPackages = () => {
  const location = useLocation();
  useBreadCrumb("Agent Packages", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Link to="/settings/package-management/add">
            <Button className="primary__btn">Add New Package</Button>
          </Link>
        </div>
        <PackageListTable />
      </div>
    </div>
  );
};

export default AllPackages;
