import {
  AlertOutlined,
  ContainerOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useNavigate } from "react-router-dom";
import { GetDataAuth } from "../../apiService/GetData";
import useApplicationList from "../../hooks/useApplicationList";
import { apis } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { FilterRow } from "./FilterRow";

const { confirm } = Modal;

function ApplicationTable() {
  const navigate = useNavigate();
  const { actionPrivileges, currentPage, setCurrentPage } = useStore();

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line
  }, []);

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: currentPage,
    pageSize: 10,
    status: "all",
    dataTable: true,
    start_date: null,
    end_date: null,
  });

  const coursesList = useApplicationList(filterData);

  const openSFStatusModel = (record) => {
    if (!record.sf_status_name) {
      message.error("SF Status is not available for this application");
      return;
    }
    navigate(
      "/applications-management/salesforce/" + record.salesforce_application_id
    );
  };

  const columns = [
    {
      title: "Application ID",
      sorter: (a, b) => a.app_unique_id.length - b.app_unique_id.length,
      render: (record) => <p>{record.app_unique_id}</p>,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: "Student Name",
      sorter: (a, b) => a.student_fname.length - b.student_fname.length,
      render: (record) => <p>{record.full_name}</p>,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: "Course Name",
      sorter: (a, b) => a.course_name.length - b.course_name.length,
      render: (record) => <p>{record.course_name}</p>,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: "Country",
      sorter: (a, b) => a.country_name.length - b.country_name.length,
      render: (record) => <p>{record.country_name}</p>,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: "Select University",
      sorter: (a, b) => a.uni_name.length - b.uni_name.length,
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.uni_name}</p>,
      width: 200,
    },
    {
      title: "Course Level",
      sorter: (a, b) => a.course_level_name.length - b.course_level_name.length,
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.course_level_name}</p>,
      width: 150,
    },
    {
      title: "Intake",
      sorter: (a, b) => a.intake_month_name.length - b.intake_month_name.length,
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p>{record.intake_month_name + "-" + record.intake_year_name}</p>
      ),
      width: 200,
    },

    {
      title: "Created By",
      sorter: (a, b) => a.added_fname.length - b.added_fname.length,
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p>
          {record.added_fname + "-" + record.added_lname}
          <b>({record.user_type_name})</b>
        </p>
      ),
      width: 200,
    },

    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },

    {
      title: "Update Date",
      render: (record) =>
        record.updated_at
          ? moment(record.updated_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },

    {
      title: "Delete Date",
      render: (record) =>
        record.deleted_at
          ? moment(record.deleted_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag className="tags" color={record.status_color}>
          {record.status}
        </Tag>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
      width: 150,
      fixed: "right",
    },
    ...(actionPrivileges.includes("SF_PAGE_N_DATA")
      ? [
          {
            title: "SF Status",
            render: (record) => (
              <Tag className="tags" color={record.sf_status_color ?? "blue"}>
                {record.sf_status_name ?? "Not Available"}
              </Tag>
            ),
            sorter: (a, b) => a.status.length - b.status.length,
            sortDirections: ["descend", "ascend"],
            width: 150,
            fixed: "right",
          },
        ]
      : []),
    {
      title: "Action",
      render: (text, record) => (
        <>
          {actionPrivileges.includes("APPLICATION_DATA_VIEW") && (
            <Tooltip placement="bottom" title="view">
              <Button
                className="edit_button"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() =>
                  navigate("/applications-management/" + record.id)
                }
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("APPLICATION_DATA_DELETE") && (
            <Tooltip placement="bottom" title="Delete">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteApplication(record.id)}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("APPLICATION_DATA_HISTORY") && (
            <Tooltip placement="bottom" title="History">
              <Button
                className="view_button"
                shape="circle"
                icon={<ContainerOutlined />}
                onClick={() =>
                  navigate("/applications-management/history/" + record.id)
                }
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("SF_PAGE_N_DATA") && (
            <Tooltip placement="bottom" title="SF Page">
              <Button
                className="view_button"
                shape="circle"
                icon={<AlertOutlined />}
                onClick={() => openSFStatusModel(record)}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 170,
    },
  ];

  const deleteApplication = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this application? You can't undo this action`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.DELETE_APPLICATION}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <FilterRow filterData={filterData} setFilterData={setFilterData} />
      <Table
        scroll={{ x: 1700 }}
        columns={columns}
        dataSource={coursesList.data}
        bordered
        pagination={{
          current: filterData.page,
          pageSize: filterData.per_page,
          total: coursesList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
            setCurrentPage(page);
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default ApplicationTable;
