import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Select,
} from "antd";
import useAllSFStatus from "../../hooks/useAllSFStatus";
import useSFDataById from "../../hooks/useSFDataById";
import moment from "moment";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";

const { Option } = Select;
const { TextArea } = Input;

const SalesforcePage = () => {
  const location = useLocation();
  const params = useParams();

  useBreadCrumb("Salesforce", location.pathname, "", "add");
  const [form] = Form.useForm();
  const [programStartDate, setProgramStartDate] = useState();
  const [programEndDate, setProgramEndDate] = useState();
  const [acceptanceDate, setAcceptanceDate] = useState();
  const [orientationDate, setOrientationDate] = useState();
  const [documentSubmittedDate, setDocumentSubmittedDate] = useState();
  const [visaAppliedDate, setVisaAppliedDate] = useState();
  const [applicationSendDate, setApplicationSendDate] = useState();

  const allSFStatus = useAllSFStatus();
  const { salesforceData } = useSFDataById(params.id);

  useEffect(() => {
    if (salesforceData) {
      form.setFieldsValue({
        Name: salesforceData?.Name,
        Status__c: salesforceData?.Status__c,
        University_in_Country__c: salesforceData?.University_in_Country__c,
        Student_Email_Address__c: salesforceData?.Student_Email_Address__c,
        Student_ID__c: salesforceData?.Student_ID__c,
        Course_Name__c: salesforceData?.Course_Name__c,
        Program_Start_date__c: salesforceData?.Program_Start_date__c
          ? moment(salesforceData?.Program_Start_date__c)
          : undefined,
        Program_end_date__c: salesforceData?.Program_end_date__c
          ? moment(salesforceData?.Program_end_date__c)
          : undefined,
        Package_Offer__c: salesforceData?.Package_Offer__c,
        Next_offer__c: salesforceData?.Next_offer__c,
        Offer_Conditions__c: salesforceData?.Offer_Conditions__c,
        Offer_Type__c: salesforceData?.Offer_Type__c,
        Scholarship__c: salesforceData?.Scholarship__c,
        Full_Course_fees_Annual__c: salesforceData?.Full_Course_fees_Annual__c,
        Minimum_amount_to_pay__c: salesforceData?.Minimum_amount_to_pay__c,
        Submitted_by__c: salesforceData?.Submitted_by__c,
        Applied_Via__c: salesforceData?.Applied_Via__c,
        Urgent_offer__c: salesforceData?.Urgent_offer__c,
        Is_Conditions_Met__c: salesforceData?.Is_Conditions_Met__c,
        Total_Amount_paid__c: salesforceData?.Total_Amount_paid__c,
        Total_Tuition_Fee__c: salesforceData?.Total_Tuition_Fee__c,
        Accepted_date__c: salesforceData?.Accepted_date__c
          ? moment(salesforceData?.Accepted_date__c)
          : undefined,
        Orientation_date__c: salesforceData?.Orientation_date__c
          ? moment(salesforceData?.Orientation_date__c)
          : undefined,
        Visa_Application_Handled_by__c:
          salesforceData?.Visa_Application_Handled_by__c,
        Number_of_Dependants__c: salesforceData?.Number_of_Dependants__c,
        Further_Document_submitted_date__c:
          salesforceData?.Further_Document_submitted_date__c
            ? moment(salesforceData?.Further_Document_submitted_date__c)
            : undefined,
        Visa_Applied_Date__c: salesforceData?.Visa_Applied_Date__c
          ? moment(salesforceData?.Visa_Applied_Date__c)
          : undefined,
        Dependants_visa_fee_amount__c:
          salesforceData?.Dependants_visa_fee_amount__c,
        Application_Sent_Date__c: salesforceData?.Application_Sent_Date__c
          ? moment(salesforceData?.Application_Sent_Date__c)
          : undefined,
      });
    }
    setProgramStartDate(salesforceData?.Program_Start_date__c);
    setProgramEndDate(salesforceData?.Program_end_date__c);
    setAcceptanceDate(salesforceData?.Accepted_date__c);
    setOrientationDate(salesforceData?.Orientation_date__c);
    setDocumentSubmittedDate(
      salesforceData?.Further_Document_submitted_date__c
    );
    setVisaAppliedDate(salesforceData?.Visa_Applied_Date__c);
    setApplicationSendDate(salesforceData?.Application_Sent_Date__c);
    // eslint-disable-next-line
  }, [salesforceData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      let postData = {
        ...values,
        Id: params.id,
        Program_Start_date__c: programStartDate,
        Program_end_date__c: programEndDate,
        Accepted_date__c: acceptanceDate,
        Orientation_date__c: orientationDate,
        Further_Document_submitted_date__c: documentSubmittedDate,
        Visa_Applied_Date__c: visaAppliedDate,
        Application_Sent_Date__c: applicationSendDate,
      };
      if (postData.Status__c === "Documents Received") {
        if (!postData.Application_Sent_Date__c) {
          message.error("Please select Application Sent Date");
          return;
        }
        if (!postData.Number_of_Dependants__c) {
          message.error("Please select Number of Dependants");
          return;
        }
      }
      if (postData.Status__c === "Offer Received") {
        if (!postData.Full_Course_fees_Annual__c) {
          message.error("Please select Course Fees (Annual)");
          return;
        }
        if (!postData.Program_Start_date__c) {
          message.error("Please select Program Start date");
          return;
        }
        if (!postData.Program_end_date__c) {
          message.error("Please select Program End date");
          return;
        }
        if (!postData.Student_ID__c) {
          message.error("Please enter Student ID");
          return;
        }
      }
      if (postData.Status__c === "Payment Made") {
        if (!postData.Total_Amount_paid__c) {
          message.error("Please enter Course Fees Paid");
          return;
        }
        if (!postData.Total_Tuition_Fee__c) {
          message.error("Please enter Course Fees (Annual)");
          return;
        }
      }

      if (
        postData.Status__c === "COE/CAS Received" ||
        postData.Status__c === "COE/CAS Rejected"
      ) {
        if (!postData.University_in_Country__c) {
          message.error("Please enter Country");
          return;
        }
        if (!postData.Visa_Application_Handled_by__c) {
          message.error("Please enter Visa Application Handled by");
          return;
        }
      }
      if (postData.Status__c === "Visa Applied") {
        if (!postData.Offer_Conditions__c) {
          message.error("Please select Offer Conditions");
          return;
        }
        if (!postData.Offer_Type__c) {
          message.error("Please select Offer Type");
          return;
        }
      }
      if (postData.Status__c === "Visa Granted") {
        if (!postData.Offer_Conditions__c) {
          message.error("Please select Offer Conditions");
          return;
        }
        if (!postData.Offer_Type__c) {
          message.error("Please select Offer Type");
          return;
        }
        if (!postData.Next_offer__c) {
          message.error("Please select Offer Number");
          return;
        }
        if (!postData.Package_Offer__c) {
          message.error("Please select Package Offer");
          return;
        }
        if (!postData.Accepted_date__c) {
          message.error("Please select Accepted date");
          return;
        }
      }
      submitAPIForm(postData);
    });
  };

  const submitAPIForm = (postData) => {
    PostDataAuth(apis.SF_STATUS_UPDATE, postData).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        message.success("User assigned successfully");
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
      }
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Salesforce | <span>Salesforce</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item name="Name" label="Application Number">
                <Input size="large" placeholder="Application Number" />
              </Form.Item>
              <Form.Item name="University_in_Country__c" label="Country">
                <Input size="large" placeholder="Country" />
              </Form.Item>
              <Form.Item
                name="Application_Sent_Date__c"
                label="Application Sent Date"
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Application Sent Date"
                  onChange={(date, dateString) => {
                    setApplicationSendDate(dateString);
                  }}
                />
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item name="Status__c" label="Salesforce Status" required>
                <Select size="large" placeholder="Select Salesforce Status">
                  {allSFStatus.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="Student_Email_Address__c"
                label="Student Email Address"
              >
                <Input
                  size="large"
                  placeholder="Student Email Address"
                  disabled
                />
              </Form.Item>
            </div>
          </div>

          <Divider orientation="left">Offer</Divider>
          <div className="row_2">
            <div className="input_field">
              <Form.Item name="Student_ID__c" label="Student ID">
                <Input size="large" placeholder="Student ID" />
              </Form.Item>
              <Form.Item
                name="Program_Start_date__c"
                label="Program Start date"
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Program Start date"
                  onChange={(date, dateString) => {
                    setProgramStartDate(dateString);
                  }}
                />
              </Form.Item>
              <Form.Item name="Package_Offer__c" label="Package Offer">
                <Select size="large" placeholder="Package Offer">
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
              <Form.Item name="Offer_Conditions__c" label="Offer Conditions">
                <TextArea rows={4} placeholder="Offer Conditions" />
              </Form.Item>
              <Form.Item
                name="Scholarship__c"
                label="Scholarship"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Scholarship" />
              </Form.Item>
              <Form.Item name="Submitted_by__c" label="Submitted by">
                <Input size="large" placeholder="Submitted by" />
              </Form.Item>
              <Form.Item name="Submitted_by__c" label="Urgent offer">
                <Checkbox>Urgent offer</Checkbox>
              </Form.Item>
              <Form.Item name="Is_Conditions_Met__c" label="Is Conditions Met">
                <Checkbox>Is Conditions Met</Checkbox>
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item name="Course_Name__c" label="Course Name">
                <Input size="large" placeholder="Course Name" />
              </Form.Item>
              <Form.Item name="Program_end_date__c" label="Program End date">
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Program End date"
                  onChange={(date, dateString) => {
                    setProgramEndDate(dateString);
                  }}
                />
              </Form.Item>
              <Form.Item name="Next_offer__c" label="Offer Number">
                <Select size="large" placeholder="Offer Number">
                  <Option value="Offer 01">Offer 01</Option>
                  <Option value="Offer 02">Offer 02</Option>
                  <Option value="Offer 03">Offer 03</Option>
                  <Option value="Offer 04">Offer 04</Option>
                </Select>
              </Form.Item>
              <Form.Item name="Offer_Type__c" label="Offer Type">
                <Select size="large" placeholder="Offer Type">
                  <Option value="Conditional">Conditional</Option>
                  <Option value="Unconditional">Unconditional</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="Full_Course_fees_Annual__c"
                label="Course Fees (Annual)"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Course Fees (Annual)" />
              </Form.Item>
              <Form.Item
                name="Minimum_amount_to_pay__c"
                label="Minimum amount to pay"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Minimum amount to pay" />
              </Form.Item>
              <Form.Item name="Applied_Via__c" label="Applied Via">
                <Select size="large" placeholder="Applied Via">
                  <Option value="Campus Direct">Campus Direct</Option>
                  <Option value="OEC">OEC</Option>
                  <Option value="SIUK">SIUK</Option>
                  <Option value="Admissions Overseas">
                    Admissions Overseas
                  </Option>
                  <Option value="Gateway Abroad">Gateway Abroad</Option>
                  <Option value="Emergence">Emergence</Option>
                  <Option value="Education First">Education First</Option>
                  <Option value="Visa Only">Visa Only</Option>
                  <Option value="Aspirations Education">
                    Aspirations Education
                  </Option>
                  <Option value="Spectrum Overseas">Spectrum Overseas</Option>
                  <Option value="Global Reach">Global Reach</Option>
                  <Option value="KC Overseas">KC Overseas</Option>
                  <Option value="Crizac Global">Crizac Global</Option>
                  <Option value="GSP">GSP</Option>
                  <Option value="Educo Accelerate">Educo Accelerate</Option>
                  <Option value="iapply - CANAM">iapply - CANAM</Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <Divider orientation="left">Acceptance</Divider>
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                name="Total_Amount_paid__c"
                label="Course Fees Paid"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Course Fees Paid" />
              </Form.Item>
              <Form.Item name="Accepted_date__c" label="Accepted date">
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Accepted date"
                  onChange={(date, dateString) => {
                    setAcceptanceDate(dateString);
                  }}
                />
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item
                name="Total_Tuition_Fee__c"
                label="Course Fees (Annual)"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Course Fees (Annual)" />
              </Form.Item>
              <Form.Item name="Orientation_date__c" label="Orientation date">
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Orientation date"
                  onChange={(date, dateString) => {
                    setOrientationDate(dateString);
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <Divider orientation="left">Visa</Divider>
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                name="Visa_Application_Handled_by__c"
                label="Visa Application Handled by"
              >
                <Input size="large" placeholder="Visa Application Handled by" />
              </Form.Item>
              <Form.Item
                name="Further_Document_submitted_date__c"
                label="Further Document submitted date"
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Further Document submitted date"
                  onChange={(date, dateString) => {
                    setDocumentSubmittedDate(dateString);
                  }}
                />
              </Form.Item>
              <Form.Item name="Visa_Applied_Date__c" label="Visa Applied Date">
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Visa Applied Date"
                  onChange={(date, dateString) => {
                    setVisaAppliedDate(dateString);
                  }}
                />
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item
                name="Number_of_Dependants__c"
                label="Number of Dependants"
              >
                <Select size="large" placeholder="Number of Dependants">
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="Dependants_visa_fee_amount__c"
                label="Dependants visa fee amount"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Dependants visa fee amount" />
              </Form.Item>
            </div>
          </div>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SalesforcePage;
