import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useNavigate, useParams } from "react-router-dom";

const CourseListView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [uniObj, setUniObj] = useState({});
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    const getScrapData = async () => {
      Progress.show();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/get-scarp-json/single-uni/${params.id}?country=${params.pageKey}`
      );
      const data = await response.json();
      setUniObj(data?.uniObj);
      data?.uniObj?.subjects.forEach((subject) => {
        subject.courses.forEach((course) => {
          setCourseList((prev) => [...prev, course]);
        });
      });
      Progress.hide();
    };
    getScrapData();
  }, [params.id, params.pageKey]);

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          {params.pageKey} |{uniObj.name} | <span>{uniObj.location}</span>
        </p>
        <p style={{
          color: "green",
          fontSize: 16,
          textAlign: "right",
          marginBottom: 10,
        }}>Total :{courseList?.length}</p>
        {courseList.map((course) => (
          <div
            key={course.id}
            style={{
              marginBottom: 10,
            }}
          >
            <div
              className="data_row"
              style={{
                background: "#f5f5f5",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => {
                  navigate(
                    "/settings/scraping-data-sync/" +
                      params.pageKey +
                      "/" +
                      params.id +
                      "/" +
                      course.id
                  );
                }}
              >
                {course.title}
              </div>
              <div>
                {course.isChecked ? (
                  <Tag color="green">Checked</Tag>
                ) : (
                  <Tag color="gold">Need to Check</Tag>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseListView;
