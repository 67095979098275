import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../apiService/GetData";
import { apis } from "../properties";
import { usePublicStore } from "../store";

function useAllUserListAgents() {
  const [state, setState] = useState([]);
  const {agentList, setAgentList} = usePublicStore();

  useEffect(() => {
    const getData = () => {
      Progress.show();
      GetDataAuth(apis.USER_LIST_AGENT).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          setAgentList(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    agentList?.length === 0 ? getData() : setState(agentList);
    // eslint-disable-next-line
  }, []);
  return state;
}

export default useAllUserListAgents;
