import { Popover, Steps } from "antd";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useEnquiryById from "../../hooks/useEnquiryById";
import { useEnquiryDataStore } from "../../store";
import { StepFive } from "./StepFive";
import { StepFour } from "./StepFour";
import { StepOne } from "./StepOne";
import { StepSix } from "./StepSix";
import { StepThree } from "./StepThree";
import { StepTwo } from "./StepTwo";

const { Step } = Steps;

export default function EditEnquiry() {
  const params = useParams();
  const location = useLocation();
  useBreadCrumb("Edit Enquiry", location.pathname, "", "add");
  const enquiryById = useEnquiryById(params.id);
  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);

  useEffect(() => {
    if (enquiryById.length !== 0) {
      setEnquiryData({
        ...enquiryData,
        applications: enquiryById.applications,
        uploadDocuments: JSON.parse(enquiryById.enquiry.upload_docs_list),
        refusedDoc: enquiryById.enquiry.refused_doc,
        personalData: enquiryById.user,
        addressData: enquiryById.user,
        educationData: {
          detailsOne: JSON.parse(enquiryById.education.details_one),
          detailsTwo: JSON.parse(enquiryById.education.details_two),
          diplomaDetails: JSON.parse(enquiryById.education.diploma_details),
          graduateDetails: JSON.parse(enquiryById.education.graduate_details),
          postGraduateDetails: JSON.parse(
            enquiryById.education.post_graduate_details
          ),
          phdDetails: JSON.parse(enquiryById.education.phd_details),
        },
        ieltsData: JSON.parse(enquiryById.enquiry.ielts_data),
      });
    }
    return function cleanup() {
      setEnquiryData({
        ...enquiryData,
        applications: [],
        uploadDocuments: [],
        refusedDoc: "",
        personalData: {},
        addressData: {},
        educationData: {
          detailsOne: {},
          detailsTwo: {},
          diplomaDetails: {},
          graduateDetails: {},
          postGraduateDetails: {},
          phdDetails: {},
        },
        ieltsData: {},
      });
    };

    // eslint-disable-next-line
  }, [enquiryById]);
 

  const [currentStep, setCurrentStep] = React.useState(0);

  const onChange = (currentStep) => {
    setCurrentStep(currentStep);
  };

  const steps = [
    {
      title: "Apply for Course",
      content: (
        <StepOne
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          typeOpz="edit"
        />
      ),
    },
    {
      title: "Upload Documents",
      content: (
        <StepTwo
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"edit"}
        />
      ),
    },
    {
      title: "Personal Information",
      content: (
        <StepThree
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"edit"}
        />
      ),
    },
    {
      title: "Address Details",
      content: (
        <StepFour
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"edit"}
        />
      ),
    },
    {
      title: "Education Summary",
      content: (
        <StepFive
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"edit"}
        />
      ),
    },
    {
      title: "IELTS / PTE Details",
      content: (
        <StepSix
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"edit"}
          finalStep={true}
          enquiryId={params.id}
        />
      ),
    },
  ];

  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
      className="step-popover"
    >
      {index + 1}
    </Popover>
  );

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Edit Enquiry |<span>edit this enquiry</span>
        </p>
        <Steps
          size="small"
          progressDot={customDot}
          current={currentStep}
          onChange={onChange}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
      </div>
    </div>
  );
}
