import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { apis } from "../properties";
import { useRefreshTable } from "../store";
import { GetDataAuth } from "../apiService/GetData";

function useAllSFStatus() {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  useEffect(() => {
    const getData = () => {
      Progress.show();
      GetDataAuth(apis.SF_STATUS).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    getData();
    // eslint-disable-next-line
  }, [refreshTable]);
  return state;
}

export default useAllSFStatus;
