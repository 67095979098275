import React, { useState } from "react";
import SubDiplomaInfo from "./SubDiplomaInfo";
import SubEduFirstInfo from "./SubEduFirstInfo";
import SubEduSecondInfo from "./SubEduSecondInfo";
import SubGraduateInfo from "./SubGraduateInfo";
import SubPhdInfo from "./SubPhdInfo";
import SubPostGraduateInfo from "./SubPostGraduateInfo";

export default function EducationInfo({ data }) {
  const [viewStep, setViewStep] = useState(1);

  const changePanel = (step) => {
    setViewStep(step);
  };

  return (
    <div>
      <p className="common_header">
        Education Details |<span>view education details</span>
      </p>
      <div className="edu_section">
        <div className="edu_nav">
          <div
            className={
              viewStep === 1 ? "edu_nav_btn edu_nav_btn_active" : "edu_nav_btn"
            }
            onClick={() => changePanel(1)}
          >
            <p>10th Details</p>
          </div>
          <div
            className={
              viewStep === 2 ? "edu_nav_btn edu_nav_btn_active" : "edu_nav_btn"
            }
            onClick={() => changePanel(2)}
          >
            <p>12th Details</p>
          </div>
          <div
            className={
              viewStep === 3 ? "edu_nav_btn edu_nav_btn_active" : "edu_nav_btn"
            }
            onClick={() => changePanel(3)}
          >
            <p>Diploma Details</p>
          </div>
          <div
            className={
              viewStep === 4 ? "edu_nav_btn edu_nav_btn_active" : "edu_nav_btn"
            }
            onClick={() => changePanel(4)}
          >
            <p>Under-Graduate Details</p>
          </div>
          <div
            className={
              viewStep === 5 ? "edu_nav_btn edu_nav_btn_active" : "edu_nav_btn"
            }
            onClick={() => changePanel(5)}
          >
            <p>Post-Graduate Details</p>
          </div>
          <div
            className={
              viewStep === 6 ? "edu_nav_btn edu_nav_btn_active" : "edu_nav_btn"
            }
            onClick={() => changePanel(6)}
          >
            <p>PHD/Doctorate Details</p>
          </div>
        </div>
        <div className="edu_body">
          {viewStep === 1 && <SubEduFirstInfo data={JSON.parse(data.details_one)} />}
          {viewStep === 2 && <SubEduSecondInfo data={JSON.parse(data.details_two)} />}
          {viewStep === 3 && <SubDiplomaInfo data={JSON.parse(data.diploma_details)} />}
          {viewStep === 4 && <SubGraduateInfo data={JSON.parse(data.graduate_details)} />}
          {viewStep === 5 && <SubPostGraduateInfo data={JSON.parse(data.post_graduate_details)} />}
          {viewStep === 6 && <SubPhdInfo data={JSON.parse(data.phd_details)} />}
        </div>
      </div>
    </div>
  );
}
