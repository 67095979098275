import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScholarshipTable from "../components/Scholarship/ScholarshipTable";
import useBreadCrumb from "../hooks/useBreadCrumb";

export default function Scholarship() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Scholarship", location.pathname, "Scholarship");
 

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/scholarship/create")}
          >
            Add Scholarship
          </Button>
        </div>
        <ScholarshipTable />
      </div>
    </div>
  );
}
