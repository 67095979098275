import React from "react";
import { IMAGE_PATH } from "../../../properties";

const DocumentInfo = ({ data , refused_doc }) => {
  return (
    <div>
      <p className="common_header">
        Enquiry Documents |<span>student documents details</span>
      </p>
      <div className="view_box_body">
        {data.map((item, index) => (
          <div className="view_row">
            <p className="type">{item.name}</p>
            {item.doc_url ? (
              <p className="data">
                <a
                  href={`${IMAGE_PATH}/${item.doc_url}`}
                  target="_blank"
                  rel="noreferrer"
                  className="view_doc_btn"
                >
                  View Document
                  <i class="bi bi-box-arrow-up-right"></i>
                </a>
              </p>
            ): (
              <p className="data">No</p>
            )}
          </div>
        ))}
        <div className="view_row">
            <p className="type">Rejected Documents</p>
            {refused_doc ? (
              <p className="data">
                <a
                  href={`${IMAGE_PATH}/${refused_doc}`}
                  target="_blank"
                  rel="noreferrer"
                  className="view_doc_btn"
                >
                  View Document
                  <i class="bi bi-box-arrow-up-right"></i>
                </a>
              </p>
            ): (
              <p className="data">No</p>
            )}
          </div>
      </div>
    </div>
  );
};

export default DocumentInfo;
