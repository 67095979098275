/* eslint-disable array-callback-return */
import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useUniversityList from "../../hooks/useUniversityList";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";

const ReorderUniversity = ({ visible, setVisible }) => {
  const {refreshTable, setRefreshTable} = useRefreshTable();

  const [uniList, setUniList] = useState([]);

  const [filterData] = useState({
    status: "all",
  });

  const allUserList = useUniversityList(filterData);

  useEffect(() => {
    if (allUserList.length > 0) {
      setUniList(allUserList);
    }
  }, [allUserList]);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 5;

  const getItemStyle = (isDragging, draggableStyle, status) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: "5px",
    background: isDragging ? "lightgreen" : "grey",
    color: status === 1 ? "#fff" : "#9c0000",
    fontWeight: "bold",
    textDecoration: status === 1 ? "none" : "line-through",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    width: "100%",
    background: "#fff",
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      uniList,
      result.source.index,
      result.destination.index
    );
    changeOrderNumber(items);
    setUniList(items);
    submitOrder(items);
  };

  const changeOrderNumber = (items) => {
    items.map((item, index) => {
      item.search_order = index + 1;
    });
  };

  const submitOrder = (items) => {
    PostDataAuth(apis.UNIVERSITY_ORDER_UPDATE, items).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        message.success(responseJson.message);
        setRefreshTable(!refreshTable);
      }
    });
  };

  return (
    <Modal
      title="Reorder University List"
      visible={visible}
      style={{ borderRadius: "10px" }}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {uniList.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        item.status
                      )}
                    >
                      {item.search_order} - {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};

export default ReorderUniversity;
