import { Button, message, Modal, Spin } from "antd";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GetData } from "../apiService/GetData";
import { apis } from "../properties";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PostData } from "../apiService/PostData";
import Swal from "sweetalert2";

const { confirm } = Modal;

const AgentAgreementVerify = () => {
  const params = useParams();
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [loading, setLoading] = useState(false);

  // Start drawing
  const startDrawing = (e) => {
    setIsDrawing(true);
    draw(e); // Start the drawing immediately
  };

  // End drawing
  const endDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    canvas.getContext("2d").beginPath(); // Stop drawing path
  };

  // Draw on canvas
  const draw = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.strokeStyle = "black";

    const rect = canvas.getBoundingClientRect();
    ctx.lineTo(e.clientX - rect.left, e.clientY - rect.top);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(e.clientX - rect.left, e.clientY - rect.top);
  };

  // Handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedSignature(reader.result); // Set uploaded signature image
      };
      reader.readAsDataURL(file);
    }
  };

  // Clear the canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const handleSubmit = () => {
    setLoading(true);
    const canvas = canvasRef.current;
    const blankCanvas = document.createElement("canvas");
    blankCanvas.width = canvas.width;
    blankCanvas.height = canvas.height;

    const isCanvasEmpty = canvas.toDataURL() === blankCanvas.toDataURL();

    // If neither the canvas nor the uploaded file contains a signature, show an error and prevent submission
    if (isCanvasEmpty && !uploadedSignature) {
      message.error(
        "Please provide a signature by drawing or uploading an image."
      );
      setLoading(false);
      return;
    }

    const signature = uploadedSignature
      ? uploadedSignature
      : canvas.toDataURL("image/png");
    const formData = new FormData();
    formData.append("signature", signature);

    PostData(
      `${apis.AGENT_AGREEMENT}/${params.verify_code}/accept`,
      formData
    ).then((result) => {
      if (result.status === "success") {
        Swal.fire({
          title: "Success",
          text: "You have successfully accepted the agreement, You will be get a signed copy of the agreement in your email.",
          icon: "success",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          window.location.href = "https://www.campusdirect.io/";
        });
      } else {
        message.error(result.message);
      }
      setLoading(false);
    });
  };

  const handleReject = () => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to Reject this?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        setLoading(true);
        GetData(`${apis.AGENT_AGREEMENT}/${params.verify_code}/reject`).then(
          (result) => {
            if (result.status === "success") {
              window.open("https://www.campusdirect.io/");
            } else {
              message.error(result.message);
            }
            setLoading(false);
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="ag_center">
      <div className="ag_center_box">
        <Spin spinning={loading}>
          <h2>Agent Agreement</h2>
          <p>
            Do you read the agreement? If you agree, insert your signature and
            click the "Accept" button. If you do not agree, click the "Reject"
            button. Add your signature by drawing or uploading an image. The
            image should be in PNG or JPEG format.
          </p>

          <div className="signature-box">
            <h3>Draw Your Signature:</h3>
            <canvas
              ref={canvasRef}
              width={400}
              height={150}
              style={{ border: "1px solid black", cursor: "crosshair" }}
              onMouseDown={startDrawing}
              onMouseUp={endDrawing}
              onMouseOut={endDrawing}
              onMouseMove={draw}
            />
            <Button className="default__btn" onClick={clearCanvas}>
              Clear Signature
            </Button>
          </div>

          <div className="signature-box">
            <h3>Or Upload Your Signature:</h3>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              className="file-upload-input"
            />
            {uploadedSignature && (
              <img
                className="uploaded-signature"
                src={uploadedSignature}
                alt="Uploaded Signature"
                style={{ marginTop: "10px", width: "100px" }}
              />
            )}
          </div>

          <div className="btn_row">
            <Button
              onClick={() => {
                clearCanvas();
                setUploadedSignature(null);
              }}
            >
              Clear All
            </Button>
            <Button className="danger__btn" onClick={handleReject}>
              Reject
            </Button>

            <Button className="primary__btn" onClick={handleSubmit}>
              Accept
            </Button>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default AgentAgreementVerify;
