import { Button, Form, Input, message, Modal } from "antd";
import React from "react";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { useRefreshTable } from "../../store";

export default function AddUserType({ visible, setVisible }) {
  const [form] = Form.useForm();

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      PostDataAuth(apis.ADD_USER_TYPE, form.getFieldsValue()).then(
        (result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            form.resetFields();
            setRefreshTable(!refreshTable);
            setVisible(false);
            message.success(responseJson.message);
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        }
      );
    });
  };

  return (
    <Modal
      title="Add new user type"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Role name cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Name" />
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
