import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useEnquiryById from "../../hooks/useEnquiryById";
import { apis } from "../../properties";
import AddressInfo from "./ViewPanel/AddressInfo";
import ApplicationsInfo from "./ViewPanel/ApplicationsInfo";
import DocumentInfo from "./ViewPanel/DocumentInfo";
import EducationInfo from "./ViewPanel/EducationInfo";
import IeltsInfo from "./ViewPanel/IeltsInfo";
import PersonalInfo from "./ViewPanel/PersonalInfo";
import Progress from "react-progress-2";
import { useStore } from "../../store";

const { confirm } = Modal;

export default function ViewEnquiry() {
  const navigate = useNavigate();
  const actionPrivileges = useStore((state) => state.actionPrivileges);
  const params = useParams();
  const location = useLocation();
  useBreadCrumb("View Enquiry", location.pathname, "", "add");
  const enquiryById = useEnquiryById(params.id);
  const [viewStep, setViewStep] = useState(1);

  const changePanel = (step) => {
    setViewStep(step);
  };

  // const exportFile = () => {
  //   confirm({
  //     title: `Are you sure?`,
  //     icon: <ExclamationCircleOutlined />,
  //     content: `You are about to export all the application to a CSV file.`,
  //     okText: "Yes",
  //     okType: "primary",
  //     cancelText: "No",
  //     onOk() {
  //       Progress.show();
  //       PostDataAuth(apis.FILE_EXPORT, { type: "application-all" }).then(
  //         (result) => {
  //           let responseJson = result;
  //           if (responseJson.status === "success") {
  //             Progress.hide();
  //             message.success("File export successfully");
  //             window.open(`${IMAGE_PATH}/${responseJson.data}`);
  //           } else if (responseJson.status === "error") {
  //             message.error(responseJson.message);
  //             Progress.hide();
  //           }
  //         }
  //       );
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const downloadFile = () => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `You are about to download all applications. This will take a while. Do you want to continue?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(apis.FILE_DOWNLOAD, {
          type: "download-applications",
          enq_id: enquiryById.enquiry.id,
          enq_name: enquiryById.user.first_name,
        }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            message.success("File export successfully");
            window.open(`https://api.campusdirect.io/${responseJson.data}`);
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          {actionPrivileges.includes("ENQUIRY_ADD") && (
            <Button
              className="primary__btn"
              onClick={() => navigate("/enquiries/create")}
            >
              Add Enquiry
            </Button>
          )}
          &nbsp;
          {actionPrivileges.includes("ENQUIRY_DOWNLOAD_ALL") && (
            <Button className="default__btn" onClick={() => downloadFile()}>
              Download All Documents
            </Button>
          )}
          &nbsp;
          {/* {actionPrivileges.includes("ENQUIRY_EXPORT_BTN") && (
          <Button className="default__btn" onClick={() => exportFile()}>
            Export
          </Button>
          )} */}
        </div>
        <div className="edu_section">
          <div className="edu_nav">
            <div
              className={
                viewStep === 1
                  ? "edu_nav_btn edu_nav_btn_active"
                  : "edu_nav_btn"
              }
              onClick={() => changePanel(1)}
            >
              <p>Personal Information</p>
            </div>
            <div
              className={
                viewStep === 2
                  ? "edu_nav_btn edu_nav_btn_active"
                  : "edu_nav_btn"
              }
              onClick={() => changePanel(2)}
            >
              <p>Education Details</p>
            </div>
            <div
              className={
                viewStep === 3
                  ? "edu_nav_btn edu_nav_btn_active"
                  : "edu_nav_btn"
              }
              onClick={() => changePanel(3)}
            >
              <p>Address Detail</p>
            </div>
            <div
              className={
                viewStep === 4
                  ? "edu_nav_btn edu_nav_btn_active"
                  : "edu_nav_btn"
              }
              onClick={() => changePanel(4)}
            >
              <p>Application</p>
            </div>
            <div
              className={
                viewStep === 5
                  ? "edu_nav_btn edu_nav_btn_active"
                  : "edu_nav_btn"
              }
              onClick={() => changePanel(5)}
            >
              <p>IELTS / PTE Details</p>
            </div>
            <div
              className={
                viewStep === 6
                  ? "edu_nav_btn edu_nav_btn_active"
                  : "edu_nav_btn"
              }
              onClick={() => changePanel(6)}
            >
              <p>Enquiry Documents</p>
            </div>
          </div>
          <div className="edu_body">
            {viewStep === 1 && (
              <PersonalInfo
                data={enquiryById.length !== 0 ? enquiryById.user : ""}
              />
            )}
            {viewStep === 2 && (
              <EducationInfo
                data={enquiryById.length !== 0 ? enquiryById.education : ""}
              />
            )}
            {viewStep === 3 && (
              <AddressInfo
                data={enquiryById.length !== 0 ? enquiryById.user : ""}
              />
            )}
            {viewStep === 4 && (
              <ApplicationsInfo
                data={enquiryById.length !== 0 ? enquiryById.applications : ""}
              />
            )}
            {viewStep === 5 && (
              <IeltsInfo
                data={
                  enquiryById.length !== 0
                    ? JSON.parse(enquiryById.enquiry.ielts_data)
                    : ""
                }
              />
            )}
            {viewStep === 6 && (
              <DocumentInfo
                data={
                  enquiryById.length !== 0
                    ? JSON.parse(enquiryById.enquiry.upload_docs_list)
                    : ""
                }
                refused_doc={
                  enquiryById.length !== 0
                    ? enquiryById.enquiry.refused_doc
                    : ""
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
