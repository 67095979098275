import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import Progress from "react-progress-2";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import usePopUpData from "../hooks/usePopUpData";

export default function PopUpManagement() {
  const popUpData = usePopUpData();

  useEffect(() => {
    if (popUpData) {
      form.setFieldsValue({
        uri: popUpData.uri,
        status: popUpData.status,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpData]);

  const [form] = Form.useForm();
  const [selectImage, setSelectImage] = useState();

  const location = useLocation();
  useBreadCrumb("PopUp Message", location.pathname, "PopUp Message");

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const body = new FormData();
      body.append("image_url", selectImage);
      body.append("uri", values.uri);
      body.append("status", values.status);

      PostDataAuth(apis.POPUP_MSG_UPDATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectImage(event.target.files[0]);
  };
  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          PopUp message will be displayed on the home page.
        </p>
        <br />
        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Redirection Link"
            name="uri"
            rules={[
              {
                required: true,
                message: "Redirection Link cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Redirection Link" />
          </Form.Item>
          <Form.Item name="image_url" label="Upload Main image">
            <Input
              type="file"
              size="large"
              placeholder="Upload image"
              onChange={(e) => uploadFileChange(e)}
              accept=".png, .jpg, .jpeg"
            />
          </Form.Item>
          <Form.Item
            name="status"
            label="Select Status"
            rules={[{ required: true, message: "Status cannot be empty!" }]}
          >
            <Select size="large" placeholder="Select Status">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
