import { Button, Form, message, Modal, Select } from "antd";
import React, { useEffect } from "react";
import Progress from "react-progress-2";
import { PostDataAuth } from "../../../apiService/PostData";
import useUserTypes from "../../../hooks/useUserTypes";
import { apis } from "../../../properties";
import { useRefreshTable } from "../../../store";
const { Option } = Select;

export default function ChangePrivilege({ visible, setVisible, selectData }) {
  const [form] = Form.useForm();

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const userTypes = useUserTypes({ status: "all" });

  useEffect(() => {
    if (userTypes.length !== 0) {
      form.setFieldsValue({
        role_ids: JSON.parse(selectData.roles),
      });
    }
    // eslint-disable-next-line
  }, [userTypes, visible]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      console.log(values);
      Progress.show();
      PostDataAuth(apis.PAGE_PRIVILEGE_UPDATE, { ...values, id: selectData.id }).then(
        (result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            form.resetFields();
            setRefreshTable(!refreshTable);
            setVisible(false);
            message.success(responseJson.message);
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        }
      );
    });
  };

  return (
    <Modal
      title="Add Country"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="role_ids"
            rules={[
              {
                required: true,
                message: "Country cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              mode="multiple"
              allowClear
              placeholder="Select Roles"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {userTypes.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
