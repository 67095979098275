import { message } from "antd";
import { useEffect, useState, useMemo } from "react";
import Progress from "react-progress-2";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";

function useCoursesList(filterData) {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  useEffect(() => {
    const getData = async () => {
      Progress.show();
      try {
        const result = await PostDataAuth(apis.COURSES_LIST, filterData);
        const responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      } catch (error) {
        message.error(error.message);
        Progress.hide();
      }
    };

    getData();
    
  }, [refreshTable, filterData]);

  const memoizedState = useMemo(() => state, [state]);

  return memoizedState;
}

export default useCoursesList;
