import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import useRequiredDocuments from "../../hooks/useRequiredDocuments";
import { useEnquiryDataStore, useUpFilesData } from "../../store";
import DynamicFileUpload from "../../util/DynamicFileUpload";
import FileUpload from "../../util/FileUpload";

export const StepTwo = ({ currentStep, setNextStep, type }) => {
  const [form] = Form.useForm();
  const [filterDataOther] = useState({
    status: 1,
  });
  const setUpFilesData = useUpFilesData((state) => state.setUpFilesData);
  const upFiles = useUpFilesData((state) => state.upFiles);
  const [upDocData, setUpDocData] = useState();
  const [isRefusedDoc, setIsRefusedDoc] = useState(false);
  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);

  const requiredDocuments = useRequiredDocuments(filterDataOther);

  const submitForm = () => {
    const applicationHasForAustralia = enquiryData.applications.some(
      (item) => item.country_id === 13
    );
    // Check if all the required documents are uploaded
    let isAllUploaded = true;
    const missedDocs = [];
    upFiles.forEach((item) => {
      if (item.prefix_doc === "student_declaration_form" && !item.doc_url) {
        missedDocs.push("Student Declaration Form");
        isAllUploaded = false;
      }
      if (item.prefix_doc === "form_of_sponsor_details_of_the_student" && !item.doc_url && applicationHasForAustralia) {
        missedDocs.push("Form of Sponsor Details of the student");
        isAllUploaded = false;
      }
      if (item.prefix_doc === "pre_application_form__australia" && !item.doc_url && applicationHasForAustralia) {
        missedDocs.push("Pre application form- Australia");
        isAllUploaded = false;
      }
    });

    if (!isAllUploaded) {
      message.error("Please upload all the required documents");
      message.info(`Missing Documents: ${missedDocs.join(", ")}`);
      return;
    }
    setEnquiryData({
      ...enquiryData,
      uploadDocuments: upFiles,
      refusedDoc: upDocData,
    });
    setNextStep(currentStep + 1);
  };

  const prev = () => {
    setNextStep(currentStep - 1);
  };

  useEffect(() => {
    if (requiredDocuments) {
      setUpFilesData(requiredDocuments);
      if (enquiryData.uploadDocuments.length > 0) {
        setUpFilesData(enquiryData.uploadDocuments);
      }
      if (enquiryData.refusedDoc) {
        setUpDocData(enquiryData.refusedDoc);
        setIsRefusedDoc(true);
      }
    }
    // eslint-disable-next-line
  }, [requiredDocuments]);

  const addNewDoc = () => {
    let newDoc = {
      name: "Other Documents" + upFiles.length,
      doc_url: null,
      prefix_doc: "other_documents",
    };
    setUpFilesData([...upFiles, newDoc]);
  };

  return (
    <div className="step_board">
      <p className="common_header">
        Upload Documents |<span>select your course details</span>
      </p>
      <Form autoComplete="off" form={form}>
        <ul className="doc_list">
          {upFiles.map((item, index) => (
            <li key={index}>
              <p>{item.name}</p>
              <DynamicFileUpload
                type="file"
                location="documents/student_doc"
                accept="application/pdf"
                index={index}
                prefix={item.prefix_doc}
              />
            </li>
          ))}
          <li className="empty_box_li" onClick={addNewDoc}>
            <div className="empty_box">Upload your other documents</div>
          </li>
        </ul>

        <div className="is_ref_doc">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={isRefusedDoc}
            onChange={() => setIsRefusedDoc(!isRefusedDoc)}
          />
          <p>Have You been Refused Visa from USA, UK, NZ or Australia?</p>
        </div>

        {isRefusedDoc && (
          <FileUpload
            type="file"
            location="documents/student_doc"
            count={1}
            imageUpload={upDocData}
            setImageUpload={setUpDocData}
            accept="application/pdf"
            prefix="refused_doc"
          />
        )}

        <div className="left_btn">
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>

          <Button type="primary" onClick={() => submitForm()}>
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};
