import { Tag } from "antd";
import React from "react";

const AgentStatusTag = ({ status }) => {
  switch (status) {
    case "PENDING":
      return (
        <Tag className="tags" color="#F29339">
          PENDING
        </Tag>
      );
    case "DELETED":
      return (
        <Tag className="tags" color="#800000">
          DELETED
        </Tag>
      );
    case "REJECTED":
      return (
        <Tag className="tags" color="#800000">
          REJECTED
        </Tag>
      );
    case "AGREEMENT SENT":
      return (
        <Tag className="tags" color="#0d8c63">
          Agreement Sent
        </Tag>
      );

    case "AGREEMENT ACCEPTED":
      return (
        <Tag className="tags" color="#077E8C">
          AGREEMENT ACCEPTED
        </Tag>
      );
    case "REJECT FROM AGENT":
      return (
        <Tag className="tags" color="#D9512C">
          REJECT FROM AGENT
        </Tag>
      );

    case "AGENT ONBOARD":
      return (
        <Tag className="tags" color="#800000">
          AGENT ONBOARD
        </Tag>
      );

    default:
      return (
        <Tag className="tags" color="#0000FF">
          Unknown
        </Tag>
      );
  }
};

export default AgentStatusTag;
