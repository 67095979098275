import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { breadcrumbStore } from "./store/breadcrumbStore";
import { dashboardStore } from "./store/dashboardStore";
import { enquiryDataStore } from "./store/enquiryDataStore";
import { publicStore } from "./store/publicStore";
import { usersStore } from "./store/usersStore";

export const useStore = create(
  devtools(
    persist(
      (set) => ({
        profileData: {},
        accessToken: {},
        refreshToken: {},
        expiresAt: {},
        authUser: false,
        activeSideMenu: false,
        activeRoute: "dashboard",
        pagePrivileges: [],
        actionPrivileges: [],
        currentPage: 1,

        setLogUser: (
          authUser,
          accessToken,
          refreshToken,
          expiresAt,
          profileData,
          pagePrivileges,
          actionPrivileges
        ) => {
          set((state) => ({
            ...state,
            authUser: authUser,
            accessToken: accessToken,
            refreshToken: refreshToken,
            expiresAt: expiresAt,
            profileData: profileData,
            pagePrivileges: pagePrivileges,
            actionPrivileges: actionPrivileges,
          }));
        },

        setProfileData: (profileData) => {
          set((state) => ({ ...state, profileData: profileData }));
        },

        setSettingData: (activeSideMenu) => {
          set((state) => ({ ...state, activeSideMenu: activeSideMenu }));
        },

        setActiveRoute: (activeRoute) => {
          set((state) => ({ ...state, activeRoute: activeRoute }));
        },

        setCurrentPage: (currentPage) => {
          set((state) => ({ ...state, currentPage: currentPage }));
        },
      }),
      { name: "campusDirect" }
    )
  )
);

let refreshTable = (set) => ({
  refreshTable: false,
  setRefreshTable: (refreshTable) => {
    set((state) => ({ ...state, refreshTable: refreshTable }));
  },
});

let upFilesData = (set) => ({
  upFiles: [],
  setUpFilesData: (upFiles) => {
    set((state) => ({ ...state, upFiles: upFiles }));
  },
});

export const useRefreshTable = create(devtools(refreshTable));
export const useUpFilesData = create(devtools(upFilesData));

export const useBreadcrumbStore = create(
  devtools(
    persist(breadcrumbStore, {
      name: "breadCrumb",
    })
  )
);

export const useEnquiryDataStore = create(
  devtools(persist(enquiryDataStore, { name: "enquiryData" }))
);

export const useDashboardStores = create(
  devtools(dashboardStore, { anonymousActionType: "app/dashboard" })
);

export const useUsersStores = create(
  devtools(usersStore, { anonymousActionType: "app/users" })
);

export const usePublicStore = create(
  devtools(publicStore, { anonymousActionType: "app/common" })
);
