import React from "react";
import useBreadCrumb from "../hooks/useBreadCrumb";

import { useLocation } from "react-router-dom";
import AgentOnboardingDataTable from "../components/AgentOnboarding/AgentOnboardingDataTable";

export default function AgentsManagement() {
  const location = useLocation();

  useBreadCrumb("Agents Management", location.pathname, "Agents Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <AgentOnboardingDataTable />
      </div>
     
    </div>
  );
}
