import { DatePicker, Form, Input } from "antd";
import moment from "moment";
import React, { useEffect } from "react";

const UnderGraduateDetails = ({ graduateDetails, setGraduateDetails }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...graduateDetails,
      attended_from: graduateDetails.attended_from
        ? moment(graduateDetails.attended_from)
        : undefined,
      attended_to: graduateDetails.attended_to
        ? moment(graduateDetails.attended_to)
        : undefined,
    });
    // eslint-disable-next-line
  }, [graduateDetails]);

  return (
    <div>
      <p className="common_header">
        Under Graduate Details |<span>fill your under graduate details</span>
      </p>
      <Form form={form} layout="vertical">
        <div className="row_2">
          <div className="input_field">
            <Form.Item name="course_name">
              <Input
                size="large"
                placeholder="Course Name"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    course_name: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="country_institution">
              <Input
                size="large"
                placeholder="Country of Institution"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    country_institution: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="primary_language">
              <Input
                size="large"
                placeholder="Primary Level of language"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    primary_language: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="attended_from">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Attended From"
                format={(value) => (value ? value.format("YYYY-MM-DD") : "")}
                onChange={(date, dateString) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    attended_from: dateString,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="subject_marks">
              <Input
                size="large"
                placeholder="Core Subject Marks"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    subject_marks: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="school_address">
              <Input
                size="large"
                placeholder="Institute Address"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    school_address: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="school_city">
              <Input
                size="large"
                placeholder="Institute City"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    school_city: e.target.value,
                  })
                }
              />
            </Form.Item>
          </div>
          <div className="input_field">
            <Form.Item name="name_institute">
              <Input
                size="large"
                placeholder="Name Of Institute"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    name_institute: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="certificate_number">
              <Input
                size="large"
                placeholder="Certificate Number"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    certificate_number: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="attended_to">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Attended To"
                format={(value) => (value ? value.format("YYYY-MM-DD") : "")}
                onChange={(date, dateString) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    attended_to: dateString,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="percentage">
              <Input
                size="large"
                placeholder="Percentage"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    percentage: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="school_state">
              <Input
                size="large"
                placeholder="Institute State"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    school_state: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="postal_code">
              <Input
                size="large"
                placeholder="Institute Postal Code"
                onChange={(e) =>
                  setGraduateDetails({
                    ...graduateDetails,
                    postal_code: e.target.value,
                  })
                }
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UnderGraduateDetails;
