import {
  DownloadOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAllAgentOnboarding from "../../hooks/useAllAgentOnboarding";
import { IMAGE_PATH } from "../../properties";
import AgentStatusTag from "../../util/AgentStatusTag";


function AgentOnboardingDataTable() {
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const allAgentOnboarding = useAllAgentOnboarding(filterData);

  const columns = [
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD hh:mm a")
          : "",
      width: 100,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      width: 100,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: 100,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      width: 100,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      width: 150,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: 100,
    },
    {
      title: "Agent Type",
      dataIndex: "agent_type",
      width: 100,
    },
    {
      title: "Address",
      dataIndex: "address",
      width: 100,
    },
    {
      title: "Status",
      render: (record) => <AgentStatusTag status={record.status} />,
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Review Request">
            <Button
              className="view_button"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => navigate(`/agents-management/review/${record.id}`)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record.agent_contract_pdf && (
            <Tooltip placement="bottom" title="Download Contract">
              <Button
                className="view_button"
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  window.open(
                    `${IMAGE_PATH}/${record.agent_contract_pdf}`,
                    "_blank"
                  );
                }}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {record.agent_commission_pdf && (
            <Tooltip placement="bottom" title="Download Commission">
              <Button
                className="view_button"
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  window.open(
                    `${IMAGE_PATH}/${record.agent_commission_pdf}`,
                    "_blank"
                  );
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: 1800 }}
        columns={columns}
        dataSource={allAgentOnboarding.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allAgentOnboarding.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default AgentOnboardingDataTable;
