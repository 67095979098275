import {
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import React from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import useUserTypes from "../../hooks/useUserTypes";

const { confirm } = Modal;

function UserTypeTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const userTypes = useUserTypes();

  const columns = [
    {
      title: "Created Date",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Type Name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record) => <p>{record.name}</p>,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Delete Access",
      render: (record) => (
        <Tag
          className="tags"
          color={record.delete_access !== 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.delete_access === 1 ? "Cannot Delete" : "Can Delete"}
        </Tag>
      ),
    },

    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Delete user type">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteUserType(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 70,
    },
  ];

  const deleteUserType = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this user type?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.DELETE_USER_TYPE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table columns={columns} dataSource={userTypes} bordered />
    </>
  );
}

export default UserTypeTable;
