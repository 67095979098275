import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../apiService/GetData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";

function useAllAgentOnboarding(filterData) {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  useEffect(() => {
    const getData = () => {
      Progress.show();
      GetDataAuth(
        `${apis.AGENT_ONBOARDING}?page=${filterData.page}&pageSize=${filterData.pageSize}`
      ).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    getData();
    // eslint-disable-next-line
  }, [filterData, refreshTable]);
  return state;
}

export default useAllAgentOnboarding;
