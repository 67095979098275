import { Collapse } from "antd";
import React from "react";
import { IMAGE_PATH } from "../../../properties";

const { Panel } = Collapse;

const ApplicationsInfo = ({ data }) => {
  return (
    <div>
      <p className="common_header">
        Applications Details |<span>student application details</span>
      </p>
      <div className="view_box_body">
        <Collapse accordion expandIconPosition="right" defaultActiveKey={['0']} >
          {data.map((item, index) => (
            <Panel header={`Application - (${item.app_unique_id})`} key={index}>
              <div className="view_row">
                <p className="type">Applying Country</p>
                <p className="data">
                  {item.country_name ? item.country_name : "-"}
                </p>
              </div>
              <div className="view_row">
                <p className="type">Applying University</p>
                <p className="data">
                  {item.university_name ? item.university_name : "-"}
                </p>
              </div>
              <div className="view_row">
                <p className="type">Applying Intake</p>
                <p className="data">
                  {item.intake_month_name ? item.intake_month_name : "-"} -{" "}
                  {item.intake_year_name ? item.intake_year_name : "-"}
                </p>
              </div>
              <div className="view_row">
                <p className="type">Applying Course Level</p>
                <p className="data">
                  {item.course_level_name ? item.course_level_name : "-"}
                </p>
              </div>
              <div className="view_row">
                <p className="type">SOP Document</p>
                {item.sop_doc && item.sop_doc !== "" && (
                <p className="data">
                  <a
                    href={`${IMAGE_PATH}/${item.sop_doc}`}
                    target="_blank"
                    rel="noreferrer"
                    className="view_doc_btn"
                  >
                    View Document
                    <i class="bi bi-box-arrow-up-right"></i>
                  </a>
                </p>
                )}
              </div>
              {item.paperbase_doc && item.paperbase_doc !== "" && (
                <div className="view_row">
                  <p className="type">PaperBase Document</p>
                  <p className="data">
                    <a
                      href={`${IMAGE_PATH}/${item.paperbase_doc}`}
                      target="_blank"
                      rel="noreferrer"
                      className="view_doc_btn"
                    >
                      View Document
                      <i class="bi bi-box-arrow-up-right"></i>
                    </a>
                  </p>
                </div>
              )}
              <div className="view_row">
                <p className="type">Assigned Employee</p>
                <p className="data">
                  {item.assigned_name ? item.assigned_name : "No Staff Assigned"}
                </p>
              </div>
              <div className="view_row">
                <p className="type">Application Status</p>
                <p className="data">
                  {item.stat_name ? item.stat_name : "-"}
                </p>
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default ApplicationsInfo;
