import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PictureOutlined,
  SwitcherOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { useNavigate } from "react-router-dom";
import { GetDataAuth } from "../../apiService/GetData";
import useCoursesList from "../../hooks/useCoursesList";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { FilterRow } from "./FilterRow";
import { IntakesModel } from "./IntakesModel";

const { confirm } = Modal;

function CoursesTable() {
  const navigate = useNavigate();
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  const [intakesVisible, setIntakesVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    country_id: "",
    dataTable: true,
    name: "",
    uni_id: "",
    course_level_id: "",
    intake_month_id: "",
    intake_year_id: "",
  });

  const coursesList = useCoursesList(filterData);

  const courseIntakeModel = (data) => {
    setIntakesVisible(true);
    setSelectedCourse(data);
  };

  console.log((1000)); // returns 1,000.00

  const columns = [
    {
      title: "",
      align: "center",
      dataIndex: "uni_logo",
      render: (uni_logo) => (
        <div className="table_av">
          {uni_logo ? (
            <Avatar src={`${IMAGE_PATH}/${uni_logo}`} />
          ) : (
            <Avatar
              style={{ color: "#234caa", backgroundColor: "#06297a30" }}
              icon={<PictureOutlined />}
            />
          )}
        </div>
      ),
      width: 50,
    },
    {
      title: "Course Name",
      sorter: (a, b) => a.course_name.length - b.course_name.length,
      render: (record) => <p>{record.course_name}</p>,
      sortDirections: ["descend", "ascend"],
      width: 250,
    },
    {
      title: "Country",
      sorter: (a, b) => a.country.length - b.country.length,
      render: (record) => <p>{record.country}</p>,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: "Select University",
      sorter: (a, b) => a.university.length - b.university.length,
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.university}</p>,
      width: 180,
    },
    {
      title: "Course Level",
      sorter: (a, b) => a.level_name.length - b.level_name.length,
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.level_name}</p>,
      width: 180,
    },
    {
      title: "Course Fee",
      render: (record) => <p>{record.course_fee}</p>,
      width: 100,
    },
    {
      title: "Currency",
      render: (record) => <p>{record.currency}</p>,
      width: 100,
    },
    {
      title: "Created Date",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          {actionPrivileges.includes("COURSE_APPLY_NOW") && (
            <Tooltip placement="bottom" title="Apply Now">
              <Button
                className="view_button"
                shape="circle"
                icon={<SwitcherOutlined />}
                onClick={() =>
                  applyNow(
                    record.uni_id,
                    record.country_id,
                    record.id,
                    record.course_level_id
                  )
                }
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("COURSE_EDIT") && (
            <Tooltip placement="bottom" title="Edit">
              <Button
                className="edit_button"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() =>
                  navigate("/courses-management/edit/" + record.id)
                }
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("COURSE_DELETE") && (
            <Tooltip placement="bottom" title="Delete">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteUni(record.id)}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 130,
    },
  ];

  const deleteUni = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this course?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.DELETE_COURSE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const applyNow = (uid, country_id, course_id, course_level_id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to apply for this course?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        courseIntakeModel({
          uid,
          country_id,
          course_id,
          course_level_id,
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <FilterRow filterData={filterData} setFilterData={setFilterData} />
      <Table
        scroll={{ x: 1200 }}
        columns={columns}
        dataSource={coursesList.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: coursesList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
      {intakesVisible && (
        <IntakesModel
          visible={intakesVisible}
          setVisible={() => {
            setIntakesVisible(!intakesVisible);
          }}
          courseData={selectedCourse}
        />
      )}
    </>
  );
}

export default CoursesTable;
