import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useLocation, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useAllUserList from "../../hooks/useAllUserList";
import useAllUserListAgents from "../../hooks/useAllUserListAgents";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useWorkflowById from "../../hooks/useWorkflowById";
import { apis } from "../../properties";

const { Option } = Select;

function EditWorkflows() {
  const [form] = Form.useForm();
  const location = useLocation();
  const params = useParams();

  useBreadCrumb("Edit Workflows Rule", location.pathname, "", "add");
  const [isActive, setIsActive] = useState(true);

  const workflowById = useWorkflowById(params.id);

  const [filterData] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    role: [2, 4],
    dataTable: false,
  });
  const allStaffList = useAllUserList(filterData);
  const allAgentList = useAllUserListAgents();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  useEffect(() => {
    if (workflowById.length !== 0) {
      setIsActive(workflowById.status === 1 ? true : false);
      form.setFieldsValue({
        ...workflowById,
      });
    }
    return () => {
      form.setFieldsValue({
        rule: "AUTO_ASSIGN_STAFF_APPLICATION",
        agent_id: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowById]);



  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
        id: params.id,
        status: isActive ? 1 : 0,
      };
      PostDataAuth(apis.UPDATE_WORKFLOW, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Edit Workflows Rule |<span>edit rule</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item name="rule">
                <Input size="large" disabled />
              </Form.Item>
              <Form.Item
                name="agent_id"
                null
                required
                rules={[
                  {
                    required: true,
                    message: "Agent Admin cannot be empty!",
                  },
                ]}
              >
                <Select size="large" placeholder="Select Agent Admin">
                  {allAgentList.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.first_name} {item.last_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="staff_id"
                required
                rules={[
                  {
                    required: true,
                    message: "Staff Member cannot be empty!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Select Staff Member"
                >
                  {allStaffList.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.first_name +
                        " " +
                        item.last_name +
                        " (" +
                        item.role +
                        ")"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div style={{ display: "flex", gap: "5px" }}>
              <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined />}
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              <p>Status</p>
            </div>

              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="input_field"></div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditWorkflows;
