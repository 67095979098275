import { message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { GetDataAuth } from "../../apiService/GetData";
import Avatar from "../../assets/images/avater.jpg";
import { apis, IMAGE_PATH } from "../../properties";
export default function ViewUserModel({ visible, setVisible, selectedUser }) {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const getUserData = () => {
      GetDataAuth(`${apis.USER_BY_ID}/${selectedUser}`).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setUserData(responseJson.data);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
        }
      });
    };
    getUserData();
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Modal
      title="User Details"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
    >
      <div className="step_model_body">
        <div className="row_2">
          <div className="cus_data">
            <div className="row">
              <p>First Name :</p>
              <span>{userData?.first_name}</span>
            </div>
            <div className="row">
              <p>Middle Name :</p>
              <span>{userData?.middle_name}</span>
            </div>
            <div className="row">
              <p>Last Name :</p>
              <span>{userData?.last_name}</span>
            </div>
            <div className="row">
              <p>User role :</p>
              <span>{userData?.role}</span>
            </div>
            <div className="row">
              <p>Email :</p>
              <span>{userData?.email}</span>
            </div>
            <div className="row">
              <p>Address :</p>
              <span>{userData?.address}</span>
            </div>
            <div className="row">
              <p>Country :</p>
              <span>{userData?.country}</span>
            </div>
            <div className="row">
              <p>State :</p>
              <span>{userData?.state}</span>
            </div>
            <div className="row">
              <p>City :</p>
              <span>{userData?.city}</span>
            </div>
            <div className="row">
              <p>Contact No :</p>
              <span>{userData?.phone}</span>
            </div>
            <div className="row">
              <p>Agent type :</p>
              <span>{userData?.agent_type}</span>
            </div>
            <div className="row">
              <p>Company Name :</p>
              <span>{userData?.company_name}</span>
            </div>
            {userData?.br_doc && (
              <div className="row">
                <p>BR documents link :</p>
                <span>
                  <a
                    href={`${IMAGE_PATH}/${JSON.parse(userData.br_doc)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </span>
              </div>
            )}
          </div>
          <div className="cus_image">
            {userData.img_url ? (
              <img src={`${IMAGE_PATH}/${userData.img_url}`} alt="" />
            ) : (
              <img src={Avatar} alt="avatar" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
