import { Button, DatePicker, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { uploadAdapter } from "../../helpers/uploadAdapter";
import useAllCountries from "../../hooks/useAllCountries";
import useCareerBySlug from "../../hooks/useCareerBySlug";
const { Option } = Select;

function EditCareer() {
  const params = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [desData, setDesData] = useState();

  useBreadCrumb("Edit career", location.pathname, "", "add");

  useBreadCrumb("Create career", location.pathname, "", "add");
  const [filterDataOther] = useState({
    status: "all",
  });
  const careerData = useCareerBySlug(params.slug);
  const countries = useAllCountries(filterDataOther);

  useEffect(() => {
    form.setFieldsValue({
      title: careerData?.title,
      slug: params.slug,
      end_date: moment(careerData?.end_date),
      description: careerData?.description,
      received_email: careerData?.received_email,
      tags: careerData?.tags,
      country_code:
        careerData?.country_code !== "all"
          ? careerData?.country_code?.toUpperCase()
          : "all",
    });
    setDesData(careerData.description);
    // eslint-disable-next-line
  }, [careerData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      console.log(values);
      Progress.show();
      const data = {
        ...values,
        description: desData,
        end_date: moment(values.end_date).format("YYYY-MM-DD"),
        country_code: values?.country_code
          ? values?.country_code.toLowerCase()
          : "all",
      };
      PostDataAuth(apis.CAREER_UPDATE, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <p className="common_header">Create new career</p>
        </div>

        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Title" />
          </Form.Item>

          <Form.Item
            label="Slug"
            name="slug"
            rules={[{ required: true, message: "Slug cannot be empty!" }]}
          >
            <Input
              type="text"
              size="large"
              placeholder="slug"
              disabled={true}
            />
          </Form.Item>

          <Form.Item name="description" label="Job Description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>

          <p className="form__description">
            If you select country, the job will be visible only for that country
          </p>
          <Form.Item name="country_code" label="Country (optional)">
            <Select
              allowClear
              size="large"
              showSearch
              placeholder="Select country"
              defaultValue={"all"}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              style={{
                width: "300px",
              }}
            >
              <Option value="all">All countries</Option>
              {countries.map((item, index) => (
                <Option key={index} value={item.sortname}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="end_date"
            label="Job End Date"
            rules={[
              { required: true, message: "Date & Time cannot be empty!" },
            ]}
          >
            <DatePicker
              size="large"
              format="YYYY-MM-DD"
              placeholder="Job End Date"
              style={{
                width: "300px",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Received Email"
            name="received_email"
            rules={[
              {
                required: true,
                message: "Email cannot be empty!",
              },
            ]}
          >
            <Input size="large" placeholder="Received Email" />
          </Form.Item>

          <p className="form__description">
            If you select tags, the job will be visible only for that tags and
            you can select multiple tags by comma separated values like
            (tag1,tag2,tag3) etc.
          </p>
          <Form.Item label="Tags (optional)" name="tags">
            <Input size="large" placeholder="Type tags separated by comma" />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditCareer;
