import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { PostDataAuth } from "../../apiService/PostData";
import StatusTag from "../../util/StatusTag";
import useAllCareer from "../../hooks/useAllCareer";

const { confirm } = Modal;

function CareerDataTable() {
  const navigate = useNavigate();
  const { refreshTable, setRefreshTable } = useRefreshTable();
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    dataTable: true,
  });

  const allCareer = useAllCareer(filterData);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Description",
      render: (record) => parse(record.description.substring(0, 120) + "..."),
      width: 200,
    },
    {
      title: "Country",
      dataIndex: "country_code",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "received_email",
      width: 150,
    },
    {
      title: "End Date",
      render: (record) =>
        record.end_date ? moment(record.end_date).format("YYYY-MM-DD") : "",
      width: 100,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 100,
    },
    {
      title: "Updated Date",
      render: (record) =>
        record.updated_at
          ? moment(record.updated_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 100,
    },
    {
      title: "Status",
      render: (record) => <StatusTag status={record.status} />,
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Career">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => navigate(`/career-management/edit/${record.slug}`)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Unpublished Career">
            <Button
              className="delete_button"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => changeStatusContent(record.slug, "UNPUBLISHED")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Published Career">
            <Button
              className="view_button"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => changeStatusContent(record.slug, "PUBLISHED")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete Career">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => changeStatusContent(record.slug, "DELETED")}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  const changeStatusContent = (slug, status) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${status} this event?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(`${apis.CAREER_STATUS_CHANGE}/${slug}`, {
          status,
        }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <>
      <Table
        scroll={{ x: 1800 }}
        columns={columns}
        dataSource={allCareer.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allCareer.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default CareerDataTable;
