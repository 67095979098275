import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { useNavigate } from "react-router-dom";
import { GetDataAuth } from "../../apiService/GetData";
import useEnquiryList from "../../hooks/useEnquiryList";
import { apis } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { AssignModel } from "./AssignModel";
import { FilterRow } from "./FilterRow";

const { confirm } = Modal;

function EnquiryTable() {
  const navigate = useNavigate();
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  const [assignVisible, setAssignVisible] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState({});
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "",
    name: "",
    date_range: "",
  });

  const enquiryList = useEnquiryList(filterData);

  const assignModel = (app_data) => {
    if (actionPrivileges.includes("ENQUIRY_ASSIGN")) {
      setAssignVisible(true);
      setSelectedApplication(app_data);
    } else {
      message.error("You don't have permission to assign");
    }
  };

  const columns = [
    {
      title: "Student Name",
      render: (record) => <p>{record.enquiry.full_name}</p>,
      width: 200,
    },
    {
      title: "Course Application",
      render: (record) => (
        <>
          {record.application.map((course) => (
            <Tag color="cyan" key={course.id}>
             <b> {course.course_name}</b> - {course.uni_name}
            </Tag>
          ))}
        </>
      ),
      sortDirections: ["descend", "ascend"],
      width: 300,
    },
    {
      title: "Assigned Employees",
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <>
          {record.application.map((course) =>
            course.status !== "14" ? (
              <div
                className={
                  course.assigned_fname ? "assign_box" : "assign_box_not"
                }
                onClick={() => assignModel(course)}
              >
                <p className="app_id">{course.app_unique_id}</p>
                <p className="app_name">
                  {course.assigned_fname
                    ? course.assigned_fname + " " + course.assigned_lname
                    : "N/A"}
                </p>
              </div>
            ) : (
              <div className="assign_box_deleted">
                <p className="app_id">{course.app_unique_id}</p>
                <p className="app_name">
                  {course.assigned_fname
                    ? course.assigned_fname + " " + course.assigned_lname
                    : "Deleted"}
                </p>
              </div>
            )
          )}
        </>
      ),
      width: 180,
    },
    {
      title: "Created By",
      render: (record) => (
        <p>
          {record.added.first_name + " " + record.added.last_name}{" "}
          <b>({record.added.user_type})</b>
        </p>
      ),
      width: 180,
    },

    {
      title: "Created Date",
      render: (record) =>
        record.enquiry
          ? moment(record.enquiry.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Deleted By",
      render: (record) => (
        <p>
          {record?.enquiry?.deleted_by_first_name
            ? record?.enquiry?.deleted_by_first_name +
              " " +
              record?.enquiry?.deleted_by_last_name
            : "NA"}
        </p>
      ),
      width: 180,
    },

    {
      title: "Deleted Date",
      render: (record) =>
        record.enquiry.deleted_at
          ? moment(record.enquiry.deleted_at).format("YYYY-MM-DD h:mm:ss a")
          : "NA",
      width: 180,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={
            record.enquiry.status === "DELETED" ||
            record.enquiry.status === "REJECTED"
              ? "#c73b27"
              : "#0d8c63"
          }
        >
          {record.enquiry.status}
        </Tag>
      ),
      fixed: "right",
      width: 100,
    },

    {
      title: "Action",
      render: (text, record) => (
        <>
          {actionPrivileges.includes("ENQUIRY_EXPORT_DETAILS") && (
            <Tooltip placement="bottom" title="User Details">
              <Button
                className="view_button"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => navigate("/enquiries/" + record.enquiry.id)}
              />
            </Tooltip>
          )}
          &nbsp;
          {actionPrivileges.includes("ENQUIRY_EDIT") &&
            record.enquiry.status !== "DELETED" &&
            record.enquiry.status !== "REJECTED" && (
              <Tooltip placement="bottom" title="Edit">
                <Button
                  className="edit_button"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() =>
                    navigate("/enquiries/edit/" + record.enquiry.id)
                  }
                />
              </Tooltip>
            )}
          &nbsp;
          {actionPrivileges.includes("ENQUIRY_DELETE") && (
            <Tooltip placement="bottom" title="Delete">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteUni(record.enquiry.id)}
              />
            </Tooltip>
          )}
          &nbsp;
          {actionPrivileges.includes("ENQUIRY_REJECT") && (
            <Tooltip placement="bottom" title="Reject">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => rejectEnq(record.enquiry.id)}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 170,
    },
  ];

  const deleteUni = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this enquiry? You can't undo this action.`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.DELETE_ENQUIRY}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const rejectEnq = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to reject this enquiry? You can't undo this action.`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.REJECT_ENQUIRY}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <FilterRow filterData={filterData} setFilterData={setFilterData} />
      <Table
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={enquiryList.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: enquiryList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
      {assignVisible && (
        <AssignModel
          visible={assignVisible}
          setVisible={() => {
            setAssignVisible(!assignVisible);
          }}
          application={selectedApplication}
        />
      )}
    </>
  );
}

export default EnquiryTable;
