import { Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useApplicationHistoryList from "../../hooks/useApplicationHistoryList";
import useBreadCrumb from "../../hooks/useBreadCrumb";

function ApplicationHistoryTable() {
  const params = useParams();
  const location = useLocation();

  useBreadCrumb("Status History", location.pathname, "", "add");

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    dataTable: true,
    id: params.id,
  });
  const coursesList = useApplicationHistoryList(filterData);

  const columns = [
    {
      title: "Created Date",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
    },
    {
      title: "Created By",
      sorter: (a, b) => a.added_fname.length - b.added_fname.length,
      render: (record) => (
        <p>
          {record.added_fname +
            " " +
            (record.added_mname ? record.added_mname : "") +
            " " +
            (record.added_lname ? record.added_lname : "")}
        </p>
      ),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Role",
      sorter: (a, b) => a.user_type_name.length - b.user_type_name.length,
      render: (record) => (
        <p>
          {record.user_type_name}
        </p>
      ),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      sorter: (a, b) => a.happen.length - b.happen.length,
      render: (record) => <p>{record.happen}</p>,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      render: (record) => (
        <Tag className="tags" color={record.status_color}>
          {record.status}
        </Tag>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
      fixed: "right",
    },
  ];

  return (
    <div className="dashboard">
      <div className="section_row">
        <Table
          columns={columns}
          dataSource={coursesList.data}
          bordered
          pagination={{
            current: filterData.current_page,
            pageSize: filterData.per_page,
            total: coursesList.total,
            onChange: (page, pageSize) => {
              setFilterData({
                ...filterData,
                page: page,
                pageSize: pageSize,
              });
            },
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </div>
    </div>
  );
}

export default ApplicationHistoryTable;
