import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { PostData } from "../apiService/PostData";
import { apis } from "../properties";
import { usePublicStore, useRefreshTable } from "../store";

function useIntakeYears(filterData) {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const { intakeYears, setIntakeYears } = usePublicStore();

  useEffect(() => {
    const getData = () => {
      Progress.show();
      PostData(apis.INTAKE_YEARS, filterData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          filterData?.isStore &&  setIntakeYears(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    if (!filterData?.isStore) {
      getData();
    } else {
      intakeYears?.length === 0 ? getData() : setState(intakeYears);
    }
    
    // eslint-disable-next-line
  }, [filterData, refreshTable]);
  return state;
}

export default useIntakeYears;
