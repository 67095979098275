import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import useEventBySlug from "../../hooks/useEventBySlug";
import { uploadAdapter } from "../../helpers/uploadAdapter";
import useAllCountries from "../../hooks/useAllCountries";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FileUpload from "../../util/FileUpload";
const { Option } = Select;
const { TextArea } = Input;

function EditEvent() {
  const params = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [desData, setDesData] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [coverImage, setCoverImage] = useState();

  useBreadCrumb("Edit event", location.pathname, "", "add");
  const eventData = useEventBySlug(params.slug);

  useBreadCrumb("Create event", location.pathname, "", "add");
  const [filterDataOther] = useState({
    status: "all",
  });
  const countries = useAllCountries(filterDataOther);

  useEffect(() => {
    const eventData2 = JSON.parse(eventData?.dates_n_locations || "[]");
    const datesLocationsArr = eventData2.map((item) => {
      return {
        location_name: item.location_name,
        start_date: moment(item.start_date),
        end_date: moment(item.end_date),
      };
    });
    form.setFieldsValue({
      title: eventData.title,
      slug: params.slug,
      description: eventData.description,
      application_url: eventData?.application_url,
      meta_title: eventData?.meta_title,
      meta_description: eventData?.meta_description,
      meta_keywords: eventData?.meta_keywords,
      country_code: eventData?.country_code?.toUpperCase(),
      map_locations: JSON.parse(eventData?.map_locations || "[]"),
      tabs_list: JSON.parse(eventData?.tabs_list || "[]"),
      dates_n_locations: datesLocationsArr,
    });
    setBannerImage(eventData?.banner_url);
    setCoverImage(eventData?.cover_url);
    setDesData(eventData?.description);
    // eslint-disable-next-line
  }, [eventData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    if (bannerImage === "" || coverImage === "") {
      message.error("Please upload banner image");
      return false;
    }

    form.validateFields().then((values) => {
      console.log(values);
      Progress.show();
      values.dates_n_locations = values.dates_n_locations.map((item) => {
        return {
          location_name: item.location_name,
          start_date: moment(item.start_date).format("YYYY-MM-DD HH:mm:ss"),
          end_date: moment(item.end_date).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      const data = {
        ...values,
        description: desData,
        country_code: values.country_code.toLowerCase(),
        banner_url: bannerImage,
        cover_url: coverImage,
      };

      PostDataAuth(apis.EVENT_UPDATE, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <p className="common_header">Edit event</p>
        </div>

        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Event Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Event title" />
          </Form.Item>

          <Form.Item
            label="Event Slug"
            name="slug"
            rules={[{ required: true, message: "Slug cannot be empty!" }]}
          >
            <Input
              type="text"
              size="large"
              placeholder="slug"
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            name="country_code"
            label="Country"
            rules={[
              {
                required: true,
                message: "Country cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Select country"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {countries.map((item, index) => (
                <Option key={index} value={item.sortname}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="description" label="Event Description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Content description",
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Application URL"
            name="application_url"
            rules={[
              {
                type: "url",
                message: "Please enter a valid URL",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Application URL" />
          </Form.Item>

          <br />
          <Divider orientation="left" orientationMargin="0">
            Event Dates & Locations
          </Divider>
          <Form.List name="dates_n_locations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "location_name"]}
                      style={{ width: "50%" }}
                      rules={[
                        { required: true, message: "Missing Location Name" },
                      ]}
                    >
                      <Input size="large" placeholder="Location Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "start_date"]}
                      style={{ width: "20%" }}
                      rules={[
                        { required: true, message: "Missing Start Date" },
                      ]}
                    >
                      <DatePicker
                        showTime
                        size="large"
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Event Start Date"
                        className="w-100"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "end_date"]}
                      style={{ width: "20%" }}
                      rules={[{ required: true, message: "Missing End Date" }]}
                    >
                      <DatePicker
                        showTime
                        size="large"
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Event End Date"
                        className="w-100"
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ marginTop: "10px", fontSize: "18px" }}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ width: "50%" }}
                  >
                    Add Date & Location
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <br />
          <Divider orientation="left" orientationMargin="0">
            Upload Media
          </Divider>
          <div className="row">
            <Form.Item
              name="banner_image_url"
              label="Banner Image (Image Size: 1920x400px)"
              required
            >
              <FileUpload
                type="image"
                location="event"
                count={1}
                imageUpload={bannerImage}
                setImageUpload={setBannerImage}
                accept=".png, .jpg, .jpeg"
                prefix="event_banner"
              />
            </Form.Item>
            <Form.Item
              name="banner_image_url"
              label="Tile Image (Image Size: 300x300px)"
              required
            >
              <FileUpload
                type="image"
                location="event"
                count={1}
                imageUpload={coverImage}
                setImageUpload={setCoverImage}
                accept=".png, .jpg, .jpeg"
                prefix="event_tile"
              />
            </Form.Item>
          </div>

          <br />
          <Divider orientation="left" orientationMargin="0">
            Map Setup
          </Divider>
          <Form.List name="map_locations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "location_name"]}
                      style={{ width: "50%" }}
                      rules={[
                        { required: true, message: "Missing Location Name" },
                      ]}
                    >
                      <Input size="large" placeholder="Location Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "lat"]}
                      style={{ width: "20%" }}
                      rules={[{ required: true, message: "Missing Latitude" }]}
                    >
                      <Input size="large" placeholder="Latitude" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "lng"]}
                      style={{ width: "20%" }}
                      rules={[{ required: true, message: "Missing Longitude" }]}
                    >
                      <Input size="large" placeholder="Longitude" />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ marginTop: "10px", fontSize: "18px" }}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ width: "50%" }}
                  >
                    Add Location
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <br />
          <Divider orientation="left" orientationMargin="0">
            Tabs Setup
          </Divider>
          <Form.List name="tabs_list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "tab_title"]}
                      style={{ width: "30%" }}
                      rules={[
                        { required: true, message: "Missing Tab Title" },
                      ]}
                    >
                      <Input size="large" placeholder="Tab Title" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "tab_content"]}
                      style={{ width: "70%" }}
                      rules={[{ required: true, message: "Missing Content" }]}
                    >
                      <TextArea size="large" placeholder="Content" autoSize/>
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ marginTop: "10px", fontSize: "18px" }}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ width: "50%" }}
                  >
                    Add Tab
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <br />
          <Divider orientation="left" orientationMargin="0">
            SEO Details
          </Divider>
          <Form.Item
            label="Meta Title"
            name="meta_title"
            rules={[
              {
                required: true,
                message: "Meta Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Meta Title" />
          </Form.Item>

          <Form.Item
            label="Meta Description"
            name="meta_description"
            rules={[
              {
                required: true,
                message: "Meta Description cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Meta Description" />
          </Form.Item>

          <Form.Item
            label="Meta Keywords"
            name="meta_keywords"
            rules={[
              {
                required: true,
                message: "Meta Keywords cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Meta Keywords" />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditEvent;
