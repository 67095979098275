import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useDashboardStores } from "../store";

function useDashboard(actionPrivileges, SelectedYear) {
  const [state, setState] = useState([]);
  const { setDashboardData, dashboardData } = useDashboardStores();

  useEffect(() => {
    const getData = () => {
      Progress.show();
      PostDataAuth(apis.DASHBOARD, {
        year: SelectedYear,
      })
        .then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            setState(responseJson.data);
            setDashboardData(responseJson.data);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        })
        .catch((error) => {
          console.log("error", error);
          Progress.hide();
          message.error(error.message);
        });
    };
    if (
      actionPrivileges.includes("DASHBOARD_DATA") ||
      dashboardData?.length === 0
    ) {
      getData();
    } else {
      setState(dashboardData);
    }
    // eslint-disable-next-line
  }, [SelectedYear]);
  return state;
}

export default useDashboard;
