import {
  BankOutlined,
  BookOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SwitcherOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { GetDataAuth } from "../../apiService/GetData";
import useUniversityList from "../../hooks/useUniversityList";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { FilterRow } from "./FilterRow";
import StatusTag from "../../util/StatusTag";

const { confirm } = Modal;

function UniversityTable() {
  const navigate = useNavigate();
  const isMobileDevice = useMediaQuery({ query: "(max-width: 600px)" });
  const actionPrivileges = useStore((state) => state.actionPrivileges);
  const setActiveRoute = useStore((state) => state.setActiveRoute);

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    country_id: "all",
    dataTable: true,
    name: "",
  });

  const allUserList = useUniversityList(filterData);
  console.log(allUserList);

  const columns = [
    {
      title: "",
      align: "center",
      dataIndex: "uni_logo",
      render: (uni_logo) => (
        <div className="table_av">
          {uni_logo ? (
            <Avatar src={`${IMAGE_PATH}/${uni_logo}`} />
          ) : (
            <Avatar
              style={{ color: "#234caa", backgroundColor: "#06297a30" }}
              icon={<BankOutlined />}
            />
          )}
        </div>
      ),
      width: 50,
    },
    {
      title: "University Name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record) => <p>{record.name}</p>,
      sortDirections: ["descend", "ascend"],
      width: 250,
    },
    {
      title: "Country",
      sorter: (a, b) => a.country.length - b.country.length,
      render: (record) => <p>{record.country}</p>,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: "Created Date",
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Update Date",
      render: (record) =>
        record.updated_at
          ? moment(record.updated_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Search Order",
      sorter: (a, b) => a.search_order - b.search_order,
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.search_order}</p>,
      width: 120,
    },
    {
      title: "Paper Base Form",
      render: (record) => (
        <a
          href={`${IMAGE_PATH}/${record.paperbase_form_file}`}
          target="_blank"
          rel="noreferrer"
        >
          <Tag
            className="tags"
            color={record.paperbase_form === 0 ? "#424242" : "#d76d00"}
          >
            {record.paperbase_form === 0 ? "NA" : "ATTACHED"}
          </Tag>
        </a>
      ),
      width: 130,
    },
    {
      title: "Status",
      render: (record) => <StatusTag status={record.status} />,
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          {actionPrivileges.includes("UNIVERSITY_APPLY_NOW") && (
            <Tooltip placement="bottom" title="Apply Now">
              <Button
                className="view_button"
                shape="circle"
                icon={<SwitcherOutlined />}
                onClick={() => applyNow(record.id, record.country_id)}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("UNIVERSITY_EDIT") && (
            <Tooltip placement="bottom" title="Edit">
              <Button
                className="edit_button"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() =>
                  navigate("/university-management/edit/" + record.id)
                }
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {record.status === 1
            ? actionPrivileges.includes("UNIVERSITY_INACTIVE") && (
                <Tooltip placement="bottom" title="Deactivate">
                  <Button
                    className="delete_button"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => statusChange(record.id, "Inactive")}
                  />
                </Tooltip>
              )
            : actionPrivileges.includes("UNIVERSITY_ACTIVE") && (
                <Tooltip placement="bottom" title="Activate">
                  <Button
                    className="view_button"
                    shape="circle"
                    icon={<CheckOutlined />}
                    onClick={() => statusChange(record.id, "Active")}
                  />
                </Tooltip>
              )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("UNIVERSITY_DELETE") && (
            <Tooltip placement="bottom" title="Delete">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteUni(record.id)}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("UNIVERSITY_ADD_COURSE") && (
            <Tooltip placement="bottom" title="Add Course">
              <Button
                className="view_button"
                shape="circle"
                icon={<BookOutlined />}
                onClick={() => addCourse(record.id, record.country_id)}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 210,
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this university?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.CHANGE_UNIVERSITY_STATUS}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteUni = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this university?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.DELETE_UNIVERSITY}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const applyNow = (uid, country_id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to apply for this university?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        navigate("/enquiries/create", {
          state: { uid: uid, country_id: country_id },
        });
        setActiveRoute("enquiries");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const addCourse = (uid, country_id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to add new course for this university?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        navigate("/courses-management/create", {
          state: { uid: uid, country_id: country_id },
        });
        setActiveRoute("courses-management");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <FilterRow filterData={filterData} setFilterData={setFilterData} />
      <Table
        scroll={isMobileDevice ? { x: 1300 } : { x: 1300 }}
        columns={columns}
        dataSource={allUserList.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: allUserList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default UniversityTable;
