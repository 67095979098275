import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select, DatePicker } from "antd";
import React, { useState } from "react";
import useAllUserList from "../../hooks/useAllUserList";
import useCountries from "../../hooks/useCountries";
import useCourseLevels from "../../hooks/useCourseLevels";
import useIntakeMonths from "../../hooks/useIntakeMonths";
import useIntakeYears from "../../hooks/useIntakeYears";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import useAllUserListAgents from "../../hooks/useAllUserListAgents";
import useApplicationStatusList from "../../hooks/useApplicationStatusList";
import { useStore } from "../../store";
const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  const [validTime, setValidTime] = useState();
  const [postData, setPostData] = useState({
    country_id: "all",
  });

  const [assign_filter] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    role: [2, 4],
    dataTable: false,
  });

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const countries = useCountries(filterDataOther);
  const filteredUniversities = useUniversityByCountry(postData.country_id);
  const courseLevels = useCourseLevels(filterDataOther);
  const intakeMonths = useIntakeMonths(filterDataOther);
  const intakeYears = useIntakeYears(filterDataOther);
  const allUserList = useAllUserList(assign_filter);
  const allAgentList = useAllUserListAgents();
  const applicationStatusList = useApplicationStatusList();

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      ...values,
      start_date: validTime && validTime[0],
      end_date: validTime && validTime[1],
    });
  };

  const clearData = () => {
    setFilterData({
      page: 1,
      pageSize: 10,
      status: "all",
      dataTable: true,
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="date_range" noStyle>
              <RangePicker
                allowClear
                style={{ width: "95%" }}
                format={dateFormat}
                onChange={(date, dateString) => {
                  setValidTime(dateString);
                }}
              />
            </Form.Item>
            {actionPrivileges.includes("SHOW_AGENT_FILTER") && (
              <Form.Item name="agent_id" noStyle>
                <Select
                  placeholder="Select Agent Admin"
                  style={{ width: "100%" }}
                  allowClear
                >
                  {allAgentList?.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.first_name} {item.last_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="app_status" noStyle>
              <Select
                placeholder="Select Application Status"
                style={{ width: "100%" }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {applicationStatusList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.stat_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item name="app_id" noStyle>
              <Input
                placeholder="Application ID"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="student_name" noStyle>
              <Input
                placeholder="Student Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
            <Form.Item name="course_name" noStyle>
              <Input
                placeholder="Course Name"
                style={{ width: "95%" }}
                allowClear
              />
            </Form.Item>
          </div>

          {/* Select row */}

          <div className="row">
            <Form.Item name="country_id" noStyle>
              <Select
                allowClear
                placeholder="Select Country"
                style={{ width: "100%" }}
                onChange={(value) =>
                  setPostData({ ...postData, country_id: value })
                }
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="uni_id" noStyle>
              <Select
                placeholder="Select University"
                style={{ width: "100%" }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {filteredUniversities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="assign_id" noStyle>
              <Select
                placeholder="Select Assign To"
                style={{ width: "100%" }}
                allowClear
              >
                {allUserList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.first_name + " " + item.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          {/* Select row */}
          <div className="row">
            <Form.Item name="course_level_id" noStyle>
              <Select
                allowClear
                placeholder="Select Course Level"
                style={{ width: "100%" }}
              >
                {courseLevels.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_month_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Month"
                style={{ width: "100%" }}
              >
                {intakeMonths.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.months}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="intake_year_id" noStyle>
              <Select
                allowClear
                placeholder="Select Intake Year"
                style={{ width: "100%" }}
              >
                {intakeYears.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
