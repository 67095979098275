import { Button, Form, Input, message, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import useCountries from "../../hooks/useCountries";
import FileUpload from "../../util/FileUpload";
import useUniversityById from "../../hooks/useUniversityById";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

function EditUniversity() {
  const params = useParams();
  const [form] = Form.useForm();
  const location = useLocation();

  useBreadCrumb("Edit University", location.pathname, "", "add");
  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });
  const [postData, setPostData] = useState({});
  const [uniLogo, setUniLogo] = useState();
  const [upDoc, setUpDoc] = useState();
  const universityData = useUniversityById(params.id);
  const countries = useCountries(filterDataOther);

  useEffect(() => {
    if (universityData) {
      form.setFieldsValue({
        ...universityData,
      });
      setPostData({
        ...postData,
        paperbase_form: universityData.paperbase_form,
      });
      if (universityData.length !== 0) {
        if (universityData.uni_logo) {
          setUniLogo(universityData.uni_logo);
        }
        if (universityData.paperbase_form_file) {
          setUpDoc(universityData.paperbase_form_file);
        }
      }
    }
    // eslint-disable-next-line
  }, [universityData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
        id: params.id,
        uni_logo: uniLogo ? uniLogo : null,
        paperbase_form_file: upDoc ? upDoc : null,
      };
      PostDataAuth(apis.UPDATE_UNIVERSITY, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const changeForm = (e) => {
    setPostData({ ...postData, paperbase_form: e.target.value });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Edit University |<span>edit selected university</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                label="University name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "University name cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="University name" />
              </Form.Item>
              <Form.Item
                name="uni_desc"
                label="University Description"
                rules={[
                  {
                    required: true,
                    message: "University Description cannot be empty!",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  size="large"
                  placeholder="University Description"
                />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country_id"
                required
                rules={[
                  {
                    required: true,
                    message: "Country cannot be empty!",
                  },
                ]}
              >
                <Select size="large" placeholder="Select Country">
                  {countries.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="University email" name="uni_email">
                <Input
                  type="text"
                  size="large"
                  placeholder="University email"
                />
              </Form.Item>
              <Form.Item name="uni_address" label="University Address">
                <Input
                  type="text"
                  size="large"
                  placeholder="University Address"
                />
              </Form.Item>
              <Form.Item name="search_order" label="search_order">
                <Input
                  type="number"
                  size="large"
                  placeholder="search_order"
                />
              </Form.Item>
              <Form.Item name="uni_phone" label="University Phone Number">
                <Input
                  type="text"
                  size="large"
                  placeholder="University Phone Number"
                />
              </Form.Item>
              <Form.Item name="uni_web" label="University Website">
                <Input
                  type="text"
                  size="large"
                  placeholder="University Website"
                />
              </Form.Item>
            </div>
            <div className="input_field">
              <div className="rg_box">
                <p className="common_header">
                  University logo |<span>upload university logo</span>
                </p>
                <FileUpload
                  type="image"
                  location="images/university_logo"
                  count={1}
                  imageUpload={uniLogo}
                  setImageUpload={setUniLogo}
                  accept="image/png, image/jpeg"
                  prefix="uni_logo"
                />
              </div>
              <div className="rg_box">
                <p className="common_header">
                  Is PaperBase Form Required |<span>select is required</span>
                </p>
                <Form.Item
                  name="paperbase_form"
                  rules={[
                    {
                      required: true,
                      message: "PaperBase Form cannot be empty!",
                    },
                  ]}
                >
                  <Radio.Group onChange={changeForm}>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {postData.paperbase_form === 1 && (
                  <>
                    <p className="common_header">
                      Paperbase Form File |
                      <span>upload Paperbase document</span>
                    </p>
                    <FileUpload
                      type="file"
                      location="documents/university_doc"
                      count={1}
                      imageUpload={upDoc}
                      setImageUpload={setUpDoc}
                      accept="application/pdf"
                      prefix="paperbase_doc"
                    />
                  </>
                )}
              </div>
              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditUniversity;
