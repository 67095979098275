import React from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import ActionPrivilegeTable from "./ActionPrivilegeTable";

export default function ActionPrivilege() {
  const location = useLocation();
  useBreadCrumb("Manage Action Privilege", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Action privilege |<span>manage action privilege</span>
        </p>
        <ActionPrivilegeTable />
      </div>
    </div>
  );
}
