import { EditOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import React, { useState } from "react";
import useActionPrivileges from "../../../hooks/useActionPrivileges";
import ChangePrivilege from "./ChangePrivilege";
import useUserTypes from "../../../hooks/useUserTypes";
import { FilterRow } from "./FilterRow";

function ActionPrivilegeTable() {
  const [visible, setVisible] = useState(false);
  const [selectData, setSelectData] = useState("");
  const userTypes = useUserTypes();

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    dataTable: true,
    page_key: "",
    role: '',
  });

  const actionPrivileges = useActionPrivileges(filterData);

  const columns = [
    {
      title: "Page Key",
      sorter: (a, b) => a.page_key.length - b.page_key.length,
      render: (record) => <p>{record.page_key}</p>,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action Key",
      sorter: (a, b) => a.action_key.length - b.action_key.length,
      render: (record) => <p>{record.action_key}</p>,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Roles",
      sorter: (a, b) => a.roles.length - b.roles.length,
      render: (record) => (
        <p>
          {JSON.parse(record?.roles).map((role) => (
            <span key={role.id} className="spam_box">
              {userTypes?.find((item) => item.id === role)?.name}
            </span>
          ))}
        </p>
      ),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectData(record);
                setVisible(true);
              }}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
    },
  ];

  return (
    <>
      <FilterRow filterData={filterData} setFilterData={setFilterData} />
      <Table
        columns={columns}
        dataSource={actionPrivileges.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: actionPrivileges.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
      {visible && (
        <ChangePrivilege
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
          selectData={selectData}
        />
      )}
    </>
  );
}

export default ActionPrivilegeTable;
