import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import AddUserType from "./AddUserType";
import UserTypeTable from "./UserTypeTable";

export default function UserType() {
  const [visible, setVisible] = useState(false);

  const location = useLocation();
  useBreadCrumb("Manage User Type", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
      <div className="top_row">
          <Button className="primary__btn" onClick={() => setVisible(true)}>
            Add new user type
          </Button>
        </div>
        <UserTypeTable />
      </div>
      {visible && (
        <AddUserType
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
        />
      )}
    </div>
  );
}
