import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import AddIntakeMonths from "./AddIntakeMonths";
import IntakeMonthsTable from "./IntakeMonthsTable";

export default function IntakeMonths() {
  const location = useLocation();
  useBreadCrumb("Intake Months", location.pathname, "", "add");
  const [visible, setVisible] = useState(false);

  return (
    <div className="dashboard">
    <div className="section_row">
      <div className="top_row">
        <Button className="primary__btn" onClick={() => setVisible(true)}>Add New Intake Month</Button>
      </div>
      <IntakeMonthsTable />
    </div>
    {visible && (
      <AddIntakeMonths
        visible={visible}
        setVisible={() => {
          setVisible(!visible);
        }}
        type="add"
        selectId={0}
      />
    )}
  </div>
  );
}
