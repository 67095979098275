import React from "react";

const AddressInfo = ({ data }) => {
  return (
    <div>
      <p className="common_header">
        Address Details |<span>student address details</span>
      </p>
      <div className="view_box_body">
        <div className="view_row">
          <p className="type">Address</p>
          <p className="data">{data.address ? data.address : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Country</p>
          <p className="data">{data.country_name ? data.country_name : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">State</p>
          <p className="data">{data.state_name ? data.state_name : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">City</p>
          <p className="data">{data.city_name ? data.city_name : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Postal Code</p>
          <p className="data">{data.postal_code ? data.postal_code : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Refused Visa from USA, UK, NZ or Australia</p>
          <p className="data">
            {data.refused_doc ? 'Yes' : "No"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
