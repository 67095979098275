import { RightOutlined } from "@ant-design/icons";
import React from "react";

export const SettingRow = ({ title, description, Icon }) => {
  return (
    <div className="setting_box_content_main">
      <div className="setting_box_content">
        {Icon}
        <div className="setting_box_title">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
      <RightOutlined />
    </div>
  );
};
