import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import React from "react";
import Progress from "react-progress-2";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import EnquiryTable from "../components/Enquiry/EnquiryTable";
import useBreadCrumb from "../hooks/useBreadCrumb";
import { apis, IMAGE_PATH } from "../properties";
import { useStore } from "../store";

const { confirm } = Modal;

export default function Enquiries() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Students", location.pathname, "Students");
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  const exportFile = () => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `You are about to export all the enquiries to a CSV file.`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(apis.FILE_EXPORT, { type: "enquiry-all" }).then(
          (result) => {
            let responseJson = result;
            if (responseJson.status === "success") {
              Progress.hide();
              message.success("File export successfully");
              window.open(`${IMAGE_PATH}/${responseJson.data}`);
            } else if (responseJson.status === "error") {
              message.error(responseJson.message);
              Progress.hide();
            }
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          {actionPrivileges.includes("ENQUIRY_ADD") && (
            <Button
              className="primary__btn"
              onClick={() => navigate("/enquiries/create")}
            >
              Add Student
            </Button>
          )}
          {actionPrivileges.includes("ENQUIRY_EXPORT_BTN") && (
            <>
              <Button className="default__btn" onClick={() => exportFile()}>
                Export
              </Button>
            </>
          )}
        </div>
        <EnquiryTable />
      </div>
    </div>
  );
}
