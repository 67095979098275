import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";
import useIntakeMonths from "../../hooks/useIntakeMonths";
import useIntakeYears from "../../hooks/useIntakeYears";

const { Option } = Select;

export const IntakesInputs = () => {

  const [filterDataOther] = useState({
    status: 1,
  });
  
  const intakeMonths = useIntakeMonths(filterDataOther);
  const intakeYears = useIntakeYears(filterDataOther);

  return (
    <>
      <Form.List name="intakes">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  marginBottom: 0,
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Form.Item
                  {...restField}
                  name={[name, "month_id"]}
                  style={{ width: "50%" }}
                  rules={[{ required: true, message: 'Missing intake month' }]}
                >
                  <Select size="large" placeholder="Select Intake Month">
                    {intakeMonths.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.months}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "year_id"]}
                  style={{ width: "20%" }}
                  rules={[{ required: true, message: 'Missing intake year' }]}
                >
                  <Select size="large" placeholder="Select Intake Year">
                    {intakeYears.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.year}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "course_limit"]}
                  style={{ width: "20%" }}
                  rules={[{ required: true, message: 'Missing course limit' }]}
                >
                  <Input size="large" placeholder="Limit" />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => remove(name)}
                  style={{ marginTop: "10px", fontSize: "18px" }}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                style={{ width: "50%" }}
              >
                Add Intake
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
