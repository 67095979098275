import { message } from "antd";
import { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { GetData } from "../apiService/GetData";
import { apis } from "../properties";

function useCities(id) {
  const [state, setState] = useState([]);

  useEffect(() => {
    const getData = () => {
      Progress.show();
      GetData(`${apis.CITY_LIST}/${id}`).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setState(responseJson.data);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    };
    getData();
    // eslint-disable-next-line
  }, [id]);
  return state;
}

export default useCities;
