import { Popover, Steps } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { StepFive } from "./StepFive";
import { StepFour } from "./StepFour";
import { StepOne } from "./StepOne";
import { StepSix } from "./StepSix";
import { StepThree } from "./StepThree";
import { StepTwo } from "./StepTwo";

const { Step } = Steps;

export default function AddEnquiry() {
  const location = useLocation();
  useBreadCrumb("Add Student", location.pathname, "", "add");
  const [currentStep, setCurrentStep] = React.useState(0);

  // const onChange = (currentStep) => {
  //   setCurrentStep(currentStep);
  // };

  const steps = [
    {
      title: "Apply for Course",
      content: (
        <StepOne
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          typeOpz="new"
        />
      ),
    },
    {
      title: "Upload Documents",
      content: (
        <StepTwo
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "Personal Information",
      content: (
        <StepThree
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "Address Details",
      content: (
        <StepFour
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "Education Summary",
      content: (
        <StepFive
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "IELTS / PTE Details",
      content: (
        <StepSix
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
          finalStep={true}
        />
      ),
    },
  ];

  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
      className="step-popover"
    >
      {index + 1}
    </Popover>
  );

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Create Student |<span>create new student</span>
        </p>
        <Steps
          size="small"
          progressDot={customDot}
          current={currentStep}
          // onChange={onChange}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
      </div>
    </div>
  );
}
