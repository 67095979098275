import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import AddRequireDoc from "./AddRequireDoc";
import RequirementsDocumentsTable from "./RequirementsDocumentsTable";

export default function RequirementsDocuments() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  useBreadCrumb("Requirements Documents", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button className="primary__btn" onClick={() => setVisible(true)}>Add Requirement Documents</Button>
        </div>
        <RequirementsDocumentsTable />
      </div>
      {visible && (
        <AddRequireDoc
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
          type="add"
          selectId={0}
        />
      )}
    </div>
  );
}
