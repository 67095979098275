import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { useNavigate } from "react-router-dom";
import { GetDataAuth } from "../../apiService/GetData";
import useWorkflowList from "../../hooks/useWorkflowList";
import { apis } from "../../properties";
import { useRefreshTable, useStore } from "../../store";

const { confirm } = Modal;

function WorkflowsTable() {
  const navigate = useNavigate();
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    country_id: "",
    dataTable: true,
    uni_id: "",
  });

  const workflowList = useWorkflowList(filterData);

  const columns = [
    {
      title: "Rule",
      render: (record) => <p>{record.rule}</p>,
      width: 200,
    },
    {
      title: "Agent Names",
      render: (record) => <p>{record.agent_name}</p>,
      width: 250,
    },
    {
      title: "Staff Name",
      render: (record) => record.staff.map((staff) => <Tag color="cyan">{staff}</Tag>),
      width: 150,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag
          className="tags"
          color={record.status === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          {actionPrivileges.includes("COURSE_EDIT") && (
            <Tooltip placement="bottom" title="Edit">
              <Button
                className="edit_button"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => navigate("/workflows/edit/" + record.id)}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("COURSE_DELETE") && (
            <Tooltip placement="bottom" title="Delete">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteUni(record.id)}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 130,
    },
  ];

  const deleteUni = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this workflow?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.DELETE_WORKFLOW}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        scroll={{ x: 1200 }}
        columns={columns}
        dataSource={workflowList.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.per_page,
          total: workflowList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default WorkflowsTable;
