import React from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import PagePrivilegeTable from "./PagePrivilegeTable";

export default function PagePrivilege() {
  const location = useLocation();
  useBreadCrumb("Manage User Privilege", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          User privilege |<span>manage user privilege</span>
        </p>
        <PagePrivilegeTable />
      </div>
    </div>
  );
}
