import { Modal, message } from "antd";
import { GetDataAuth } from "../apiService/GetData";
import { apis } from "../properties";
import Progress from "react-progress-2";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;
export const permanentDelete = (type, id, refreshTable, setRefreshTable) => {
  confirm({
    title: `🔴 Are you sure?`,
    icon: <ExclamationCircleFilled />,
    content: `Do  you want to delete this recode permanently? This action cannot be undone and all the data related to this record will be deleted.`,
    okText: "Yes",
    okType: "primary",
    cancelText: "No",
    onOk() {
      Progress.show();
      GetDataAuth(`${apis.DELETE_RECORD}/${type}/${id}`).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          message.success(responseJson.message);
          setRefreshTable(!refreshTable);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    },
    onCancel() {
      message.info("Action cancelled");
    },
  });
};
