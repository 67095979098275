import React from "react";
import { IoSync } from "react-icons/io5";
import {
  MdOutlineAddModerator,
  MdOutlineAssignmentTurnedIn,
  MdOutlineAvTimer,
  MdOutlineFactCheck,
  MdOutlineFilterHdr,
  MdOutlineInsertInvitation,
  MdOutlineLibraryBooks,
  MdOutlinePersonOutline,
  MdOutlineRateReview,
  MdOutlineToll,
  MdOutlineTravelExplore,
  MdOutlineCardTravel,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { SettingRow } from "../components/Settings/SettingRow";
import useBreadCrumb from "../hooks/useBreadCrumb";
import { useStore } from "../store";

export default function Settings() {
  const location = useLocation();
  useBreadCrumb("Settings", location.pathname, "Settings");
  const actionPrivileges = useStore((state) => state.actionPrivileges);

  return (
    <div className="dashboard">
      {actionPrivileges.includes("SETTING_SYSTEM_SECTION") && (
        <>
          <p className="common_header">
            System Settings |<span>manage your system data here</span>
          </p>
          <div className="section_row">
            <ul className="setting_box">
              {actionPrivileges.includes("SETTING_MY_PROFILE") && (
                <li>
                  <Link to="/settings/my-profile">
                    <SettingRow
                      title="My Profile"
                      description="Here you can edit my profile data, change password, change profile image and more."
                      Icon={<MdOutlinePersonOutline />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_MANAGE_USER_TYPE") && (
                <li>
                  <Link to="/settings/user-type">
                    <SettingRow
                      title="Manage User Types"
                      description="Here you can change user role or add new user role"
                      Icon={<MdOutlineToll />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_MANAGE_COUNTRY") && (
                <li>
                  <Link to="/settings/country-management">
                    <SettingRow
                      title="Country Management"
                      description="Here you can add, edit, delete country"
                      Icon={<MdOutlineTravelExplore />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_MANAGE_PAGE_PRIVILEGE") && (
                <li>
                  <Link to="/settings/page-privilege">
                    <SettingRow
                      title="Manage Page Privilege"
                      description="Here you can change page role permission or assign new permission for user types"
                      Icon={<MdOutlineAddModerator />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_MANAGE_ACTION_PRIVILEGE") && (
                <li>
                  <Link to="/settings/action-privilege">
                    <SettingRow
                      title="Manage Action Privilege"
                      description="Here you can change action role permission or assign new permission for user types"
                      Icon={<MdOutlineRateReview />}
                    />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </>
      )}

      {actionPrivileges.includes("SETTING_COURSE_SECTION") && (
        <>
          <p className="common_header">
            Courses Settings |<span>manage courses data here</span>
          </p>
          <div className="section_row">
            <ul className="setting_box">
              {actionPrivileges.includes("SETTING_COURSE_LEVEL") && (
                <li>
                  <Link to="/settings/corses-level">
                    <SettingRow
                      title="Corses Level"
                      description="Here you can add, edit, delete courses level "
                      Icon={<MdOutlineFilterHdr />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_REQUIRE_DOC") && (
                <li>
                  <Link to="/settings/requirements-documents">
                    <SettingRow
                      title="Requirements Documents"
                      description="Here you can add, edit, delete requirements documents"
                      Icon={<MdOutlineLibraryBooks />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_INTAKE_MONTH") && (
                <li>
                  <Link to="/settings/intake-months">
                    <SettingRow
                      title="Intake Months"
                      description="Here you can add, edit, delete intake months"
                      Icon={<MdOutlineInsertInvitation />}
                    />
                  </Link>
                </li>
              )}
              {actionPrivileges.includes("SETTING_INTAKE_YEAR") && (
                <li>
                  <Link to="/settings/intake-years">
                    <SettingRow
                      title="Intake Years"
                      description="Here you can add, edit, delete intake years"
                      Icon={<MdOutlineAvTimer />}
                    />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </>
      )}
      {actionPrivileges.includes("SETTING_APPLICATION_STATUS") && (
        <>
          <p className="common_header">
            Application Settings |<span>manage application data here</span>
          </p>
          <div className="section_row">
            <ul className="setting_box">
              <li>
                <Link to="/settings/application-status">
                  <SettingRow
                    title="Application Status"
                    description="Here you can add, edit, delete application status "
                    Icon={<MdOutlineAssignmentTurnedIn />}
                  />
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}

      {actionPrivileges.includes("SETTING_MANAGE_SUBJECT") && (
        <>
          <p className="common_header">
            Manage Subject |<span>manage subjects data here</span>
          </p>
          <div className="section_row">
            <ul className="setting_box">
                <li>
                  <Link to="/settings/subjects">
                    <SettingRow
                      title="Manage Subject"
                      description="Here you can add, edit, delete subjects "
                      Icon={<MdOutlineFactCheck />}
                    />
                  </Link>
                </li>
            </ul>
          </div>
        </>
      )}
      {actionPrivileges.includes("SETTING_SCRAP_DATA") && (
        <>
          <p className="common_header">
            Scraping data Sync |<span>manage scraping data sync here</span>
          </p>
          <div className="section_row">
            <ul className="setting_box">
              <li>
                <Link to="/settings/scraping-data-sync">
                  <SettingRow
                    title="Scraping Data Sync"
                    description="Here you can sync scraping data"
                    Icon={<IoSync />}
                  />
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}

      {actionPrivileges.includes("SETTING_AGENT_SECTION") && (
        <>
          <p className="common_header">
            Agent onboarding |<span> manage agent onboarding here</span>
          </p>
          <div className="section_row">
            <ul className="setting_box">
              <li>
                <Link to="/settings/package-management">
                  <SettingRow
                    title="Package Management"
                    description="Update package details"
                    Icon={<MdOutlineCardTravel />}
                  />
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
