import { Button, DatePicker, Form, Input, message, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PostData, PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import { useEnquiryDataStore } from "../../store";
import Progress from "react-progress-2";
import { useNavigate } from "react-router-dom";

export const StepSix = ({
  currentStep,
  setNextStep,
  type,
  enquiryId,
  refId,
}) => {
  const [form] = Form.useForm();

  const [examDate, setExamDate] = useState();
  const navigate = useNavigate();

  const { enquiryData, setEnquiryData } = useEnquiryDataStore();

  useEffect(() => {
    if (enquiryData) {
      form.setFieldsValue({
        ...enquiryData,
        exam_type: enquiryData.ieltsData.exam_type,
        listening_score: enquiryData.ieltsData.listening_score,
        overall_score: enquiryData.ieltsData.overall_score,
        reading_score: enquiryData.ieltsData.reading_score,
        speaking_score: enquiryData.ieltsData.speaking_score,
        writing_score: enquiryData.ieltsData.writing_score,
        examDate: enquiryData.ieltsData.examDate
          ? moment(enquiryData.ieltsData.examDate)
          : undefined,
      });
      setExamDate(
        enquiryData.ieltsData.examDate
          ? enquiryData.ieltsData.examDate
          : undefined
      );
    }
    // eslint-disable-next-line
  }, [enquiryData]);

  const submitForm = () => {
    form.validateFields().then((values) => {
      let postData = {
        ...values,
        examDate: examDate,
      };
      Progress.show();
      var URL = apis.CREATE_ENQUIRIES;
      if (type === "edit") {
        URL = apis.UPDATE_ENQUIRIES;
      } else if (type === "referral") {
        URL = apis.CREATE_ENQUIRIES_REF;
      }
      const fetchMethod = type === "referral" ? PostData : PostDataAuth;
      fetchMethod(URL, {
        ...enquiryData,
        ieltsData: postData,
        refId: refId ? refId : undefined,
        id: enquiryId ? enquiryId : "",
      }).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          setEnquiryData({
            ...enquiryData,
            applications: [],
            uploadDocuments: [],
            refusedDoc: "",
            personalData: {},
            addressData: {},
            educationData: {
              detailsOne: {},
              detailsTwo: {},
              diplomaDetails: {},
              graduateDetails: {},
              postGraduateDetails: {},
              phdDetails: {},
            },
            ieltsData: {},
          });
          setNextStep(0);
          type !== "referral" && navigate("/enquiries");
          // navigate("/enquiries");
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const prev = () => {
    setNextStep(currentStep - 1);
  };

  return (
    <div className="step_board">
      <p className="common_header">
        IELTS / PTE Details |<span>enter IELTS / PTE Details</span>
      </p>
      <Form
        autoComplete="off"
        form={form}
        initialValues={{ remember: true }}
        layout="vertical"
      >
        <div className="row_2">
          <div className="input_field">
            <Form.Item name="exam_type" label="Exam Type">
              <Select
                size="large"
                placeholder="IELTS / PTE Details"
                // onChange={(value) => console.log(`selected ${value}`)}
                options={[
                  { value: "IELTS", label: "IELTS" },
                  { value: "PTE", label: "PTE" },
                ]}
              />
            </Form.Item>
            <Form.Item name="overall_score" label="Overall Score">
              <Input size="large" placeholder="Overall Score" />
            </Form.Item>
            <Form.Item name="reading_score" label="Reading Score">
              <Input size="large" placeholder="Reading Score" />
            </Form.Item>
            <Form.Item name="speaking_score" label="Speaking Score">
              <Input size="large" placeholder="Speaking Score" />
            </Form.Item>
          </div>
          <div className="input_field">
            <Form.Item name="examDate" label="Date Of Exam">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Date Of Exam"
                onChange={(date, dateString) => {
                  setExamDate(dateString);
                }}
              />
            </Form.Item>
            <Form.Item name="listening_score" label="Listening Score">
              <Input size="large" placeholder="Listening Score" />
            </Form.Item>
            <Form.Item name="writing_score" label="Writing Score">
              <Input size="large" placeholder="Writing Score" />
            </Form.Item>
          </div>
        </div>
        <div className="left_btn">
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" onClick={() => submitForm()}>
            Done
          </Button>
        </div>
      </Form>
    </div>
  );
};
