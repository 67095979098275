import React from "react";
import { IMAGE_PATH } from "../../properties";
import { CloudDownloadOutlined } from "@ant-design/icons";

const DownloadItem = ({name, url}) => {
  return (
    <div className="do_file">
      <p>{name}</p>
      <div
        className="do_file_btn"
        onClick={() => window.open(`${IMAGE_PATH}/${url}`, "_blank")}
      >
        <CloudDownloadOutlined />
      </div>
    </div>
  );
};

export default DownloadItem;
