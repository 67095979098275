import { Button, Form, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCountries from "../../hooks/useCountries";
import useCourseLevels from "../../hooks/useCourseLevels";
import useCoursesList from "../../hooks/useCoursesList";
import useIntakeMonths from "../../hooks/useIntakeMonths";
import useIntakeYears from "../../hooks/useIntakeYears";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import useUniversityById from "../../hooks/useUniversityById";
import { IMAGE_PATH } from "../../properties";
import { useEnquiryDataStore } from "../../store";
import FileUpload from "../../util/FileUpload";
import DownloadItem from "../atoms/DownloadItem";
import ApplicationList from "./ApplicationList";
import { DownloadFile } from "./FileList";

const { Option } = Select;

export const StepOne = ({ currentStep, setNextStep, typeOpz }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [postData, setPostData] = useState({
    country_id: location.state?.country_id || "all",
    uni_id: location.state?.uid || 0,
    course_id: location.state?.course_id,
    course_level_id: location.state?.course_level_id,
    intake_month: location.state?.intake_month,
    intake_year: location.state?.intake_year,
  });

  useEffect(() => {
    form.setFieldsValue({
      country_id: postData.country_id === "all" ? null : postData.country_id,
      uni_id: postData.uni_id || null,
      course_id: postData.course_id || null,
      course_level_id: postData.course_level_id || null,
      intake_month: postData.intake_month || null,
      intake_year: postData.intake_year || null,
    });
    // eslint-disable-next-line
  }, []);

  const [sop_doc, setUpDocSOP] = useState();
  const [paperbase_doc, setUpDocPaperbase] = useState();
  const [applicationList, setApplicationList] = useState([]);

  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);

  const [courseFilter, setCourseFilter] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    country_id: location.state?.country_id || "",
    dataTable: false,
    name: "",
    uni_id: location.state?.uid || "",
    course_level_id: location.state?.course_level_id || "",
    intake_month_id: location.state?.intake_month || "",
    intake_year_id: location.state?.intake_year || "",
  });

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const countries = useCountries(filterDataOther);
  const filteredUniversities = useUniversityByCountry(postData.country_id);
  const intakeMonths = useIntakeMonths(filterDataOther);
  const intakeYears = useIntakeYears(filterDataOther);
  const courseLevels = useCourseLevels(filterDataOther);
  const coursesList = useCoursesList(courseFilter);
  const universityById = useUniversityById(postData.uni_id);

  useEffect(() => {
    if (enquiryData.applications.length > 0) {
      setApplicationList(enquiryData.applications);
    }
    // eslint-disable-next-line
  }, [enquiryData.applications]);

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        let appList = [...applicationList];
        let newApplication = {
          ...values,
          sop_doc: sop_doc,
          paperbase_doc: paperbase_doc,
          id: 0,
          status: 1,
        };
        appList.push(newApplication);
        setApplicationList(appList);
        setEnquiryData({
          ...enquiryData,
          applications: appList,
        });
        form.resetFields();
        setUpDocSOP();
        setUpDocPaperbase();
        setPostData({
          country_id: "all",
          uni_id: 0,
        });
        setNextStep(currentStep + 1);
        navigate(location.pathname, { replace: true });
      })
      .catch((err) => {
        if (applicationList.length > 0) {
          setNextStep(currentStep + 1);
        } else {
          message.error("Please fill all the details");
        }
      });
  };

  const UniversityDescription = () => {
    if (postData.uni_id !== 0) {
      if (universityById.length !== 0) {
        const disArr = universityById.uni_desc.split("\n");
        return (
          <div className="uni_data_dox">
            <p className="common_header">
              University Description |<span>check this description</span>
            </p>
            <h3>{universityById.name}</h3>
            <ol>
              {disArr.map((item, index) => (
                <li key={index}>●&nbsp;{item}</li>
              ))}
            </ol>
            {universityById.paperbase_form === 1 && (
              <a
                href={`${IMAGE_PATH}/${universityById.paperbase_form_file}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary">Download Paperbase Form</Button>
              </a>
            )}
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };

  const addNewOne = () => {
    form.validateFields().then((values) => {
      let appList = [...applicationList];
      let newApplication = {
        ...values,
        sop_doc: sop_doc,
        paperbase_doc: paperbase_doc,
        id: 0,
        status: 1,
      };
      appList.push(newApplication);
      setApplicationList(appList);
      setEnquiryData({
        ...enquiryData,
        applications: appList,
      });
      form.resetFields();
      setUpDocSOP();
      setUpDocPaperbase();
      setPostData({
        country_id: "all",
        uni_id: 0,
      });
    });
  };

  const clearForm = () => {
    form.resetFields();
    setUpDocSOP();
    setUpDocPaperbase();
    setPostData({
      country_id: "all",
      uni_id: 0,
    });
  };

  const clearApplications = () => {
    setApplicationList([]);
    setEnquiryData({
      ...enquiryData,
      applications: [],
      uploadDocuments: [],
      refusedDoc: "",
      personalData: {},
      addressData: {},
      educationData: {
        detailsOne: {},
        detailsTwo: {},
        diplomaDetails: {},
        graduateDetails: {},
        postGraduateDetails: {},
        phdDetails: {},
      },
      ieltsData: {},
    });
  };

  const onChangeCountry = (value) => {
    setPostData({ ...postData, country_id: value });
    setCourseFilter({ ...courseFilter, country_id: value });
    form.setFieldsValue({
      uni_id: null,
      intake_month: null,
      intake_year: null,
      course_level_id: null,
      name: null,
      course_id: null,
    });
  };

  const onChangeUni = (value) => {
    setPostData({ ...postData, uni_id: value });
    setCourseFilter({ ...courseFilter, uni_id: value });
    form.setFieldsValue({
      intake_month: null,
      intake_year: null,
      course_level_id: null,
      name: null,
      course_id: null,
    });
  };

  const onChangeIntakeMonths = (value) => {
    setPostData({ ...postData, intake_month: value });
    setCourseFilter({ ...courseFilter, intake_month_id: value });
    form.setFieldsValue({
      intake_year: null,
      course_level_id: null,
      name: null,
      course_id: null,
    });
  };

  const onChangeIntakeYears = (value) => {
    setPostData({ ...postData, intake_year: value });
    setCourseFilter({ ...courseFilter, intake_year_id: value });
    form.setFieldsValue({
      course_level_id: "",
      name: "",
      course_id: "",
    });
  };

  const onChangeCourseLevel = (value) => {
    setPostData({ ...postData, course_level_id: value });
    setCourseFilter({ ...courseFilter, course_level_id: value });
    form.setFieldsValue({
      name: null,
      course_id: null,
    });
  };

  return (
    <div className="step_board">
      {applicationList.length > 0 && (
        <div className="collapseList">
          <p className="common_header">
            Applications List |<span>all application for apply</span>
          </p>
          <ApplicationList
            applicationList={applicationList}
            setApplicationList={setApplicationList}
            typeOpz={typeOpz}
          />
        </div>
      )}
      <p className="common_header">
        Apply For New Course |<span>select your course details</span>
      </p>
      <Form
        autoComplete="off"
        form={form}
        layout="horizontal"
        labelCol={{ span: 5 }}
      >
        <div className="row_2">
          <div className="input_field">
            <Form.Item
              name="country_id"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select Country"
                onChange={(value) => onChangeCountry(value)}
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="uni_id"
              label="University"
              rules={[
                {
                  required: true,
                  message: "University cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select University"
                onChange={(value) => onChangeUni(value)}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {filteredUniversities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="intake_month"
              label="Intake Month"
              rules={[
                { required: true, message: "Intake month cannot be empty!" },
              ]}
            >
              <Select
                size="large"
                placeholder="Select Intake Month"
                onChange={(value) => onChangeIntakeMonths(value)}
              >
                {intakeMonths.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.months}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="intake_year"
              label="Intake Year"
              rules={[
                { required: true, message: "Intake year cannot be empty!" },
              ]}
            >
              <Select
                size="large"
                placeholder="Select Intake Year"
                onChange={(value) => onChangeIntakeYears(value)}
              >
                {intakeYears.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="course_level_id"
              label="Course Level"
              rules={[
                {
                  required: true,
                  message: "course Level cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select course Level"
                onChange={(value) => onChangeCourseLevel(value)}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {courseLevels.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="course_id"
              label="Applying Course"
              rules={[
                {
                  required: true,
                  message: "Course cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Applying Course"
                onChange={(value) => {
                  setPostData({ ...postData, course_id: value });
                }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {coursesList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.course_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {/* ==================== 2nd Row ============ */}
          <div className="input_field">
            <UniversityDescription />
            <div className="app_upload">
              <div>
                <p className="common_header">
                  Upload SOP |<span>upload SOP here</span>
                </p>
                <FileUpload
                  type="file"
                  location="documents/application_doc"
                  count={1}
                  imageUpload={sop_doc}
                  setImageUpload={setUpDocSOP}
                  accept="application/pdf"
                  prefix="sop"
                />
              </div>

              {universityById.length !== 0 &&
                universityById.paperbase_form === 1 && (
                  <div>
                    <p className="common_header">
                      Paperbase Form |<span>upload paperbase</span>
                    </p>
                    <FileUpload
                      type="file"
                      location="documents/application_doc"
                      count={1}
                      imageUpload={paperbase_doc}
                      setImageUpload={setUpDocPaperbase}
                      accept="application/pdf"
                      prefix="paperbase"
                    />
                  </div>
                )}
            </div>
            <br />
            {DownloadFile.length > 0 && (
              <div>
                <p className="common_header">
                  Download File |
                  <span>Fill the form and upload it next step</span>
                </p>
                {DownloadFile.map(
                  (item, index) =>
                    item.country.includes(postData.country_id) && (
                      <DownloadItem
                        key={index}
                        name={item.name}
                        url={item.url}
                      />
                    ) 
                )}
              </div>
            )}
          </div>
        </div>
        <div className="left_btn">
          {typeOpz !== "edit" && (
            <Button
              className="default__btn"
              onClick={() => clearApplications()}
            >
              Clear Applications
            </Button>
          )}
          &nbsp;&nbsp;
          <Button className="default__btn" onClick={() => clearForm()}>
            Clear Form
          </Button>
          &nbsp;&nbsp;
          <Button className="default__btn" onClick={() => addNewOne()}>
            Add Another Application
          </Button>
          &nbsp;&nbsp;
          <Button type="primary" onClick={() => submitForm()}>
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};
