import { Button, Checkbox, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useCountries from "../../hooks/useCountries";
import useCourseLevels from "../../hooks/useCourseLevels";
import useRequiredDocuments from "../../hooks/useRequiredDocuments";
import useSubjects from "../../hooks/useSubjects";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import { apis } from "../../properties";
import FileUpload from "../../util/FileUpload";
import { IntakesInputs } from "./IntakesInputs";
import { RequirementsInputs } from "./RequirementsInputs";

const { Option } = Select;

function AddCourses() {
  const [form] = Form.useForm();
  const location = useLocation();

  useBreadCrumb("Create Courses", location.pathname, "", "add");

  const [postData, setPostData] = useState({
    country_id: location.state?.country_id || "all",
    required_documents: [],
  });

  const [courseImage, setCourseImage] = useState();
  const [currency, setCurrency] = useState("AUD");

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const countries = useCountries(filterDataOther);
  const filteredUniversities = useUniversityByCountry(postData.country_id);
  const courseLevels = useCourseLevels(filterDataOther);
  const subjects = useSubjects(filterDataOther);
  const requiredDocuments = useRequiredDocuments(filterDataOther);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  useEffect(() => {
    form.setFieldsValue({
      country_id: location.state?.country_id,
      uni_id: location.state?.uid,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requiredDocuments.length !== 0) {
      var arr = [];
      requiredDocuments.forEach((element) => {
        arr.push(element.name);
      });
      setPostData({
        ...postData,
        required_documents: arr,
      });
    }
    // eslint-disable-next-line
  }, [requiredDocuments]);

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
        course_image: courseImage ? courseImage : null,
        required_documents: postData.required_documents,
        currency: currency,
        course_fee: parseFloat(values.course_fee).toFixed(2),
        entry_requirements: values.entry_requirements,
        course_description: values.course_description,
      };

      PostDataAuth(apis.CREATE_COURSES, formData).then((result) => {
        console.log("formData", formData);

        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          // setPostData({});
          setCourseImage();
          form.resetFields();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const addServiceList = (event) => {
    let courseRequests = postData.required_documents;
    if (courseRequests !== undefined) {
      if (courseRequests.includes(event.target.value)) {
        courseRequests.splice(courseRequests.indexOf(event.target.value), 1);
      } else {
        courseRequests.push(event.target.value);
      }
    }

    postData.required_documents = courseRequests;
    setPostData({ ...postData, required_documents: courseRequests });
  };

  const onChangeCountry = (value, index) => {
    setPostData({ ...postData, country_id: value });
    setCurrency(countries[index.key].currency);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Create Courses |<span>create new course</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                label="Course Name"
                name="course_name"
                rules={[
                  {
                    required: true,
                    message: "Course name cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="Course name" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country_id"
                required
                rules={[
                  {
                    required: true,
                    message: "Country cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Country"
                  onChange={(value, index) => onChangeCountry(value, index)}
                >
                  {countries.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="University"
                name="uni_id"
                required
                rules={[
                  {
                    required: true,
                    message: "University cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select University"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {filteredUniversities.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Course Level"
                name="course_level_id"
                required
                rules={[
                  {
                    required: true,
                    message: "course Level cannot be empty!",
                  },
                ]}
              >
                <Select size="large" placeholder="Select Course Level">
                  {courseLevels.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.level}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="subject_id"
                label="Subject"
                required
                rules={[
                  {
                    required: true,
                    message: "Subject cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Subject"
                  mode="multiple"
                  allowClear
                >
                  {subjects.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="course_fee"
                label="Course Fee"
                rules={[
                  {
                    required: true,
                    message: "Course Fee cannot be empty!",
                  },
                  {
                    pattern: new RegExp(/^\d+(\.\d{1,2})?$/),
                    message: "Course Fee must be a number!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  addonAfter={currency}
                  placeholder="Course Fee"
                />
              </Form.Item>
              <Form.Item name="entry_requirements" label="Entry Requirements">
                <Input
                  type="text"
                  size="large"
                  placeholder="Entry Requirements"
                />
              </Form.Item>
              <Form.Item
                name="course_description"
                label="Course Description"
                rules={[
                  {
                    required: true,
                    message: "Course description cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Course Description"
                />
              </Form.Item>
              <div className="rg_box">
                <p className="common_header">
                  Document Requirements |
                  <span>select requirements documents</span>
                </p>
                {requiredDocuments.map((item, index) => (
                  <div key={index} className="check_list">
                    <Checkbox
                      checked={
                        postData.required_documents.includes(item.name)
                          ? true
                          : false
                      }
                      value={item.name}
                      onChange={(event) => addServiceList(event)}
                    >
                      {item.name}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
            <div className="input_field">
              <div className="rg_box">
                <p className="common_header">
                  Intakes |<span> select intakes</span>
                </p>
                <IntakesInputs />
              </div>
              <div className="rg_box">
                <p className="common_header">
                  Course Requirements |<span> enter course requirements</span>
                </p>
                <RequirementsInputs />
              </div>
              <div className="rg_box">
                <p className="common_header">
                  Course Image |<span>upload course image</span>
                </p>
                <FileUpload
                  type="image"
                  location="images/courses_images"
                  count={1}
                  imageUpload={courseImage}
                  setImageUpload={setCourseImage}
                  accept="image/png, image/jpeg"
                  prefix="course_image"
                />
              </div>
              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddCourses;
