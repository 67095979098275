import React from "react";

const PersonalInfo = ({ data }) => {
  return (
    <div>
      <p className="common_header">
        Personal Details |<span>student personal details</span>
      </p>
      <div className="view_box_body">
        <div className="view_row">
          <p className="type">Name</p>
          <p className="data">
            {data.first_name +
              " " +
              (data.middle_name ? data.middle_name : "") +
              " " +
              (data.last_name ? data.last_name : "")}
          </p>
        </div>
        <div className="view_row">
          <p className="type">Email</p>
          <p className="data">{data.email}</p>
        </div>
        <div className="view_row">
          <p className="type">Phone</p>
          <p className="data">{data.phone ? data.phone : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">First Language</p>
          <p className="data">{data.first_language ? data.first_language : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Date Of Birth</p>
          <p className="data">{data.dob ? data.dob : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Country Citizenship</p>
          <p className="data">{data.country_citizen ? data.country_citizen : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Passport Number</p>
          <p className="data">{data.passport_number ? data.passport_number : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Gender</p>
          <p className="data">{data.gender ? data.gender : "-"}</p>
        </div>
        <div className="view_row">
          <p className="type">Marital Status</p>
          <p className="data">{data.marital_status ? data.marital_status : "-"}</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
