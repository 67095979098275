import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import AddSubjects from "./AddSubjects";
import SubjectsTable from "./SubjectsTable";

export default function SubjectManage() {
  const location = useLocation();
  useBreadCrumb("Manage Subjects", location.pathname, "", "add");
  const [visible, setVisible] = useState(false);

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button className="primary__btn" onClick={() => setVisible(true)}>
            Add New Subject
          </Button>
        </div>
        <SubjectsTable />
      </div>
      {visible && (
        <AddSubjects
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
          type="add"
          selectId={0}
        />
      )}
    </div>
  );
}
