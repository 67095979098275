import { Button, Divider, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import PackageDataTable from "./PackageDataTable";
import useUniversityByCountry from "../../../hooks/useUniversityByCountry";
import useCountries from "../../../hooks/useCountries";
import { PostDataAuth } from "../../../apiService/PostData";
import { apis } from "../../../properties";
import Progress from "react-progress-2";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const AddPackages = () => {
  const location = useLocation();
  useBreadCrumb("Add Package", location.pathname, "", "add");

  const [form] = Form.useForm();
  const [currency, setCurrency] = useState("USD");
  const [directUniList, setDirectUniList] = useState([]);
  const [partnersUniList, setPartnersUniList] = useState([]);
  const [countryId, setCountryId] = useState();

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });
  const countries = useCountries(filterDataOther);
  const filteredUniversities = useUniversityByCountry(countryId);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      const formData = {
        ...values,
        country_id: countryId,
        currency: currency,
        direct_universities: directUniList,
        partner_universities: partnersUniList,
      };
      Progress.show();
      PostDataAuth(apis.AGENT_PACKAGE, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          setDirectUniList([]);
          setPartnersUniList([]);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const onChangeCountry = (value, index) => {
    setCountryId(value);
    setCurrency(countries[index.key].currency);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <div className="top_row">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Save Package
            </Button>
          </div>

          <Form.Item
            label="Package Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Package title" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country_id"
            required
            rules={[
              {
                required: true,
                message: "Country cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Select Country"
              allowClear
              onChange={(value, index) => onChangeCountry(value, index)}
            >
              {countries.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Divider orientation="left" plain>
          Direct Universities
        </Divider>
        <PackageDataTable
          filteredUniversities={filteredUniversities ?? []}
          currency={currency}
          data={directUniList}
          setData={setDirectUniList}
        />
        <Divider orientation="left" plain>
          Partner Universities
        </Divider>
        <PackageDataTable
          filteredUniversities={filteredUniversities ?? []}
          currency={currency}
          data={partnersUniList}
          setData={setPartnersUniList}
        />
      </div>
    </div>
  );
};

export default AddPackages;
