import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EventDataTable from "../components/Event/EventDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";

export default function Events() {
  const location = useLocation();
  const navigate = useNavigate();

  useBreadCrumb("Event Management", location.pathname, "Event Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/event-management/create")}
          >
            Add Event
          </Button>
        </div>
        <EventDataTable />
      </div>
    </div>
  );
}
