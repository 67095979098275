import { Button, Form, message, Modal, Select } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import useCourseIntakeList from "../../hooks/useCourseIntakeList";
import { useStore } from "../../store";

const { Option } = Select;

export const IntakesModel = ({ visible, setVisible, courseData }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const setActiveRoute = useStore((state) => state.setActiveRoute);

  const allUserList = useCourseIntakeList(courseData.course_id);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      var intakeData = allUserList[values.intake_id]
      navigate("/enquiries/create", {
        state: {
          uid: courseData.uid,
          country_id: courseData.country_id,
          course_id: courseData.course_id,
          course_level_id: courseData.course_level_id,
          intake_month: intakeData.month_id,
          intake_year: intakeData.year_id,
        },
      });
      setActiveRoute("enquiries");
      setVisible(false);
    });
  };

  return (
    <Modal
      title="Select Intake"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="intake_id"
            required
            rules={[
              {
                required: true,
                message: "Intake cannot be empty!",
              },
            ]}
          >
            <Select size="large" placeholder="Select Intake">
              {allUserList.map((item, index) => (
                <Option key={index} value={index}>
                  {item.intake_month + " " + item.year + "--(" + item.course_limit + ")"}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
