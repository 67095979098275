import { Button, DatePicker, Form, Input, message, Radio } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useEnquiryDataStore } from "../../store";

export const StepThree = ({ currentStep, setNextStep, type }) => {
  const [form] = Form.useForm();
  const [dob, setDob] = useState();

  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);

  useEffect(() => {
    if (enquiryData) {
      form.setFieldsValue({
        ...enquiryData,
        first_name: enquiryData.personalData.first_name,
        middle_name: enquiryData.personalData.middle_name,
        last_name: enquiryData.personalData.last_name,
        email: enquiryData.personalData.email,
        phone: enquiryData.personalData.phone,
        first_language: enquiryData.personalData.first_language,
        dob: enquiryData.personalData.dob
          ? moment(enquiryData.personalData.dob)
          : undefined,
        country_citizen: enquiryData.personalData.country_citizen,
        passport_number: enquiryData.personalData.passport_number,
        gender: enquiryData.personalData.gender,
        marital_status: enquiryData.personalData.marital_status,
      });
      setDob(
        enquiryData.personalData.dob ? enquiryData.personalData.dob : undefined
      );
    }
    // eslint-disable-next-line
  }, [enquiryData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };
  const submitForm = () => {
    
    form.validateFields().then((values) => {
      let postData = {
        ...values,
        dob: dob,
      };
      setEnquiryData({
        ...enquiryData,
        personalData: postData,
      });
      setNextStep(currentStep + 1);
    });
  };

  const prev = () => {
    setNextStep(currentStep - 1);
  };
  return (
    <div className="step_board">
      <p className="common_header">
        Personal Information |<span>enter personal details</span>
      </p>
      <Form
        autoComplete="off"
        form={form}
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <div className="row_2">
          <div className="input_field">
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "First Name cannot be empty!",
                },
              ]}
            >
              <Input size="large" placeholder="First Name" />
            </Form.Item>
            <Form.Item name="middle_name" label="Middle Name">
              <Input size="large" placeholder="Middle name" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Last Name cannot be empty!",
                },
              ]}
            >
              <Input size="large" placeholder="Last name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email cannot be empty!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Contact Number"
              rules={[
                {
                  required: true,
                  message: "Phone cannot be empty!",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please enter a valid phone number!",
                },
              ]}
            >
              <Input size="large" placeholder="Phone" />
            </Form.Item>
            <Form.Item name="first_language" label="First Language">
              <Input size="large" placeholder="First Language" />
            </Form.Item>
          </div>
          <div className="input_field">
            <Form.Item name="dob" label="Date Of Birth">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Date Of Birth"
                onChange={(date, dateString) => {
                  setDob(dateString);
                }}
              />
            </Form.Item>
            <Form.Item name="country_citizen" label="Country Citizenship">
              <Input size="large" placeholder="Country Citizenship" />
            </Form.Item>
            <Form.Item
              name="passport_number"
              label="Passport Number"
              rules={[
                {
                  required: true,
                  message: "Passport Number cannot be empty!",
                },
              ]}
            >
              <Input size="large" placeholder="Passport Number" />
            </Form.Item>
            <div className="rg_box">
              <p className="common_header">Gender</p>
              <Form.Item name="gender" noStyle>
                <Radio.Group>
                  <Radio value="Male">Male</Radio>
                  <Radio value="Female">Female</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="rg_box">
              <p className="common_header">Marital Status</p>
              <Form.Item name="marital_status" noStyle>
                <Radio.Group>
                  <Radio value="Married">Married</Radio>
                  <Radio value="Unmarried">Unmarried</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="left_btn">
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>

          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};
