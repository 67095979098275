import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select } from "antd";
import React, { useState } from "react";
import useCountries from "../../hooks/useCountries";
const { Option } = Select;
const { Panel } = Collapse;

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();
  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });
  const countries = useCountries(filterDataOther);

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      country_id: values.country_id ? values.country_id : "all",
      name: values.name ? values.name : "",
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      country_id: "all",
      name: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="name" noStyle>
              <Input placeholder="University Name" allowClear />
            </Form.Item>
            <Form.Item name="country_id" noStyle>
              <Select placeholder="Select Country" style={{ width: "100%" }} allowClear>
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
