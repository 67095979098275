export const DownloadFile = [
  {
    name: "Student Declaration Form",
    url: "download/Files/Student_Declaration_Form.pdf",
    country: [13, 38, 157, 231, 230],
  },
  {
    name: "Pre application form- Australia",
    url: "download/Files/Updated_pre_appliation_form.docx",
    country: [13],
  },
  {
    name: "Form of Sponsor Details of the student",
    url: "download/Files/Sponsor_Details_of_the_Student.docx",
    country: [13],
  },
];
