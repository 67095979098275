import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowsTable from "../components/Workflows/WorkflowsTable";
import useBreadCrumb from "../hooks/useBreadCrumb";

export default function Workflows() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Workflows", location.pathname, "Workflows");
 

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/workflows/create")}
          >
            Add Workflow
          </Button>
        </div>
        <WorkflowsTable />
      </div>
    </div>
  );
}
