import { Button, Form, message, Modal, Select } from "antd";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../../apiService/GetData";
import useAllCountries from "../../../hooks/useAllCountries";
import { apis } from "../../../properties";
import { useRefreshTable } from "../../../store";
const { Option } = Select;

export default function AddCountryManagement({ visible, setVisible }) {
  const [form] = Form.useForm();

  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const [filterData] = useState({
    status: "all",
  });

  const countries = useAllCountries(filterData);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      GetDataAuth(`${apis.COUNTRY_CHANGE_STATUS}/${values.country_id}`).then(
        (result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            form.resetFields();
            setRefreshTable(!refreshTable);
            setVisible(false);
            message.success(responseJson.message);
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        }
      );
    });
  };

  return (
    <Modal
      title="Add Country"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
    >
      <div className="step_model_body">
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="country_id"
            rules={[
              {
                required: true,
                message: "Country cannot be empty!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Select Country"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {countries.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
