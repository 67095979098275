import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable } from "../../store";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import useAllEvents from "../../hooks/useAllEvents";
import { PostDataAuth } from "../../apiService/PostData";
import StatusTag from "../../util/StatusTag";
import { GetDataAuth } from "../../apiService/GetData";

const { confirm } = Modal;

function EventDataTable() {
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    dataTable: true,
  });

  const allEvents = useAllEvents(filterData);

  const columns = [
    {
      title: "Image",
      dataIndex: "cover_url",
      key: "cover_url",
      render: (text) => (
        <img
          src={IMAGE_PATH + "/" + text}
          alt="event"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      ),
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Country Code",
      dataIndex: "country_code",
      width: 50,
    },
    {
      title: "Description",
      render: (record) => parse(record.description.substring(0, 120) + "..."),
      width: 200,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_date
          ? moment(record.created_date).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 100,
    },
    {
      title: "Status",
      render: (record) => <StatusTag status={record.status} />,
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Event">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => navigate(`/event-management/edit/${record.slug}`)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Unpublished Event">
            <Button
              className="delete_button"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => changeStatusContent(record.slug, "UNPUBLISHED")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Published Event">
            <Button
              className="view_button"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => changeStatusContent(record.slug, "PUBLISHED")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete Event">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteStatusContent(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  const changeStatusContent = (slug, status) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${status} this event?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(`${apis.EVENT_STATUS_CHANGE}/${slug}`, {
          status,
        }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteStatusContent = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this event? You can't undo this action`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.EVENT_DELETE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <>
      <Table
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={allEvents.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allEvents.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default EventDataTable;
