import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Select } from "antd";
import React from "react";
import useUserTypes from "../../../hooks/useUserTypes";
import usePagePrivileges from "../../../hooks/usePagePrivileges";
const { Option } = Select;
const { Panel } = Collapse;

export const FilterRow = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();

  const userTypes = useUserTypes();
  const pagePrivileges = usePagePrivileges();

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      page_key: values.page_key ? values.page_key : "",
      role: values.role ? values.role : "",
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      role: "",
      page_key: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="page_key" noStyle>
              <Select
                placeholder="Select page key"
                style={{ width: "100%" }}
                allowClear
              >
                {pagePrivileges.map((item, index) => (
                  <Option key={index} value={item.page}>
                    {item.page}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="role" noStyle>
              <Select
                placeholder="Select user role"
                style={{ width: "100%" }}
                allowClear
              >
                {userTypes.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
