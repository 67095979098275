import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import AddCorseLevel from "./AddCorseLevel";
import CorseLevelTable from "./CorseLevelTable";

export default function CorsesLevel() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  useBreadCrumb("Corses Level", location.pathname, "", "add");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button className="primary__btn" onClick={() => setVisible(true)}>Add New Corse Level</Button>
        </div>
        <CorseLevelTable />
      </div>
      {visible && (
        <AddCorseLevel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
          type="add"
          selectId={0}
        />
      )}
    </div>
  );
}
