import React, { useState } from "react";
import StatusTag from "../../util/StatusTag";
import useAllBlogs from "../../hooks/useAllBlogs";
import moment from "moment";
import Progress from "react-progress-2";
import parse from "html-react-parser";
import { IMAGE_PATH, apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Modal, Table, Tooltip } from "antd";
import { PutDataAuth } from "../../apiService/PostData";
import { DeleteDataAuth } from "../../apiService/GetData";

const { confirm } = Modal;

function BlogDataTable() {
  const navigate = useNavigate();
  const { refreshTable, setRefreshTable } = useRefreshTable();
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const allBlogs = useAllBlogs(filterData);

  const columns = [
    {
      title: "Image",
      align: "center",
      dataIndex: "image_path",
      render: (image_path) => (
        <div className="table_av">
          {image_path ? (
            <Avatar src={`${IMAGE_PATH}/${image_path}`} />
          ) : (
            <Avatar
              style={{ color: "#234caa", backgroundColor: "#06297a30" }}
              icon={<PictureOutlined />}
            />
          )}
        </div>
      ),
      width: 40,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 50,
    },
    {
      title: "Description",
      render: (record) =>
        parse(record.blog_description.substring(0, 120) + "..."),
      width: 200,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.createdAt ? moment(record.createdAt).format("YYYY-MM-DD") : "",
      width: 50,
    },
    {
      title: "Status",
      render: (record) => <StatusTag status={record.status} />,
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Blog">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => navigate(`/blog-management/edit/${record.slug}`)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record.status === 7 ? (
            <Tooltip placement="bottom" title="Unpublished Blog">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => changeStatusContent(record.slug, 8)}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="PUBLISHED Blog">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => changeStatusContent(record.slug, 7)}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Permanently Delete Blog">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => changeDeleteContent(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  const changeStatusContent = (slug, status) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${status} this Blog item?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PutDataAuth(`${apis.BLOGS}/${slug}`, {
          status: status,
        }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeDeleteContent = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this Blog item?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        DeleteDataAuth(`${apis.BLOGS}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  return (
    <>
      <Table
        columns={columns}
        dataSource={allBlogs.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allBlogs.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default BlogDataTable;
