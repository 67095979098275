import { Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useRemarkList from "../../../hooks/useRemarkList";
import { IMAGE_PATH } from "../../../properties";

const RemarkInfo = ({ application_id }) => {
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    dataTable: true,
    application_id: application_id,
  });
  const coursesList = useRemarkList(filterData);

  const columns = [
    {
      title: "Created Date",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
    },
    {
      title: "Created By",
      sorter: (a, b) => a.added_fname.length - b.added_fname.length,
      render: (record) => (
        <p>
          {record.added_fname +
            " " +
            (record.added_mname ? record.added_mname : "") +
            " " +
            (record.added_lname ? record.added_lname : "")}
        </p>
      ),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Role",
      sorter: (a, b) => a.user_type_name.length - b.user_type_name.length,
      render: (record) => <p>{record.user_type_name}</p>,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Remark",
      sorter: (a, b) => a.remark.length - b.remark.length,
      render: (record) => <p>{record.remark}</p>,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Documents",
      sorter: (a, b) => a.remark.length - b.remark.length,
      render: (record) =>
        record.up_document ? (
          <a
            href={`${IMAGE_PATH}/${record.up_document}`}
            target="_blank"
            rel="noreferrer"
            className="view_doc_btn"
          >
            View Document
            <i class="bi bi-box-arrow-up-right"></i>
          </a>
        ) : (
          <p>No</p>
        ),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      render: (record) => (
        <Tag className="tags" color={record.status_color}>
          {record.status}
        </Tag>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
      fixed: "right",
    },
  ];
  return (
    <div>
      <p className="common_header">
        Remark History |<span>view all the remarks</span>
      </p>
      <div className="view_box_body">
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={coursesList.data}
          bordered
          pagination={{
            current: filterData.current_page,
            pageSize: filterData.per_page,
            total: coursesList.total,
            onChange: (page, pageSize) => {
              setFilterData({
                ...filterData,
                page: page,
                pageSize: pageSize,
              });
            },
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </div>
    </div>
  );
};

export default RemarkInfo;
