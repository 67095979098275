import { FilterOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input } from "antd";
import React from "react";
const { Panel } = Collapse;

export const FilterRowInvite = ({ filterData, setFilterData }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setFilterData({
      ...filterData,
      email: values.email ? values.email : "",
    });
  };

  const clearData = () => {
    setFilterData({
      ...filterData,
      email: "",
    });
    form.resetFields();
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <FilterOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel header="Filter">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <div className="row">
            <Form.Item name="email" noStyle>
              <Input placeholder="Email" />
            </Form.Item>
          </div>
          <div className="left_btn">
            <Button className="default__btn" onClick={() => clearData()}>
              Clear
            </Button>
            &nbsp;&nbsp;
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
