import { Popover, Steps } from "antd";
import moment from "moment";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { StepFive } from "../components/Enquiry/StepFive";
import { StepFour } from "../components/Enquiry/StepFour";
import { StepOne } from "../components/Enquiry/StepOne";
import { StepSix } from "../components/Enquiry/StepSix";
import { StepThree } from "../components/Enquiry/StepThree";
import { StepTwo } from "../components/Enquiry/StepTwo";
import useBreadCrumb from "../hooks/useBreadCrumb";

const { Step } = Steps;

export default function ReferralPage() {
  const { id } = useParams();
  const location = useLocation();
  useBreadCrumb("Add Enquiry", location.pathname, "", "add");
  const [currentStep, setCurrentStep] = React.useState(0);

  const onChange = (currentStep) => {
    setCurrentStep(currentStep);
  };

  const steps = [
    {
      title: "Apply for Course",
      content: (
        <StepOne
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          typeOpz="new"
        />
      ),
    },
    {
      title: "Upload Documents",
      content: (
        <StepTwo
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "Personal Information",
      content: (
        <StepThree
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "Address Details",
      content: (
        <StepFour
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "Education Summary",
      content: (
        <StepFive
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"new"}
        />
      ),
    },
    {
      title: "IELTS / PTE Details",
      content: (
        <StepSix
          currentStep={currentStep}
          setNextStep={setCurrentStep}
          type={"referral"}
          finalStep={true}
          refId={id}
        />
      ),
    },
  ];

  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
      className="step-popover"
    >
      {index + 1}
    </Popover>
  );
  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Create Enquiry |<span>create new enquiry</span>
        </p>
        <Steps
          size="small"
          progressDot={customDot}
          current={currentStep}
          onChange={onChange}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
      </div>
      <div className="footer_ref">
        <p> &copy; {moment().year()} Campus Direct. All rights reserved.</p>
      </div>
    </div>
  );
}
