import { Button, Form, Input, message, Radio, Select } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import useAllCountries from "../../hooks/useAllCountries";
import useStates from "../../hooks/useStates";
import useCities from "../../hooks/useCities";
import FileUpload from "../../util/FileUpload";
import useUserTypes from "../../hooks/useUserTypes";
import useAllUserListAgents from "../../hooks/useAllUserListAgents";
import { useStore } from "../../store";

const { Option } = Select;

function AddUser() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();

  useBreadCrumb("Create user", location.pathname, "", "add");

  const [selectAdmin, setSelectAdmin] = useState(false);
  const [postData, setPostData] = useState({});
  const [upDocBR, setUpDocBR] = useState();
  const [filterDataOther] = useState({
    status: "all",
  });
  const countries = useAllCountries(filterDataOther);
  const states = useStates(postData.country_id ? postData.country_id : 0);
  const cities = useCities(postData.state_id ? postData.state_id : 0);
  const userTypes = useUserTypes({ status: 1 });
  const allUserList = useAllUserListAgents();
  const profileData = useStore((state) => state.profileData);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
        br_doc: upDocBR,
        admin_agent_id: values.admin_agent_id ? values.admin_agent_id : null,
        created_by: profileData.id,
        c_role_id: profileData.role_id,
      };
      PostDataAuth(apis.USER_REGISTER, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          navigate("/user-management");
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Create users |<span>create new user</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First name cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="Your first name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: "Last name cannot be empty!" },
                ]}
              >
                <Input type="text" size="large" placeholder="Your last name" />
              </Form.Item>
              <Form.Item name="company_name">
                <Input type="text" size="large" placeholder="Company Name" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Contact number cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Your contact number"
                />
              </Form.Item>
              <Form.Item name="email">
                <Input type="text" size="large" placeholder="Your Email name" />
              </Form.Item>
              <Form.Item
                name="role_id"
                required
                rules={[
                  {
                    required: true,
                    message: "User type cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select user role"
                  onChange={(value) => {
                    setPostData({ ...postData, role_id: value });
                    setSelectAdmin(value === 9 ? true : false);
                  }}
                >
                  {userTypes.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectAdmin && (
                <Form.Item
                  name="admin_agent_id"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Agent admin name cannot be empty!",
                    },
                  ]}
                >
                  <Select size="large" placeholder="Select an agent admin">
                    {allUserList.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.first_name} {item.last_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Password cannot be empty!" },
                ]}
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>
              <Form.Item name="address">
                <Input type="text" size="large" placeholder="Your address" />
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item
                name="country_id"
                rules={[
                  {
                    required: true,
                    message: "Country cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Select user country"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    setPostData({ ...postData, country_id: value });
                  }}
                >
                  {countries.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="state_id"
                rules={[
                  {
                    required: true,
                    message: "State cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select user state"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    setPostData({ ...postData, state_id: value });
                  }}
                >
                  {states.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="city_id"
                rules={[
                  {
                    required: true,
                    message: "city cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select user city"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    setPostData({ ...postData, city_id: value });
                  }}
                >
                  {cities.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {postData.role_id === 3 && (
                <>
                  <div className="rg_box">
                    <p className="common_header">
                      Business Type |<span>select your business type</span>
                    </p>
                    <Form.Item name="agent_type">
                      <Radio.Group>
                        <Radio value="Individual">Individual</Radio>
                        <Radio value="Company">Company</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="rg_box">
                    <p className="common_header">
                      Upload BR |<span>Upload your business registration</span>
                    </p>
                    <FileUpload
                      type="file"
                      location="documents/business_registration"
                      count={1}
                      imageUpload={upDocBR}
                      setImageUpload={setUpDocBR}
                      accept="application/pdf"
                      prefix="br"
                    />
                  </div>
                </>
              )}

              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddUser;
