import { Button, Divider, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useCountries from "../../hooks/useCountries";
import useCoursesList from "../../hooks/useCoursesList";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import { apis } from "../../properties";
import { DatePicker } from "antd";
import useScholarshipTypes from "../../hooks/useScholarshipTypes";
import useScholarshipById from "../../hooks/useScholarshipById";
import moment from "moment";
import FileUpload from "../../util/FileUpload";

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";

function EditScholarship() {
  const [form] = Form.useForm();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useBreadCrumb("Edit Scholarship", location.pathname, "", "add");

  const scholarshipById = useScholarshipById(params.id);

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const [validTime, setValidTime] = useState();
  const [disableCourse, setDisableCourse] = useState(false);
  const [currency, setCurrency] = useState("AUD");
  const [bannerImage, setBannerImage] = useState();
  const [coverImage, setCoverImage] = useState();
  const [courseFilter, setCourseFilter] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    country_id: "",
    dataTable: false,
    uni_id: "",
  });

  const countries = useCountries(filterDataOther);
  const scholarshipTypes = useScholarshipTypes();
  const filteredUniversities = useUniversityByCountry(courseFilter.country_id);
  const coursesList = useCoursesList(courseFilter);

  useEffect(() => {
    if (scholarshipById.length !== 0) {
      form.setFieldsValue({
        ...scholarshipById,
        start_date: [
          moment(scholarshipById.start_date),
          moment(scholarshipById.end_date),
        ],
      });
      setCourseFilter({
        ...courseFilter,
        country_id: scholarshipById.country_id,
        uni_id: scholarshipById.uni_id,
      });
      setValidTime([
        moment(scholarshipById.start_date),
        moment(scholarshipById.end_date),
      ]);
      setDisableCourse(true);
      setCurrency(scholarshipById.currency);
      setBannerImage(scholarshipById.banner_url);
      setCoverImage(scholarshipById.cover_url);
    }
    // eslint-disable-next-line
  }, [scholarshipById]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const formData = {
        ...form.getFieldsValue(),
        start_date: validTime[0],
        end_date: validTime[1],
        currency: currency,
        id: params.id,
        banner_url: bannerImage,
        cover_url: coverImage,
      };

      PostDataAuth(apis.UPDATE_SCHOLARSHIP, formData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          navigate("/scholarship");
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Edit Scholarship |<span>edit scholarship</span>
        </p>
        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="row_2">
            <div className="input_field">
              <Form.Item
                name="title"
                label="Scholarship Title"
                rules={[
                  {
                    required: true,
                    message: "Scholarship Title cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Scholarship Title"
                />
              </Form.Item>
              <Form.Item
                label="Scholarship Slug"
                name="slug"
                rules={[{ required: true, message: "Slug cannot be empty!" }]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="slug"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="scholarship_type_id"
                label="Scholarship Type"
                rules={[
                  {
                    required: true,
                    message: "Scholarship Type cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Scholarship Type"
                  onChange={(value) => {
                    form.setFieldsValue({
                      course_id: null,
                    });
                    setDisableCourse(value === 1 ? true : false);
                  }}
                >
                  {scholarshipTypes.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="country_id"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Country cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Country"
                  onChange={(value, index) => {
                    setCourseFilter({ ...courseFilter, country_id: value });
                    setCurrency(countries[index.key].sortname);
                  }}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {countries.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="uni_id"
                label="University"
                rules={[
                  {
                    required: true,
                    message: "University cannot be empty!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select University"
                  onChange={(value) => {
                    setCourseFilter({ ...courseFilter, uni_id: value });
                  }}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {filteredUniversities.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="course_id" label="Course">
                <Select
                  size="large"
                  placeholder="Select Course"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  disabled={disableCourse}
                >
                  {coursesList.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.course_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="quantity"
                label="Scholarship Quantity"
                rules={[
                  {
                    required: true,
                    message: "Scholarship quantity cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Scholarship quantity"
                />
              </Form.Item>
              <Form.Item
                name="amount"
                label="Scholarship Amount"
                rules={[
                  {
                    required: true,
                    message: "Scholarship amount cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Scholarship amount"
                  addonAfter={currency}
                />
              </Form.Item>
              <Form.Item
                name="start_date"
                label="Scholarship Valid Time"
                rules={[
                  {
                    required: true,
                    message: "Scholarship valid time cannot be empty!",
                  },
                ]}
              >
                <RangePicker
                  size="large"
                  style={{ width: "100%" }}
                  format={dateFormat}
                  onChange={(date, dateString) => {
                    setValidTime(dateString);
                  }}
                />
              </Form.Item>
              <Divider orientation="left" orientationMargin="0">
                SEO Details
              </Divider>
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "Meta Title cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="Meta Title" />
              </Form.Item>

              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "Meta Description cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Meta Description"
                />
              </Form.Item>

              <Form.Item
                label="Meta Keywords"
                name="meta_keywords"
                rules={[
                  {
                    required: true,
                    message: "Meta Keywords cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="Meta Keywords" />
              </Form.Item>
            </div>
            <div className="input_field">
              <Form.Item
                name="banner_image_url"
                label="Banner Image (Image Size: 1920x400px)"
                required
              >
                <FileUpload
                  type="image"
                  location="scholarship"
                  count={1}
                  imageUpload={bannerImage}
                  setImageUpload={setBannerImage}
                  accept=".png, .jpg, .jpeg"
                  prefix="scholarship_banner"
                />
              </Form.Item>
              <Form.Item
                name="banner_image_url"
                label="Tile Image (Image Size: 300x300px)"
                required
              >
                <FileUpload
                  type="image"
                  location="scholarship"
                  count={1}
                  imageUpload={coverImage}
                  setImageUpload={setCoverImage}
                  accept=".png, .jpg, .jpeg"
                  prefix="scholarship_tile"
                />
              </Form.Item>
              <div className="left_btn">
                <Button
                  type="primary"
                  className="primary__btn"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditScholarship;
