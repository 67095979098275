import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import useCities from "../../hooks/useCities";
import useAllCountries from "../../hooks/useAllCountries";
import useStates from "../../hooks/useStates";
import { useEnquiryDataStore } from "../../store";
const { Option } = Select;
const { TextArea } = Input;

export const StepFour = ({ currentStep, setNextStep, type }) => {
  const [form] = Form.useForm();
  const [postData, setPostData] = useState({});
  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);
  const [filterDataOther] = useState({
    status: 'all',
  });
  const countries = useAllCountries(filterDataOther);
  const states = useStates(postData.country_id ? postData.country_id : 0);
  const cities = useCities(postData.state_id ? postData.state_id : 0);

  useEffect(() => {
    if (enquiryData) {
      form.setFieldsValue({
        ...enquiryData,
        address: enquiryData.addressData.address,
        city_id: enquiryData.addressData.city_id,
        country_id: enquiryData.addressData.country_id,
        postal_code: enquiryData.addressData.postal_code,
        state_id: enquiryData.addressData.state_id,
      });
      setPostData({
        country_id: enquiryData.addressData.country_id,
        state_id: enquiryData.addressData.state_id,
      });
    }
    // eslint-disable-next-line
  }, [enquiryData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };
  const submitForm = () => {
    form.validateFields().then((values) => {
      let postValues = {
        ...postData,
        ...values,
      };
      setEnquiryData({
        ...enquiryData,
        addressData: postValues,
      });
      setNextStep(currentStep + 1);
    });
  };

  const prev = () => {
    setNextStep(currentStep - 1);
  };
  return (
    <div className="step_board">
      <p className="common_header">
        Address Detail |<span>enter address details</span>
      </p>
      <Form
        autoComplete="off"
        form={form}
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
        layout="vertical"
      >
        <div className="row_2">
          <div className="input_field">
            <Form.Item name="address"  label="Address">
              <TextArea rows={7} placeholder="Type address here" />
            </Form.Item>
          </div>
          <div className="input_field">
            <Form.Item
              name="country_id"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select user country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setPostData({ ...postData, country_id: value });
                }}
              >
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="state_id"
              label="State"
              rules={[
                {
                  required: true,
                  message: "State cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select user state"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setPostData({ ...postData, state_id: value });
                }}
              >
                {states.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="city_id"
              label="City"
              rules={[
                {
                  required: true,
                  message: "city cannot be empty!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select user city"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value) => {
                  setPostData({ ...postData, city_id: value });
                }}
              >
                {cities.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="postal_code" label="Postal Code">
              <Input size="large" placeholder="Postal code" />
            </Form.Item>
          </div>
        </div>
        <div className="left_btn">
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>

          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};
