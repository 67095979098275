import { DeleteOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Select, message } from "antd";
import React, { useState } from "react";
import useCountries from "../../hooks/useCountries";
import useCourseLevels from "../../hooks/useCourseLevels";
import useCoursesList from "../../hooks/useCoursesList";
import useIntakeMonths from "../../hooks/useIntakeMonths";
import useIntakeYears from "../../hooks/useIntakeYears";
import useUniversityByCountry from "../../hooks/useUniversityByCountry";
import useUniversityById from "../../hooks/useUniversityById";
import { IMAGE_PATH } from "../../properties";
import { useEnquiryDataStore } from "../../store";
import FileUpload from "../../util/FileUpload";
import DownloadItem from "../atoms/DownloadItem";
import { DownloadFile } from "./FileList";

const { Panel } = Collapse;
const { Option } = Select;

const ApplicationList = ({ applicationList, setApplicationList, typeOpz }) => {
  console.log(applicationList);
  const [form] = Form.useForm();

  const [postData, setPostData] = useState({
    country_id: "all",
    uni_id: 0,
  });
  const [sop_doc, setSop_doc] = useState();
  const [paperbase_doc, setPaperbase_doc] = useState();
  const enquiryData = useEnquiryDataStore((state) => state.enquiryData);
  const setEnquiryData = useEnquiryDataStore((state) => state.setEnquiryData);

  const [courseFilter, setCourseFilter] = useState({
    page: 1,
    pageSize: 10,
    status: 1,
    country_id: "",
    dataTable: false,
    name: "",
    uni_id: "",
    course_level_id: "",
    intake_month_id: "",
    intake_year_id: "",
  });

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const countries = useCountries(filterDataOther);
  const filteredUniversities = useUniversityByCountry(postData.country_id);
  const intakeMonths = useIntakeMonths(filterDataOther);
  const intakeYears = useIntakeYears(filterDataOther);
  const courseLevels = useCourseLevels(filterDataOther);
  const coursesList = useCoursesList(courseFilter);
  const universityById = useUniversityById(postData.uni_id);

  const genExtra = (index) => (
    <DeleteOutlined
      onClick={(e) => {
        e.stopPropagation();
        let newApplicationList = [...applicationList];
        if (typeOpz === "new") {
          newApplicationList.splice(index, 1);
        } else {
          newApplicationList[index] = {
            ...form.getFieldsValue(),
            sop_doc: sop_doc,
            paperbase_doc: paperbase_doc,
            id: newApplicationList[index].id || 0,
            status: -1,
          };
        }
        setApplicationList(newApplicationList);

        let newEnquiryData = { ...enquiryData };
        newEnquiryData.applications = newApplicationList;
        setEnquiryData(newEnquiryData);
        message.success("Application Deleted");
      }}
    />
  );

  function callback(key) {
    setPostData({
      ...postData,
      uni_id: applicationList[key].uni_id,
      country_id: applicationList[key].country_id,
    });
    form.setFieldsValue({
      ...applicationList[key],
    });
    setCourseFilter({
      ...courseFilter,
      uni_id: applicationList[key].uni_id,
      country_id: applicationList[key].country_id,
      intake_month_id: applicationList[key].intake_month,
      intake_year_id: applicationList[key].intake_year,
      course_level_id: applicationList[key].course_level_id,
    });
    setSop_doc(applicationList[key].sop_doc);
    setPaperbase_doc(applicationList[key].paperbase_doc);
  }

  const updateForm = (index) => {
    let newApplicationList = [...applicationList];
    newApplicationList[index] = {
      ...form.getFieldsValue(),
      sop_doc: sop_doc,
      paperbase_doc: paperbase_doc,
      id: newApplicationList[index].id || 0,
    };
    setApplicationList(newApplicationList);

    let newEnquiryData = { ...enquiryData };
    newEnquiryData.applications = newApplicationList;
    setEnquiryData(newEnquiryData);
    message.success("Application updated successfully");
  };

  const UniversityDescription = () => {
    if (universityById.length !== 0) {
      const disArr = universityById.uni_desc.split("\n");
      return (
        <div className="uni_data_dox">
          <p className="common_header">
            University Description |<span>check this description</span>
          </p>
          <h3>{universityById.name}</h3>
          <ol>
            {disArr.map((item, index) => (
              <li key={index}>●&nbsp;{item}</li>
            ))}
          </ol>
          {universityById.paperbase_form === 1 && (
            <a
              href={`${IMAGE_PATH}/${universityById.paperbase_form_file}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">Download Paperbase Form</Button>
            </a>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <Collapse accordion onChange={callback} expandIconPosition="right">
      {applicationList.map(
        (item, index) =>
          item.status !== -1 && (
            <Panel
              header={`Application ${index + 1}`}
              key={index}
              extra={genExtra(index)}
            >
              <Form
                autoComplete="off"
                form={form}
                layout="horizontal"
                labelCol={{ span: 5 }}
              >
                <div className="row_2">
                  <div className="input_field">
                    <Form.Item name="country_id" label="Country">
                      <Select
                        size="large"
                        placeholder="Select Country"
                        onChange={(value) => {
                          setPostData({ ...postData, country_id: value });
                          setCourseFilter({
                            ...courseFilter,
                            country_id: value,
                          });
                        }}
                      >
                        {countries.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="uni_id"
                      label="University"
                      required
                      rules={[
                        {
                          required: true,
                          message: "University cannot be empty!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Select University"
                        onChange={(value) => {
                          setPostData({ ...postData, uni_id: value });
                          setCourseFilter({ ...courseFilter, uni_id: value });
                        }}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {filteredUniversities.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="intake_month"
                      label="Intake Month"
                      rules={[
                        {
                          required: true,
                          message: "Intake month cannot be empty!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Select Intake Month"
                        onChange={(value) => {
                          setPostData({ ...postData, intake_month: value });
                          setCourseFilter({
                            ...courseFilter,
                            intake_month_id: value,
                          });
                        }}
                      >
                        {intakeMonths.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.months}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="intake_year"
                      label="Intake Year"
                      rules={[
                        {
                          required: true,
                          message: "Intake year cannot be empty!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Select Intake Year"
                        onChange={(value) => {
                          setPostData({ ...postData, intake_year: value });
                          setCourseFilter({
                            ...courseFilter,
                            intake_year_id: value,
                          });
                        }}
                      >
                        {intakeYears.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.year}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="course_level_id"
                      label="Course Level"
                      required
                      rules={[
                        {
                          required: true,
                          message: "course Level cannot be empty!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Select course Level"
                        onChange={(value) => {
                          setPostData({ ...postData, course_level_id: value });
                          setCourseFilter({
                            ...courseFilter,
                            course_level_id: value,
                          });
                        }}
                      >
                        {courseLevels.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.level}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="course_id"
                      label="Applying Course"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Course cannot be empty!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Applying Course"
                        onChange={(value) => {
                          setPostData({ ...postData, course_id: value });
                        }}
                      >
                        {coursesList.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.course_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  {/* ==================== 2nd Row ============ */}
                  <div className="input_field">
                    <div className="left_btn">
                      <Button type="primary" onClick={() => updateForm(index)}>
                        Update Application
                      </Button>
                    </div>
                    <UniversityDescription />
                    <div className="app_upload">
                      <div>
                        <p className="common_header">
                          Upload SOP |<span>upload SOP here</span>
                        </p>
                        <FileUpload
                          type="file"
                          location="documents/application_doc"
                          count={1}
                          imageUpload={sop_doc}
                          setImageUpload={setSop_doc}
                          accept="application/pdf"
                          prefix="sop"
                        />
                      </div>
                      {universityById.length !== 0 &&
                        universityById.paperbase_form === 1 && (
                          <div>
                            <p className="common_header">
                              Paperbase Form |<span>upload paperbase</span>
                            </p>
                            <FileUpload
                              type="file"
                              location="documents/application_doc"
                              count={1}
                              imageUpload={paperbase_doc}
                              setImageUpload={setPaperbase_doc}
                              accept="application/pdf"
                              prefix="paperbase"
                            />
                          </div>
                        )}
                    </div>
                    {DownloadFile.length > 0 && (
                      <div>
                        <p className="common_header">
                          Download File |
                          <span>Fill the form and upload it next step</span>
                        </p>
                        {DownloadFile.map(
                          (item, index) =>
                            item.country.includes(postData.country_id) && (
                              <DownloadItem
                                key={index}
                                name={item.name}
                                url={item.url}
                              />
                            )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </Panel>
          )
      )}
    </Collapse>
  );
};

export default ApplicationList;
