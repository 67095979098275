import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { GetDataAuth } from "../../apiService/GetData";
import useAllUserList from "../../hooks/useAllUserList";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import StatusTag from "../../util/StatusTag";
import { FilterRow } from "./FilterRow";
import ViewUserModel from "./ViewUserModel";
import { permanentDelete } from "../../helpers/permanentDelete";

const { confirm } = Modal;

function UserDataTable() {
  const actionPrivileges = useStore((state) => state.actionPrivileges);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    status: "all",
    role: "all",
    dataTable: true,
    name: "",
    email: "",
  });
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const allUserList = useAllUserList(filterData);

  const columns = [
    {
      title: "",
      align: "center",
      dataIndex: "img_url",
      render: (img_url) => (
        <div className="table_av">
          {img_url ? (
            <Avatar src={`${IMAGE_PATH}/${img_url}`} />
          ) : (
            <Avatar
              style={{ color: "#234caa", backgroundColor: "#06297a30" }}
              icon={<UserOutlined />}
            />
          )}
        </div>
      ),
      width: 50,
    },
    {
      title: "First Name",
      render: (record) => (
        <p>
          {record.first_name +
            " " +
            (record.last_name ? record.last_name : " ")}
        </p>
      ),
      width: 150,
    },
    {
      title: "Company Name",
      render: (record) => (
        <p>
          {record.company_name ? record.company_name : "NA"}
        </p>
      ),
      width: 150,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      width: 200,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 180,
    },

    {
      title: "Created By",
      render: (record) => (
        <p>
          {record.created_fname +
            " " +
            (record.created_lname ? record.created_lname : " ")}
          (<b>{record.created_role}</b>)
        </p>
      ),
    },

    {
      title: "Phone Number",
      render: (record) => (record.phone ? record.phone : "NA"),
      width: 150,
    },
    {
      title: "Method",
      render: (record) => (record.method ? record.method : "NA"),
      width: 150,
    },
    {
      title: "Email Verify",
      render: (record) => (
        <Tag
          className="tags"
          color={record.email_verified_at === 1 ? "#0d8c63" : "#c73b27"}
        >
          {record.email_verified_at === 1 ? "Verified" : "Unverified"}
        </Tag>
      ),
      fixed: "right",
      width: 100,
    },

    {
      title: "User Type",
      render: (record) => (
        <Tag className="tags" color="cyan">
          {record.role}
        </Tag>
      ),
      fixed: "right",
      width: 120,
    },

    {
      title: "Status",
      render: (record) => <StatusTag status={record.status} />,
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          {actionPrivileges.includes("USER_VIEW") && (
            <Tooltip placement="bottom" title="User Details">
              <Button
                className="view_button"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => viewUserDataModel(record.id)}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          {record.status === 1
            ? actionPrivileges.includes("USER_INACTIVE_OPTION") && (
                <Tooltip placement="bottom" title="Deactivate user">
                  <Button
                    className="delete_button"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => statusChange(record.id, "Inactive")}
                  />
                </Tooltip>
              )
            : actionPrivileges.includes("USER_ACTIVE_OPTION") && (
                <Tooltip placement="bottom" title="Activate user">
                  <Button
                    className="view_button"
                    shape="circle"
                    icon={<CheckOutlined />}
                    onClick={() => statusChange(record.id, "Active")}
                  />
                </Tooltip>
              )}
          &nbsp;&nbsp;
          {actionPrivileges.includes("USER_DELETE_PERMANENT_OPTION") && (
            <Tooltip placement="bottom" title="Delete user permanently">
              <Button
                className="view_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() =>
                  permanentDelete(
                    "users",
                    record.id,
                    refreshTable,
                    setRefreshTable
                  )
                }
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 130,
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleFilled />,
      content: `Do  you want to ${type} this user?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        GetDataAuth(`${apis.USER_CHANGE}/${id}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            message.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const viewUserDataModel = (id) => {
    setSelectedUser(id);
    setVisible(true);
  };

  return (
    <>
      <div>
        <FilterRow filterData={filterData} setFilterData={setFilterData} />
      </div>
      <Table
        scroll={{ x: 1800 }}
        columns={columns}
        dataSource={allUserList.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allUserList.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
      {visible && (
        <ViewUserModel
          visible={visible}
          setVisible={() => {
            setVisible(!visible);
          }}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
}

export default UserDataTable;
