import { Alert, Button, Form, Input, message, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import useCountries from "../../hooks/useCountries";
import axios from "axios";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable } from "../../store";

const { Option } = Select;

function ImportModel({ visible, setVisible }) {
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });
  const countries = useCountries(filterDataOther);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      if (selectedFile) {
        console.log(selectedFile);
        var formData = new FormData();
        formData.append("uploaded_file", selectedFile);
        formData.append("type", "university");
        formData.append("country_id", values.country_id);
        setUploading(true);
        axios
          .post(apis.FILE_IMPORT, formData, {
            headers: {
              token: "dad#lecture#Charger#lot7",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setRefreshTable(!refreshTable);
              message.success(response.data.message);
              setSelectedFile();
              setUploading(false);
              setVisible(false);
            }
          })
          .catch((error) => {
            setUploading(false);
            if (error.response.status === 422) {
              const errors = error.response.data.error.file;
              errors.forEach((error) => {
                message.error(error);
              });
            } else {
              message.error(error.response.data.message);
            }
          });
      } else {
        message.error("Please choose a file");
      }
    });
  };

  const uploadFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const alertTxt = () => {
    return (
      <ol className="alertTxt">
        <li>
          1. Download the sample format file.{" "}
          <a
            href={`${IMAGE_PATH}/download/sample/uni-import-sample.csv`}
            target="_blank"
            rel="noreferrer"
            className="alLink"
          >
            Download sample file
          </a>
        </li>
        <li>2. Fill the data in the format file</li>
        <li>3. Select the country</li>
        <li>4. Upload the file and click on import button</li>
      </ol>
    );
  };

  return (
    <Modal
      title="Import Data"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
    >
      <Spin tip="Uploading..." spinning={uploading}>
        <div className="step_model_body">
          <Alert
            message="Read the instructions carefully before importing data"
            description={alertTxt()}
            type="info"
            showIcon
          />
          <br />
          <Form
            autoComplete="off"
            form={form}
            onFinish={submitForm}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="country_id"
              required
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty!",
                },
              ]}
            >
              <Select size="large" placeholder="Select Country">
                {countries.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="uploaded_file">
              <Input
                type="file"
                size="large"
                placeholder="Select Country"
                onChange={uploadFileChange}
                accept="csv"
              />
            </Form.Item>
            <div className="left_btn">
              <Button type="primary" className="primary__btn" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
}

export default ImportModel;
