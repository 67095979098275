import { Button, DatePicker, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import moment from "moment";
import useEventBySlug from "../../hooks/useEventBySlug";

function SubEvents() { 
  const {slug} = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const [desData, setDesData] = useState();
  console.log(desData);
  useBreadCrumb("Sub event", location.pathname, "", "add");

  const eventData = useEventBySlug(slug);
  
  useEffect(() => {
    form.setFieldsValue({
      title: eventData.title,
      slug: slug,
      start_date: moment(eventData.start_date),
      end_date: moment(eventData.end_date),
      location: eventData.location,
    });
    setDesData(eventData.description);
    // eslint-disable-next-line
  }, [eventData]);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      console.log(values);
      Progress.show();
      const data = {
        ...values,
        start_date: moment(values.start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(values.end_date).format("YYYY-MM-DD HH:mm:ss"),
      };

      PostDataAuth(apis.SUB_EVENT_CREATE, data).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          message.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

 

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <p className="common_header">Create sub event</p>
        </div>

        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Event Title"
            name="title"
          >
            <Input
              type="text"
              size="large"
              placeholder="Event title"
              disabled
            />
          </Form.Item>

          <Form.Item
            label="Event Slug"
            name="slug"
          >
            <Input
              type="text"
              size="large"
              placeholder="slug"
              disabled
            />
          </Form.Item>


          <Form.Item
            name="start_date"
            label="Event Start Date & Time"
            rules={[
              { required: true, message: "Date & Time cannot be empty!" },
            ]}
          >
            <DatePicker
              showTime
              size="large"
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Event Start Date"
              className="w-100"
            />
          </Form.Item>

          <Form.Item
            name="end_date"
            label="Event End Date & Time"
            rules={[
              { required: true, message: "Date & Time cannot be empty!" },
            ]}
          >
            <DatePicker
              showTime
              size="large"
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Event End Date"
              className="w-100"
            />
          </Form.Item>

          <Form.Item
            label="Event Location"
            name="location"
            rules={[{ required: true, message: "Location cannot be empty!" }]}
          >
            <Input type="text" size="large" placeholder="Event Location" />
          </Form.Item>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div> 
  );
}

export default SubEvents;
